import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "./MasterLayout.css";
import {Box} from "@material-ui/core";
const starjetsImage = require("./starjets.svg");


interface ContainerProps {
    children: React.ReactNode;
  }

class MasterLayout extends Component<ContainerProps> {

    render() {
        const {location:{pathname}} = document;
        const isTerms = pathname === '/TermsAndPolicy';
        return (
            <Box className = 'new_div'
            style={isTerms ? {display:"block"} : {}}
             >
                <Box className = 'new_div_child' width={'100%'}>
                    <Box className = 'masterlay_logo_parent'><img className='masterlay_logo' src={starjetsImage}  /></Box>
                    <Box className='masterlay_modal' style={{width:isTerms ? "90%" : '28rem',overflow : isTerms ? 'visible' : 'auto'}}>
                        {this.props.children}
                    </Box>
                </Box>
            </Box>

        );
    }
}


export default MasterLayout;