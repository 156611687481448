import React from "react";

// Customizable Area Start
import {
  Box,
  InputAdornment,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import {Alert} from '@material-ui/lab';
import { createTheme } from "@material-ui/core/styles";
import "./EmailAccountLoginBlock.css";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import { CustomFormTextField, CustomLabel,ForgotPasswordTypeButton,FormSubmitButton } from "../../../components/src/MuiCustomComponents";
import {theme as colors} from "../../../components/src/utils";
import * as yup from 'yup';
import {Formik, FormikErrors, FormikProps, FormikTouched} from 'formik';
import {LoginPlaneSvg} from './assets';


type handleChange = (eventOrPath: React.ChangeEvent<unknown> | string) => void;
type loginInitialValuesType = {email:string,password:string};

const loginInitialValues = {
  email:'',
  password:''
}

const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email format*') 
    .required('Email is required*'), 

  password: yup
    .string()
    .trim() 
    .min(8, 'Password must be at least 8 characters*')
    .required('Password is required*'), 
});

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start

          renderEmail = (
              errors: FormikErrors<loginInitialValuesType>,
              touched: FormikTouched<loginInitialValuesType>,
              handleChange: handleChange,
          ) => {
            const {focusLabelKey} = this.state;
            return (
              <Box>
                <CustomLabel isFocused={focusLabelKey === 'email'}>Email</CustomLabel>
                <CustomFormTextField
                  autoComplete="off"                 
                  className={
                    touched.email && errors.email ? "login_border_error" : ""
                  }
                  isFocusedTypeInput={true}
                  variant='outlined'
                  placeholder="Your Email"
                  size="medium"
                  fullWidth
                  name="email"
                  onChange={handleChange}
                  onFocus={()=>this.handleFocus('email')}
                  onBlur={()=>this.handleFocus()}
                />
               {touched.email && <Box className="login_valid_error_new">
                      {errors.email}
                    </Box>}
              </Box>
            )
          }

          renderPassword = (
              errors: FormikErrors<loginInitialValuesType>,
              touched: FormikTouched<loginInitialValuesType>,
              handleChange: handleChange,
          ) => {
            const {focusLabelKey} = this.state;
            return (
              <Box>
                <CustomLabel isFocused={focusLabelKey === 'password'}>Password</CustomLabel>
                <CustomFormTextField
                  type={this.state.isTypePassword ? "text" : "password"}
                  className={`login_test_password ${touched.password && errors.password ? "login_border_error" : ""}`}
                  placeholder="Your Password"
                  isFocusedTypeInput={true}
                  variant='outlined'
                  size="medium"
                  fullWidth
                  name="password"
                  onChange={handleChange}       
                  onFocus={()=>this.handleFocus('password')}
                  onBlur={()=>this.handleFocus()} 
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={this.handlePasswordType}
                          className="password_button_type"
                        >
                          {!this.state.isTypePassword ? (
                            <VisibilityOff style={{ color: colors.GRAY_COLOR_FORM }} />
                          ) : (
                            <Visibility style={{ color: colors.GRAY_COLOR_FORM }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
               {touched.password && <Box className="login_valid_error_new">
                      {errors.password}
                    </Box>}
              </Box>
            )
          }

          renderRememberMe = () => {
            return (
              <Box className="login_form_me_password" justifyContent={'end'}>
                <ForgotPasswordTypeButton id="login_forgot_password" style={{fontSize:'1.1rem',marginTop:"0.9rem"}} variant="contained" onClick={this.handleForgotPassword}>
                  Forgot Password?
                </ForgotPasswordTypeButton>
              </Box>
            )
          }

          renderLoginButton = (handleSubmit:(event:React.FormEvent<HTMLFormElement>)=>void,email:string,password:string) => {
            return (
              <FormSubmitButton
                style={{ margin:'1.3rem 0rem'}}
                fullWidth
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  handleSubmit(event as unknown as React.FormEvent<HTMLFormElement>)
                }}
                className={
                  this.isCreateAccountGrey(
                    email,
                    password,
                  )
                    ? ""
                    : "regsiter_account_grey"
                }
                id = 'login_test_button'
              >
                Log In
              </FormSubmitButton>
            )
          }

          renderRegisterContainer = () => {
            return (
              <Box className="login_form_register_btn">
                <CustomLabel>Don't Have an account?</CustomLabel>
                <ForgotPasswordTypeButton id='login_test_register' onClick={this.navigationToRegister}>Register</ForgotPasswordTypeButton>
              </Box>
            )
          }

          renderSnackBar=()=>{
            return  <Snackbar className='login_snack_bar' anchorOrigin={{vertical:'top',horizontal:'right'}} open={this.state.isSnackBarVisible} autoHideDuration={2000} onClose={this.handleCloseSnackBar} >
                <Alert  severity="success">
                Logged In successfully
                </Alert>
          </Snackbar>
          }

          

 // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box className={"login_form_parent"}>
        {this.renderSnackBar()}
        <LoginPlaneSvg/>
        <Box fontWeight={'bold'} className={"login_form_head"}>Any Jet. Any time. Anywhere.</Box>
          <Formik
            initialValues={loginInitialValues}
            onSubmit={(values) => {
              this.loginUser({
                ...values,
                is_client: String(!this.getUserRole())
              });
            }}
            innerRef={(formikProps) => {
              this.formikMethods = formikProps as FormikProps<unknown>;
            }}
            validationSchema={loginValidationSchema}
            validateOnChange
            validateOnBlur
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange              
            }) => (
            <form onSubmit={handleSubmit} style={{marginBottom:'0'}}>
              <Box className={"login_form_container"}>
                {this.renderEmail(errors,touched,handleChange)}
                {this.renderPassword(errors,touched,handleChange)}
                <Box>{this.renderRememberMe()}</Box>
                {this.renderLoginButton(handleSubmit,values.email,values.password)}
                {!this.getUserRole()&&this.renderRegisterContainer()}
              </Box>
            </form>
            )}
        </Formik>
        


      </Box>
      // Customizable Area End
    );
  }
}
