import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { FormikProps } from "formik";
import { handleNavigation } from "../../../components/src/Navigator";

// Customizable Area Start
import { loginRoute } from "../../../components/src/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isPasswordVisible: boolean;
  isConfirmPasswordVisible: boolean;
  isPasswordErrorsVisible: boolean;
  isStatusModalVisible: boolean;
  seconds:number;
  email:string;
  formType:string;
  isRunning:boolean,
  keyToken:string;
  isErrorPasswordContainerVisible:boolean;
  passwordVisiblity:{
    New:boolean,
    Confirm:boolean
  },
  focusedField:string|null
  screenType : string | null
  activeComp : 'otp' | 'new_password' | 'status'
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  sendOtpApiCallId :string = "";
  timerID:NodeJS.Timer|null = null
  confirmOtpAPICallId:string = "";
  otpFormik:FormikProps<unknown>|null = null;
  changePasswordAPICallId:string = "";
  updatePasswordFormik:FormikProps<unknown>|null = null;
  emailIdFormik:FormikProps<unknown>|null = null
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
      isPasswordErrorsVisible: false,
      isStatusModalVisible: true,
      seconds:60,
      email:"",
      formType:"email",
      isRunning:false,
      keyToken:"",
      isErrorPasswordContainerVisible:false,
      passwordVisiblity:{
        New:false,
        Confirm:false
      },
      focusedField:null,
      activeComp:'otp',
      screenType:null
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.handleScreenType();
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
  
        if (responseJson && responseJson.data) {
            this.handleSuccessAPIResponse(apiRequestCallId,responseJson)            
        }
        if(responseJson && responseJson.message){
          if(apiRequestCallId === this.confirmOtpAPICallId){
            this.handleOtpSuccessResponse()
          }
        }

        if(responseJson.errors){
          this.handleError(apiRequestCallId,responseJson)
        }

        this.handleMetaMessage(responseJson,apiRequestCallId);
       
    }
    // Customizable Area End
}

  
  isCreateAccountGrey = (email: string) => {
    if (email.length) {
      return true;
    }
    return false;
  };

  handleSuccessAPIResponse = (apiRequestCallId:string,responseJson:{meta:{token:string}})=>{
    if(apiRequestCallId === this.sendOtpApiCallId){
      this.handleEmailSuccessResponse(responseJson)
    }
    if(apiRequestCallId === this.changePasswordAPICallId){
      this.setState({formType:"passwordModal",activeComp:"status"})
    }

  }

  handleError = (apiRequestCallId:string,responseJson:{errors:{otp:string}[]})=>{
    if(apiRequestCallId === this.sendOtpApiCallId){  
      (this.emailIdFormik as FormikProps<unknown>).setFieldError("email",responseJson.errors[0].otp)                                  
    }
    if(apiRequestCallId === this.confirmOtpAPICallId){
      let error = Object.keys(responseJson.errors[0])[0];
      (this.otpFormik as FormikProps<unknown>).setFieldError("otpValue",responseJson.errors[0][error as 'otp']);
    }    
  }

  handleSendOtpToEmail = (email:string)=>{
    const header = {
      "Content-Type":configJSON.forgotPasswordAPiContentType
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendOtpApiCallId = requestMessage.messageId;

    const emailId = {
      data:{
        attributes:{
          email:email
        }
      }
    }    

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendOtpApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(emailId)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleEmail = (email:string)=>{
    this.setState({email:email})
  }

  handleResendOtp = ()=>{
    this.handleSendOtpToEmail(this.state.email)

  }

  handleEmailSuccessResponse=(responseJson:{meta:{token:string}})=>{
    this.setState({formType:"otp",keyToken:responseJson.meta.token});
    this.resetTimer();
  }
  

  resetTimer=()=>{
    this.setState({seconds:60})
    this.startTimer();
  }

  startTimer = () => {
    if (!this.state.isRunning) {
      this.timerID = setInterval(() => {
        this.setState((prevState) => ({
          seconds: prevState.seconds - 1
        }), () => {
          if (this.state.seconds === 0) {
            clearInterval(this.timerID as NodeJS.Timeout);
            this.setState({ isRunning: false });
          }
        });
      }, 1000);
      this.setState({ isRunning: true });
    }
  };

  handleOtpConfirmation = (otpValue:string)=>{
    const {screenType} = this.state;
    const endPoint = screenType === 'update_email' ? configJSON.updateEmailOtpEndPoint : configJSON.confirmOtpAPIEndpoint;
    const methodType = screenType === 'update_email' ? configJSON.putMethod : configJSON.httpPostMethod;
    const header = {
      "Content-Type":configJSON.forgotPasswordAPiContentType
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.confirmOtpAPICallId = requestMessage.messageId;

    const otpData = {
      data: {
        attributes: {
            token: this.state.keyToken,
            otp: otpValue,
        }
    }
    }    

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(otpData)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOtpSuccessResponse = ()=>{
    this.setState({formType:"password",activeComp : 'new_password'})
  }

  handleVisibilityPassword=(keyName:string,value:boolean)=>{
    if(keyName === "Confirm New"){
      keyName = "Confirm"
    }
    this.setState({passwordVisiblity:{...this.state.passwordVisiblity,[keyName]:value}});
  }

  handleErrorContainerVisible=(isOpen:boolean)=>{
    this.setState({isErrorPasswordContainerVisible:isOpen})
  }

  handleChangePassword = (newPassword:string,confirmNewPassword:string)=>{
    const header = {
      "Content-Type":configJSON.forgotPasswordAPiContentType
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changePasswordAPICallId = requestMessage.messageId;

    const newPasswordsData = {
      data:{
        attributes:{
          token:this.state.keyToken,
          new_password:newPassword,
          new_password_confirmation:confirmNewPassword
        }
      }
    }    

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordAPIEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(newPasswordsData)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNavigationToLogIn = ()=> {
    const urlSearchParam = window.localStorage.getItem('urlSearchParam');
    const loginUrl = urlSearchParam ? `${loginRoute}${urlSearchParam}` : loginRoute;

    handleNavigation(loginUrl,this.props,this.send)
  }

  handleFocusField = (fieldName:string|null)=>{
    this.setState({focusedField:fieldName})
  }


  handleScreenType=()=>{
    const {location : {pathname,search}} = window;
    const queryParams = new URLSearchParams(search);
    const keyToken = queryParams.get('token') as string;   
    let screenType = null;
    switch(pathname){
      case "/CreateUser":
      screenType = 'create_account';
      break;
      
      case "/UpdatePassword":
      screenType = 'update_password';
      break;

      case "/UpdateEmail":
      screenType = 'update_email';
      break;

      default:
      screenType = 'forgot_password';
      break;
    }
    this.setState({screenType,keyToken});
  }

  handleMetaMessage=(responseJson:{meta:{message:string}},apiRequestCallId:string)=>{
    if(responseJson.meta.message){
      if(apiRequestCallId === this.confirmOtpAPICallId)  this.setState({activeComp : 'status'});
    }
  }

  // Customizable Area End
}
