Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.postMethod = "POST";
exports.deleteMethod = "DELETE";
exports.updateMethod = "PUT";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.getCatalogueListEndPoint = 'bx_block_catalogue/aircraft_catalogues';
exports.getAircraftCategoriesEndPoint = "bx_block_cfavinodeintegration2/aircrafts/aircraft_categories";
// Customizable Area End