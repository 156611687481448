import React from "react";

// Customizable Area Start
import {
  Box,
  IconButton,
  MenuItem,
  Avatar,
} from "@material-ui/core";
import { CustomTypography, formatNotificationTime, Loader, StyledEditDeleteMenu } from "../../../components/src/MuiCustomComponents";
import {MoreVert, Check,Notifications as NotiIcon} from '@material-ui/icons';
import {NotificationType} from './NotificationsController';
import { StarLogo } from "../../../components/src/assets";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNotificationMenu = () => {
    const { notificationMenu } = this.state;
    return (
       <Box>
          <IconButton className="notification_menu" onClick={(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.handleMenuClick(event)}>
             <MoreVert style={webStyle.menuIcon} />
          </IconButton>
          <StyledEditDeleteMenu
             anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
             }}
             transformOrigin={{
                vertical: "top",
                horizontal: "right",
             }}
             style={{marginTop:"3rem",marginLeft:"1.3rem"}}
             anchorEl={notificationMenu}
             keepMounted
             className = 'menu_button'
             open={Boolean(notificationMenu)}
             bcgColor={'#6C7278'}
             onClose={()=>this.handleMenuClose()}
          >
             <MenuItem style={webStyle.menuItemStyle} className="mark_all" onClick={()=>this.markReadUnread(null,true)}>
                <Box style={{color:'white'}}><Check/></Box>
                <Box>Mark all as read</Box>
             </MenuItem>
          </StyledEditDeleteMenu>
       </Box>
    )
 }


  renderNotificationHeader=()=>{
    const {notificationData} = this.state;
    return <Box style={webStyle.notiContainer}>
       <CustomTypography style={{color:"white"}}>Notifications</CustomTypography>
       {notificationData.length !== 0 && this.renderNotificationMenu()}
    </Box>
  }

  renderNotificationLoaderHandler=()=>{
    const {isNotificationLoading} = this.state;
    return <Box>
       {isNotificationLoading ? this.renderTableLoader() :  this.renderNotificationContainer()}
    </Box>
  }


  renderTableLoader = ()=>{
    return <Loader height="70vh">
    <Box className="cf_avinod_no_result_head loader_container" marginTop={'1rem'}>Please Wait</Box>
    <Box fontSize={'1.2rem'}>Loading Notifications...</Box>
  </Loader> 
  }

  renderNotificationContainer=()=>{
    const {notificationData} = this.state;
    return <Box style={{borderRadius:"10px",overflow:'hidden'}}>
      {notificationData && notificationData.map((message)=>this.renderEachNotification(message))}
      {notificationData.length === 0 && this.renderNoNotifications()}
    </Box>
  }

  renderNoNotifications=()=>{
    return <Box style={webStyle.noNotificationParent}>
      <Box style={webStyle.noNotification}>
          <NotiIcon style={webStyle.bellIcon}/>
          <Box fontSize={'1.5rem'} fontWeight={'bold'}>No Notifications Yet</Box>
          <Box>
            <Box>All your notifications will show up here</Box>
            <Box textAlign={'center'}>Happy Booking!</Box>  
          </Box>
      </Box>
    </Box>
  }

  renderEachNotification=(notification:NotificationType)=>{
    const {attributes:{is_read,heading,content,image,created_at},id} = notification;
    return <Box style={{...webStyle.eachNotificationContainer,borderBottom:'1px solid #3E4247'}}>
       <Box style={{...webStyle.avatarMessageContainer,width:"100%"}}>
           <Box style={webStyle.avatarCont}> 
            <StarLogo/>
            </Box> 
           <Box style={{rowGap:'0.6rem',display:"flex",flexDirection:'column',width:"100%"}} >

           <Box style={webStyle.nameText}>
              <Box style={webStyle.textContainer}>
                <Box fontWeight={'bold'} color={!is_read ? '#FFD27E' : ''}>{heading}</Box>
                <Box fontWeight={'bold'} color={!is_read ? '#FFD27E' : '#8795A8'}>| {formatNotificationTime(created_at as unknown as Date)} </Box>
                {!is_read && this.renderDot()}
              </Box>
              {this.renderReadUnred(!is_read,id)}
           </Box> 

            <Box style={{...webStyle.dullText,wordBreak:"break-all"}}>{content}</Box>
           </Box>
       </Box>
   
    </Box>
  }

  renderDot=()=>{
    return <Box style={webStyle.dot} />
  }

  renderReadUnred=(isRead:boolean,notId:string)=>{ 
    const handleText = isRead ? "read" : "unread";
    const color = isRead ? 'white' : '#94A3B8';
    return <Box>
        <Box className="read_unread" style={{...webStyle.avatarMessageContainer,columnGap:'0.5rem',cursor:"pointer"}} onClick={()=>this.markReadUnread(notId,isRead)}>
          <Check style={{color}}/> 
          <Box color={color}>Mark as {handleText}</Box>
       </Box>
    </Box>
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box className="booking_section notification_container">
        {this.renderNotificationHeader()}
        {this.renderNotificationLoaderHandler()}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
type CSSPropertiesMap = {
  [key: string]: React.CSSProperties;
};

const webStyle:CSSPropertiesMap = {
  notiContainer:{
    display:"flex",
    justifyContent:'space-between',
    alignItems : "center"
  },
  menuIcon : {
    color:'white',
    cursor:'pointer',
    position:'relative'
  },
  menuItemStyle:{
    fontSize:'1.2rem',
    columnGap : '0.4rem',
    backgroundColor:'#6C7278'
  },
  eachNotificationContainer:{
    backgroundColor:"#2D3034",
    padding:'1rem',
    color:'white'
  },
  avatarMessageContainer:{
    display:"flex",
    columnGap : '1rem',
    alignItems : 'center',
  },
  nameText:{
    display:'flex',
    justifyContent:'space-between'
  },
  dot:{
    borderRadius:"50%",
    width:"0.65rem",
    height:"0.65rem",
    backgroundColor:"#FFD27E",
    position:'relative',
    marginTop:'0.14rem'
  },
  textContainer:{
    display : 'flex',
    columnGap : '0.5rem',
    alignItems : 'center'
  },
  dullText : {
    color : '#8795A8'
  },
  lightText : {
    color : '#FFD27E'
  },
  noNotificationParent:{
    display : 'flex',
    flexDirection : 'column',
    justifyContent : 'center',
    alignItems : 'center',
    height : "70vh",
  },
  noNotification:{
    display : 'flex',
    flexDirection : 'column',
    rowGap : '0.5rem',
    color : 'white',
    justifyContent : 'center',
    alignItems : 'center',
  },
  bellIcon:{
    color : '#FFC760',
    padding : '1rem',
    fontSize : '3.5rem',
    border : '1px solid #636E72',
    borderRadius:'50%',
    background : '#181C18'
  },
  avatarCont:{
    width:"38px",
    height:"40px",
    borderRadius:"50%",
    overflow:"hidden"
  }
};
// Customizable Area End
