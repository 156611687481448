import React from "react";

// Customizable Area Start
import {Box} from '@material-ui/core';
import { CustomDashboardButton, CustomFormTextField, ErrorBoxes, Footer, FooterCancelSaveChanges, GeneralParentBox, GoldishSimpleButton, LabelAndTextField, ReusableFormLabel } from "../../../components/src/MuiCustomComponents";
import { ArrowBack } from "@material-ui/icons";
import {Formik} from 'formik';
import { PaymentFormSchema } from "../../../components/src/Validations";

interface PaymentFormType{
  fedwire_routing : null | string,
  name : null | string,
  account_type : null | string,
  account_number : null | string,
  zip_code : null | string
  rec_address : null | string,
}

interface PaymentSettingFieldType{
  label: string,
  keyName: keyof PaymentFormType,
  placeholder: string,
}

const paymentSettingField: PaymentSettingFieldType[] = [
  {
    label: 'Name of the business / organisation',
    keyName: 'name',
    placeholder: 'Enter Name of the business / organisation',
  },
  {
    label: 'Fedwire routing number',
    keyName: 'fedwire_routing',
    placeholder: 'Enter Fedwire routing number',
  },
  {
    label: 'Account number',
    keyName: 'account_number',
    placeholder: 'Enter Account number',
  },
  {
    label: 'Account type',
    keyName: 'account_type',
    placeholder: 'Enter Account type',
  },
  {
    label: 'Recipient address',
    keyName: 'rec_address',
    placeholder: 'Enter Recipient address',
  },
  {
    label: 'Zip Code',
    keyName: 'zip_code',
    placeholder: 'Enter Zip Code',
  }
];

// Customizable Area End

import PaymentSettingController, {
  Props,
    configJSON,
} from "./PaymentSettingController";

export default class PaymentSetting extends PaymentSettingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
    renderPaymentSettingHeading=()=>{
      const {isEditState } = this.state;
      const head = isEditState ? 'Edit ' : ''
      return  <GoldishSimpleButton
      style={{ color: 'white', textTransform: "capitalize", justifyContent:'start',fontSize:'1.6rem'}}
      className='cf-avinode-search'
      data-testId = 'redirect_back'
      onClick={()=>this.handleBack()}
    >
    <ArrowBack className = 'cf_avinod_arrow_search'/>
      {head} {configJSON.head}
    </GoldishSimpleButton>
    }

    renderPaymentSettingContainer=()=>{
      const {payment_form} = this.state;
      return <GeneralParentBox style={{padding:"1.5rem",marginBottom:"7rem"}}>
        <Formik
        initialValues={payment_form}
        onSubmit={() => {}}
        validationSchema={PaymentFormSchema}
        validateOnChange
        validateOnBlur
        enableReinitialize
        innerRef={(formikProps) => {
          this.paymentFormik = formikProps;
        }}
      >
        {(formikProps) => (
            <Box
              className='booking_section edit_system_setting_parent'
              style={{ rowGap: "1.8rem", padding: "1rem" }}
            >
              {paymentSettingField.map(({keyName,label,placeholder})=>this.renderPaymentEachLabelTextField({keyName,formikProps,label,placeholder}))}
            </Box>)}
          </Formik>
   </GeneralParentBox>
    }

    renderPaymentEachLabelTextField=({label,placeholder,formikProps,keyName}:LabelAndTextField<PaymentFormType>)=>{
      const {handleChange,values,touched,errors}  = formikProps;
      const {activeLabel,isEditState} = this.state;
      const variant = !isEditState ? 'standard' : 'outlined'
      return <Box>
            <ReusableFormLabel touchError={touched[keyName]} label={label} error={errors[keyName]} focusedLabel={activeLabel} keyName={keyName}></ReusableFormLabel>
            <CustomFormTextField
              isFocusedTypeInput={true}
              isErrorTouch = {touched[keyName] && errors[keyName]}
              id={keyName}
              variant={variant}
              onChange={handleChange}
              fullWidth
              className={keyName}
              value={values[keyName]}
              disabled={!isEditState}
              placeholder={placeholder}
              onFocus={()=>{this.handleActiveLabel(keyName)}}
              onBlur={this.handleActiveLabel}
              autoComplete="off"
              InputProps={{
                inputProps:{style: {marginLeft:!isEditState ? '0.3rem' : '-0.5rem'}}
              }}
    />
      <ErrorBoxes errors={errors[keyName] as unknown as string} message={errors[keyName] as unknown as string} touched={touched[keyName] as unknown as boolean}/>
  </Box>
    }

    renderEditStateFooter=()=>{
      return  <FooterCancelSaveChanges cancel={()=>this.cancelSave()} submit={()=>this.paymentFormik?.handleSubmit()}/>
    }

    renderViewStateFooter=()=>{
      return <Box className="footer_global">
        <CustomDashboardButton size="large" variant="outlined" className="edit_button" onClick={()=>this.handleEditState(true)}>
        Edit Payment Settings
        </CustomDashboardButton>
      </Box>
     }

     renderPaymentSettingFooter=()=>{
      const {isEditState} = this.state;
      return <Footer>
        {isEditState ? this.renderEditStateFooter() : this.renderViewStateFooter()}
      </Footer>
     }

  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
       <Box className="payment_setting booking_section">
            {this.renderPaymentSettingHeading()}
            {this.renderPaymentSettingContainer()}
            {this.renderPaymentSettingFooter()}
       </Box>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const webStyle = {
  // custom style definitions
};
// Customizable Area End