Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfpaymentgateway33";
exports.labelBodyText = "cfpaymentgateway33 Body";

exports.btnExampleTitle = "CLICK ME";

exports.addOrEditCardAPIEndpoint = "bx_block_content_management/cards"
exports.httpPutMethod = "PUT"
exports.getUserProfileEndPoint = 'bx_block_profile/profile'
exports.patchMethod = "PATCH"
exports.makePrimaryPaymentMethodAPIEndpoint = "/bx_block_cfpaymentgateway33/payments/make_primary"
exports.deleteCardAPIEndpoint = "/bx_block_content_management/cards"
exports.deletePassengerMethod = "DELETE"
exports.getPaymentMethodsApiEndpoint = "/bx_block_cfpaymentgateway33/payments"
exports.getDebitSkyCardEndPoint = 'bx_block_cfdebitskycard3/debit_sky_cards/debit_card';
exports.get25HrSkyCardEndPoint = 'bx_block_cf25hourskycard/hours_sky_cards';
exports.head = 'Payment Settings';
// Customizable Area End