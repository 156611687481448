import * as React from "react"

export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const visa_image = require('../assets/visa.png');
export const master_image = require('../assets/mastercard.png');
export const defaultCard = require('../assets/creditCard.png')
export const americanExpess = require('../assets/americanExpress.png');



export const customSkyCards = [
    {
    account_id:2205729,
    client:"Theressa webbbb",
    trips_remaining:9,
    aircraft_category:"Mid Jet",
    form_to:"MMAG>EGGW",
    date_created:"12/08/1994",
    expiry_date:"4/12/1978",
    csc_id:"1223450"
    },
    {
    account_id:2205729,
    client:"Darrell Steward",
    trips_remaining:9,
    aircraft_category:"Mid Jet",
    form_to:"MMAG>EGGW",
    date_created:"12/08/1994",
    expiry_date:"4/12/1978",
    csc_id:"1223450"
    },
    {
    account_id:2205729,
    client:"Floyd Miles",
    trips_remaining:9,
    aircraft_category:"Mid Jet",
    form_to:"MMAG>EGGW",
    date_created:"12/08/1994",
    expiry_date:"4/12/1978",
    csc_id:"1223450"
    }

]



export interface PaymentTextFieldType {label:string,handleChange:( e: React.ChangeEvent<HTMLInputElement>) => void,placeholderText:string,formName:string,value:NullStringNumber}
export type NullStringNumber = null | string | number
export type TouchedMessage = undefined | boolean



export interface VirtualTerminalType{
    cardDetails : CardFormType
    setActiveFieldName : (action:string)=>void,
    activeFieldName:string,
  }

export  interface CardFormType{
    amount : null | number,
    card_number : null | number,
    name_on_card : null | string,
    expiry : null | string,
    security_code: null | string,
    street_address : null | string,
    city : null | string,
    state : null | string,
    zip_code: null | string,
    country : null  | string,
  }

export  type FormikRefType = React.MutableRefObject<null>
export  type VirtualTerminalDataTyoe = null | VirtualTerminalExactDataType
export type SavedCardStateType = null | SavedCardType[]
export interface VirtualTerminalExactDataType {
    main_invoice : VirtualTerminalInvoiceType,
    incidential_invoice : VirtualTerminalInvoiceType[],
  }
 export interface SavedCardType {
    id: string
    type: string
    attributes: {
      cards: {
        card_holder_name: string
        card_number: string
        expiry_date: string
        card_type: string
        zipcode: string
        country_code: null | string
        street_address: string
        city: string
        state: string
        country: string
        country_code_string: string
      }
      primary_method: boolean
    }
  }
  export interface VirtualTerminalInvoiceType {
    id : number,
    amount_to_pay : string,
    total_amount : string,
    is_paid : boolean,
    invoice_name:string
  }

  export interface ChargeExistingCompType{
    setActiveFieldName : (action:string)=>void
    activeFieldName:string,
    savedCard : SavedCardStateType
    cardDetails : CardFormType
  }

  export interface InitExistingCardValuesType{
    amount_pay:number | null,
      invoice_id:number | null,
      card_id:string | null
  }

  export interface CombinedTextfieldType extends PaymentTextFieldType {
    touched :undefined | boolean,
    errors : string | undefined,
    message:string | undefined

  }

  export interface PaymentWithLabelCompFocusType{
    label:string,
    activeField : string,
    currentField : string
  }
  export type FromAndToType = {
    id: string
    href: string
    type: string
    name: string
    city: string
    country: {
      code: string
      name: string
    }
    icao: string
    iata: string
  }

  export interface CardsFormType{
    clientName:string,
    accountId:string,
    fromAirport:{
      id:string
    },
    toAirport:{
      id:string
    },
    noOfTrips:string,
    aircraftCategory:string,
    addInfo:string,
    price:string,
    account_number:string,
    validFrom:Date|string,
    validTo:Date|string

  }

 export interface AirportCategoryType{
    label: string
    value: string
  }

export const NoOfTripsIcon = ()=> <svg width="24" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.077 12.7675C16.118 12.7675 15.3013 12.5123 14.627 12.0017C13.9526 11.4911 13.6155 10.8714 13.6155 10.1425V3.39259C13.6155 2.66373 13.9526 2.04402 14.627 1.53344C15.3013 1.02287 16.118 0.767578 17.077 0.767578H20.6346C21.5808 0.767578 22.3782 1.02287 23.0269 1.53344C23.6756 2.04402 24 2.66373 24 3.39259V10.1425C24 10.8714 23.6756 11.4911 23.0269 12.0017C22.3782 12.5123 21.5808 12.7675 20.6346 12.7675H17.077ZM17.077 10.5176H20.6346C20.75 10.5176 20.8398 10.4815 20.9039 10.4094C20.968 10.3373 21 10.2483 21 10.1425V3.39259C21 3.28681 20.968 3.19786 20.9039 3.12574C20.8398 3.05361 20.75 3.01755 20.6346 3.01755H17.077C16.9487 3.01755 16.8398 3.05361 16.75 3.12574C16.6603 3.19786 16.6154 3.28681 16.6154 3.39259V10.1425C16.6154 10.2483 16.6603 10.3373 16.75 10.4094C16.8398 10.4815 16.9487 10.5176 17.077 10.5176ZM7.99998 20.2676C7.29231 20.2676 6.66219 20.1147 6.10962 19.8089C5.55706 19.5031 5.17309 19.0974 4.95772 18.5916L0 6.06083V0.767578H2.99996V6.01756L7.88458 18.0176H24V20.2676H7.99998ZM5.40387 24.7676V22.5176H23.9807V24.7676H5.40387ZM17.077 3.01755H16.6154H21H17.077Z" fill="white"/>
</svg>

export interface Card{
  id:string,
  type:string,
  attributes:{
    cards:{
      card_holder_name: string,
      card_number:string ,
      expiry_date:string ,
      card_type:string ,
    }
  }
 
}

export interface SelectedClient{
    id:string,
    full_name:string,
    account_id:string,
    account_number:string,
    cards:Card[]
  
}

export interface Client{

  id: string,
  type: string,
  attributes: {
    full_name: string,
    account_id: string,
    account_number:string,
    existing_card: {
      cards: {
        data: [
          {
            id: string,
            type: string,
            attributes: {
              cards: {
                card_holder_name: string,
                card_number: string,
                expiry_date: string,
                card_type: string,
              },

            }
          }
        ]
      }
    }
  }
  
  
}

export interface ClientResponse{
  data:Client[]
}

export interface ExistingCard{
  card_id:number,
  amount:number
}

export interface CustomSkyCard{
  id: string,
  type: string,
  attributes: {
    account_id: number,
    account_number:string,
    client_name: string,
    trips_remaining: number,
    aircraft_category: string,
    from_airport: {
      icao: string,
    },
    to_airport: {
      icao: string,
    },
    date_created: string,
    additional_info:string,
    expiry_date: string,
    valid_from:string,
    CSC_ID: string,
    existing_card:{cards:{data:Card[]}}
}
}

export interface CustomSkyCardsResponse{
  total_pages:number,
  Custom_forms:{data:CustomSkyCard[]}
}

  export interface DebitSkyCard{
    id: string,
    type: string,
    attributes: {
    account_id: number,
    debit_sky_card_id: number,
    client_name: string,
    balance: string,
    last_top_up: string
  }
  }

  export interface PurchasedSkyCard{
    id:string,
    type:string,
    attributes:{
      order_id: string,
      account_id: string,
      client_name: string,
      sky_card_type: string,
      date: string,
      transaction_value:string
    }

  }

  export interface HoursSkyCard{
    id: string,
    type: string,
    attributes: {
    account_id: string,
    name: string,
    balance: string,
    last_top_up: string,
    aircraft_category:string,
  }
  }

  export interface PreviousPurchases{
    order_id:string,
    sky_card_type:string,
    date:string,
    transaction_value:string
  }
  export interface DebitSkyCardResponse{
    total_pages:number,
    data: {
      data: DebitSkyCard[]
    }
  }

  export interface hoursSkyCardsResponse{
    total_pages:number,
    data:{
      data:HoursSkyCard[]
    }
  }

  export interface PurchasedSkyCardsResponse{
    total_pages:number,
    data:{
      data:PurchasedSkyCard[]
    }

  }

  export interface SkyCardsFilter{
    items:Aircraft[],
    category:string
  }

  export interface DebitSkyCardFilter{
    label:string,
    value:string
  }

  export type Filters = SkyCardsFilter|DebitSkyCardFilter|Aircraft

  export type SkyCards = CustomSkyCard|DebitSkyCard|PurchasedSkyCard|HoursSkyCard|PreviousPurchases

  export type SkyCardsResponse = CustomSkyCardsResponse|DebitSkyCardResponse|hoursSkyCardsResponse|PurchasedSkyCardsResponse

  export interface Aircraft {
    label:string,
    value:string,
  }

  export interface Topup{
    account_id:number,
    client_name:string,
    debit_sky_card_id:number,
    current_top_ups:{top_up:string}[],
    current_balance:string,
    cards:Card[],
    aircraft:string,
    topupHoursCardId:string,
    hoursBalance:string

  }

  export interface ExistingCards{
    amount_pay:number|null,
    card_id:number|null,
  }

  export interface TopupError{
    error:{
      error_code:string,
      error_message:string
    }
  }

  export interface Aircrafts{
    categories:Aircraft[],
    data:string[]
  }

  export interface SkycardTopup{
    skycard_id:string,
    account_id:number,
    account_number:string
    client_name:string,
    cards:Card[],
    aircraft:string,
    from_airport:string,
    to_airport:string,
    additionalInfo:string,
    valid_from:string,
    valid_to:string
  }

  export interface TopupCustomCardDetails{
    trips:number,
    price:number
  }

  export interface AirportCategoryType{
    label: string
    value: string
  }

  export interface HandleAircraftResponseType{
    categories : AirportCategoryType[]
    }