import React from 'react';
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const edit_icon = require("../assets/edit_24px.png");
export const deleteIcon = require("../assets/delete_outline_24px.png")
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const masterCard = require("../assets/mastercard.png")
export const visa = require("../assets/visa.png")
export const americanExpress = require("../assets/americanExpress.png")
export  const defaultCard = require("../assets/creditCard.png")

const EmailSvg =() =>{
    return <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.582 19.4791H6.41536C3.06953 19.4791 1.14453 17.5541 1.14453 14.2083V7.79158C1.14453 4.44575 3.06953 2.52075 6.41536 2.52075H15.582C18.9279 2.52075 20.8529 4.44575 20.8529 7.79158V14.2083C20.8529 17.5541 18.9279 19.4791 15.582 19.4791ZM6.41536 3.89575C3.7937 3.89575 2.51953 5.16992 2.51953 7.79158V14.2083C2.51953 16.8299 3.7937 18.1041 6.41536 18.1041H15.582C18.2037 18.1041 19.4779 16.8299 19.4779 14.2083V7.79158C19.4779 5.16992 18.2037 3.89575 15.582 3.89575H6.41536Z" fill="url(#paint0_linear_16641_5814)"/>
    <path d="M10.9999 11.7975C10.2299 11.7975 9.45071 11.5591 8.85488 11.0733L5.98571 8.78164C5.69238 8.54331 5.63738 8.11247 5.87571 7.81914C6.11405 7.5258 6.54488 7.47081 6.83822 7.70914L9.70737 10.0008C10.404 10.56 11.5865 10.56 12.2832 10.0008L15.1524 7.70914C15.4457 7.47081 15.8857 7.51664 16.1149 7.81914C16.3532 8.11247 16.3074 8.55247 16.0049 8.78164L13.1357 11.0733C12.549 11.5591 11.7699 11.7975 10.9999 11.7975Z" fill="url(#paint1_linear_16641_5814)"/>
    <defs>
    <linearGradient id="paint0_linear_16641_5814" x1="1.42608" y1="4.25513" x2="22.9392" y2="8.25357" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FFE3AE"/>
    <stop offset="1" stop-color="#FFD07A"/>
    </linearGradient>
    <linearGradient id="paint1_linear_16641_5814" x1="5.87714" y1="7.9908" x2="16.1558" y2="12.0769" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FFE3AE"/>
    <stop offset="1" stop-color="#FFD07A"/>
    </linearGradient>
    </defs>
    </svg>    
}

const DeleteSvg=()=>{
    return  <svg width="65" height="65" viewBox="0 0 81 82" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path d="M54.2448 6.85254H25.9623C13.6773 6.85254 6.35352 14.1763 6.35352 26.4613V54.71C6.35352 67.0288 13.6773 74.3525 25.9623 74.3525H54.211C66.496 74.3525 73.8198 67.0288 73.8198 54.7438V26.4613C73.8535 14.1763 66.5298 6.85254 54.2448 6.85254Z" fill="#18191B"/>
 <path d="M44.5642 56.7871C44.1036 56.3265 44.1036 55.5641 44.5642 55.1036L49.043 50.6247C49.5036 50.1641 50.266 50.1641 50.7265 50.6247C51.1871 51.0853 51.1871 51.8477 50.7265 52.3083L46.2477 56.7871C46.0095 57.0253 45.7077 57.1365 45.406 57.1365C45.1042 57.1365 44.8024 57.0253 44.5642 56.7871Z" fill="url(#paint0_linear_15415_23721)"/>
 <path d="M49.043 56.7871L44.5642 52.3083C44.1036 51.8477 44.1036 51.0853 44.5642 50.6247C45.0248 50.1641 45.7871 50.1641 46.2477 50.6247L50.7265 55.1036C51.1871 55.5641 51.1871 56.3265 50.7265 56.7871C50.4883 57.0253 50.1865 57.1365 49.8848 57.1365C49.583 57.1365 49.2812 57.0253 49.043 56.7871Z" fill="url(#paint1_linear_15415_23721)"/>
 <path fill-rule="evenodd" clip-rule="evenodd" d="M39.8329 41.1907C39.7535 41.1748 39.6423 41.1748 39.547 41.1907C34.9411 41.0478 31.4629 37.4266 31.4629 32.9636C31.4629 28.4213 35.1635 24.7207 39.7058 24.7207C44.2482 24.7207 47.9488 28.4213 47.9488 32.9636C47.9329 37.4266 44.4388 41.0478 40.0076 41.1907C39.9917 41.1907 39.9758 41.1907 39.9599 41.1907C39.9123 41.1907 39.8805 41.1907 39.8329 41.1907ZM33.8452 32.9636C33.8452 36.1401 36.3229 38.6972 39.4835 38.8083C39.5788 38.7925 39.7852 38.7925 39.9917 38.8083C43.1047 38.6654 45.5505 36.1083 45.5664 32.9636C45.5664 29.7395 42.9299 27.1031 39.7058 27.1031C36.4817 27.1031 33.8452 29.7395 33.8452 32.9636Z" fill="url(#paint2_linear_15415_23721)"/>
 <path d="M31.0807 56.1671C28.8731 54.6901 27.666 52.673 27.666 50.4971C27.666 48.3213 28.889 46.3201 31.0807 44.8589C35.8295 41.6824 43.5484 41.6824 48.2972 44.8589C48.8372 45.2242 48.996 45.9707 48.6307 46.5107C48.2654 47.0666 47.519 47.2095 46.979 46.8442C43.0243 44.2077 36.3537 44.2077 32.399 46.8442C30.8743 47.8607 30.0484 49.1471 30.0484 50.4971C30.0484 51.8471 30.8901 53.1654 32.399 54.1819C34.3207 55.4683 36.9095 56.1671 39.689 56.1671C40.3401 56.1671 40.8801 56.7071 40.8801 57.3583C40.8801 58.0095 40.356 58.5654 39.7048 58.5654C36.4648 58.5654 33.3995 57.7236 31.0807 56.1671Z" fill="url(#paint3_linear_15415_23721)"/>
 <defs>
 <linearGradient id="paint0_linear_15415_23721" x1="44.3167" y1="50.9806" x2="51.8631" y2="52.1868" gradientUnits="userSpaceOnUse">
 <stop stop-color="#FFE3AE"/>
 <stop offset="1" stop-color="#FFD07A"/>
 </linearGradient>
 <linearGradient id="paint1_linear_15415_23721" x1="44.3167" y1="50.9806" x2="51.8631" y2="52.1868" gradientUnits="userSpaceOnUse">
 <stop stop-color="#FFE3AE"/>
 <stop offset="1" stop-color="#FFD07A"/>
 </linearGradient>
 <linearGradient id="paint2_linear_15415_23721" x1="31.6984" y1="26.4051" x2="49.8505" y2="29.3109" gradientUnits="userSpaceOnUse">
 <stop stop-color="#FFE3AE"/>
 <stop offset="1" stop-color="#FFD07A"/>
 </linearGradient>
 <linearGradient id="paint3_linear_15415_23721" x1="27.9683" y1="44.122" x2="50.8538" y2="48.9361" gradientUnits="userSpaceOnUse">
 <stop stop-color="#FFE3AE"/>
 <stop offset="1" stop-color="#FFD07A"/>
 </linearGradient>
 </defs>
 </svg>
 
 }

 const PasswordSvg = ()=> <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 8.5H18.5C19.6 8.5 20.5 9.4 20.5 10.5V20.5C20.5 21.6 19.6 22.5 18.5 22.5H6.5C5.4 22.5 4.5 21.6 4.5 20.5V10.5C4.5 9.4 5.4 8.5 6.5 8.5H7.5V6.5C7.5 3.74 9.74 1.5 12.5 1.5C15.26 1.5 17.5 3.74 17.5 6.5V8.5ZM12.5 3.5C10.84 3.5 9.5 4.84 9.5 6.5V8.5H15.5V6.5C15.5 4.84 14.16 3.5 12.5 3.5ZM7.5 20.5C6.95 20.5 6.5 20.05 6.5 19.5V11.5C6.5 10.95 6.95 10.5 7.5 10.5H17.5C18.05 10.5 18.5 10.95 18.5 11.5V19.5C18.5 20.05 18.05 20.5 17.5 20.5H7.5ZM14.5 15.5C14.5 16.6 13.6 17.5 12.5 17.5C11.4 17.5 10.5 16.6 10.5 15.5C10.5 14.4 11.4 13.5 12.5 13.5C13.6 13.5 14.5 14.4 14.5 15.5Z" fill="url(#paint0_linear_17691_53461)"/>
 <defs>
 <linearGradient id="paint0_linear_17691_53461" x1="4.72857" y1="3.64773" x2="22.5328" y2="5.81715" gradientUnits="userSpaceOnUse">
 <stop stop-color="#FFE3AE"/>
 <stop offset="1" stop-color="#FFD07A"/>
 </linearGradient>
 </defs>
 </svg>

 const AirplaneSvg = ()=> <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path d="M7.27268 16.2678L9.02018 14.7978C9.28268 14.5728 9.71768 14.5728 9.98018 14.7978L11.7277 16.2678C12.1327 16.4703 12.6277 16.2678 12.7777 15.8328L13.1077 14.8353C13.1902 14.5953 13.1077 14.2428 12.9277 14.0628L11.2252 12.3528C11.0977 12.2328 11.0002 11.9928 11.0002 11.8203V9.68281C11.0002 9.36781 11.2327 9.21781 11.5252 9.33781L15.2077 10.9278C15.7852 11.1753 16.2577 10.8678 16.2577 10.2378V9.27031C16.2577 8.76781 15.8827 8.19031 15.4177 7.99531L11.2252 6.18781C11.1052 6.13531 11.0002 5.97781 11.0002 5.84281V3.59281C11.0002 2.88781 10.4827 2.05531 9.85268 1.73281C9.62768 1.62031 9.36518 1.62031 9.14018 1.73281C8.51018 2.05531 7.99268 2.89531 7.99268 3.60031V5.85031C7.99268 5.98531 7.88768 6.14281 7.76768 6.19531L3.58268 8.00281C3.11768 8.19031 2.74268 8.76781 2.74268 9.27031V10.2378C2.74268 10.8678 3.21518 11.1753 3.79268 10.9278L7.47518 9.33781C7.76018 9.21031 8.00018 9.36781 8.00018 9.68281V11.8203C8.00018 11.9928 7.90268 12.2328 7.78268 12.3528L6.08018 14.0628C5.90018 14.2428 5.81768 14.5878 5.90018 14.8353L6.23018 15.8328C6.36518 16.2678 6.86018 16.4778 7.27268 16.2678Z" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
 </svg>
 

export {EmailSvg, DeleteSvg,PasswordSvg,AirplaneSvg}