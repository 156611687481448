import React from 'react';
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const masterCard = require("../assets/mastercard.png")
export const visa = require("../assets/visa.png")
export const americanExpress = require("../assets/americanExpress.png")
export  const defaultCard = require("../assets/creditCard.png")
export const edit_icon = require("../assets/edit_24px.png");
export const deleteIcon = require("../assets/delete_outline_24px.png")

export const AirplaneSvg = ()=> <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path d="M7.27268 16.2678L9.02018 14.7978C9.28268 14.5728 9.71768 14.5728 9.98018 14.7978L11.7277 16.2678C12.1327 16.4703 12.6277 16.2678 12.7777 15.8328L13.1077 14.8353C13.1902 14.5953 13.1077 14.2428 12.9277 14.0628L11.2252 12.3528C11.0977 12.2328 11.0002 11.9928 11.0002 11.8203V9.68281C11.0002 9.36781 11.2327 9.21781 11.5252 9.33781L15.2077 10.9278C15.7852 11.1753 16.2577 10.8678 16.2577 10.2378V9.27031C16.2577 8.76781 15.8827 8.19031 15.4177 7.99531L11.2252 6.18781C11.1052 6.13531 11.0002 5.97781 11.0002 5.84281V3.59281C11.0002 2.88781 10.4827 2.05531 9.85268 1.73281C9.62768 1.62031 9.36518 1.62031 9.14018 1.73281C8.51018 2.05531 7.99268 2.89531 7.99268 3.60031V5.85031C7.99268 5.98531 7.88768 6.14281 7.76768 6.19531L3.58268 8.00281C3.11768 8.19031 2.74268 8.76781 2.74268 9.27031V10.2378C2.74268 10.8678 3.21518 11.1753 3.79268 10.9278L7.47518 9.33781C7.76018 9.21031 8.00018 9.36781 8.00018 9.68281V11.8203C8.00018 11.9928 7.90268 12.2328 7.78268 12.3528L6.08018 14.0628C5.90018 14.2428 5.81768 14.5878 5.90018 14.8353L6.23018 15.8328C6.36518 16.2678 6.86018 16.4778 7.27268 16.2678Z" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
 </svg>
