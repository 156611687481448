import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  styled,
  MenuItem,
  FormControl,
  InputAdornment,
  Avatar,
  Button,
  IconButton,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import * as Yup from 'yup';
import "./UserProfileBasicBlock.css";
import { SearchOutlined, KeyboardArrowDown, Edit, BorderColorOutlined, RoomOutlined, PhotoCameraOutlined, ChevronRight,ArrowBack, Visibility , VisibilityOff, CameraAltOutlined} from "@material-ui/icons";
import {CustomStyledPopper, CustomFormTextField, CustomLabel,HorizantalRow,FormSubmitButton, MuiModal, StyledModalContent,ModalHeading, Footer, GeneralParentBox,GoldishSimpleButton, AddLegtTypeButton, CustomFormSelectNew, MuiResuableStatusModalBody,StatusModal, RenderTimerAndResend, otpcontainerStyle, otpinputStyle, renderDeleteIconContainer, CustomAutocomplete, renderOptionFrom,PhoneNumberBox, StyledBox, PaymentCard, DFlexBetween, DFlexAlignCenter, ButtonBox} from "../../../components/src/MuiCustomComponents";
import {renderPasswordErrorBox,emailValidationSchema,otpValidationSchema,commissionKeySchema} from '../../../components/src/Validations';
import { theme as colors } from "../../../components/src/utils";
import {AirplaneSvg, EmailSvg, PasswordSvg, deleteIcon, edit_icon} from "./assets";
import {v4} from "uuid";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ICountry, IState, ICity} from 'country-state-city';
import { Formik, FormikProps } from 'formik';
import {ProfileDataType,AdminProfileDataType,setFieldErrorType,setFieldValueType,password_initial_values_type,JobRoleType, userProfileFormDataType, PhotoType,Airports} from "./UserProfileBasicBlockController.web"
import OtpInput from 'react-otp-input';
import {AutocompleteRenderInputParams} from '@material-ui/lab'


type InitiailValue =  {
    
    optedCountry:ICountry | {name:string|null},
    preferredAirport : {name:string,city:string,code:string}
}
type formikCobmineTypeType = ProfileDataType & InitiailValue;
type CombineLocationType = ICountry | IState | ICity


const adminSelectProps = {
  getContentAnchorEl: null,
  className: "profile_ditry_menu",
  anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
  },
  transformOrigin: {
      vertical: "top",
      horizontal: "left",
  },
}

const passwordValidationSchema = Yup.object().shape({
  old_password: Yup.string().required('Old Password is required*'),
  new_password: Yup.string()
      .required('New Password is required*')
      .notOneOf([Yup.ref('old_password'), null], 'New Password must be different from Old Password*')
      .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          'Password must be strong*'
      ),
  confirm_password: Yup.string()
      .required('Confirm New Password is required*')
      .oneOf([Yup.ref('new_password'), null], 'Confirm New Password must match New Password*')
      .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          'Password must be strong*'
      )
});

const DefaultMenuItem = ()=><MenuItem style={{ display: "none" }}>Please Specify</MenuItem>



const profileValidations = Yup.object().shape({
  phoneNumber: Yup.string().required('Phone number is required*'),
  full_name: Yup.string()
    .trim()  // This will trim leading and trailing spaces
    .matches(/^[a-zA-Z\s'-]+$/, "Invalid Full Name*")
    .min(1, 'Name cannot be empty*')
    .required('Full name is required*')
    .test('is-not-only-spaces', 'Name cannot be only spaces', value => {
      return value && value.trim().length > 0;
    }),
    preferredAirport: Yup.object().shape({
      name: Yup.string()
        .required("Preffered Airport is required*")
        .min(1, "Preffered Airport can't be empty")
    })
    .nullable()
    .required('Preffered Airport is required*')
});

const fullNameValidation = Yup.object().shape({
    full_name: Yup.string()
      .trim() 
      .matches(/^[a-zA-Z\s'-]+$/, "Invalid Full Name*")
      .min(1, 'Name cannot be empty*')
      .required('Full name is required*')
      .test('is-not-only-spaces', 'Name cannot be only spaces', value => {
        return value && value.trim().length > 0;
      }),
  });


  const adminProfileSchema = Yup.object().shape({
    commission: commissionKeySchema,
    full_name: fullNameValidation.fields.full_name,
    account_type: Yup
    .string()
    .notOneOf(['none'], 'Account Type is required*')
    .required('Account type is required*')
  });

  const createNewAdminSchema = Yup.object().shape({
    email: emailValidationSchema.fields.email,
    ...adminProfileSchema.fields
  })
  
  

const GridContainer = styled(Grid)({
    marginTop: '-3rem',  
  });

  const GridItem = styled(Grid)({
    paddingTop: '0px',  
    paddingBottom: '0px',
  });

  const skyCards = [
    {
      type:"Debit",
      remaining:"$12,300",
    },
    {
      type:"25 Hour",
      remaining:"12Hrs & 32Mins",
      aircraft:"Super Midsize Jet",
    }
  ]
  const debitCards = [
    {
      type:"debit",
      cardholder_name:"Leslie Alexander",
      card_number:"5678"
    },
    {
      type:"Visa",
      cardholder_name:"Leslie Alexander",
      card_number:"5678"
    }
  ]
// Customizable Area End

import UserProfileBasicBlockController, {
  Props,
} from "./UserProfileBasicBlockController.web";
import { DebitSkyCardIcon, DebitSkyCardSmallerIcon, SkyCardDebitCardIcon, SkyCardIcon } from "../../../components/src/assets";
import { AddPaymentMethodModal } from "../../../components/src/ReUsableModal";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class UserProfileBasicBlock extends UserProfileBasicBlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start

            renderCameraContainer=()=>{
                return <Box className="profile_pic_camera">
                    <PhotoCameraOutlined style={{fontSize:'2rem'}}/>
                    <Box>Add Picture</Box>
                </Box>
            }

            renderAvatar = (image: string | Blob|undefined) => {
                
                return <Avatar style={{height:'100%',width:'100%'}} src={this.handleImageBlob(image)} className="profile_avatar"/>
            }

            renderProfileContainer = (formikProps: FormikProps<formikCobmineTypeType>) => {
                const {accountId,profileData:{account_id,full_name},isEditAdminModeOpen} = this.state;
                const {setFieldError,setFieldValue,values,errors} = formikProps
                return (
                  <Box className="profile_profile_pic_cont" height={"10.5rem"}>
                      <Box>
                      {this.renderProfileModal(()=>{this.handleProfilePictureModalClose(false)},setFieldValue,setFieldError,this.deleteUserProfilePicture)}
                         {isEditAdminModeOpen?this.renderProfilePictureEdit(values.photo):<Box className="profie_profile_pic" width={"9rem"} height={"9rem"}>
                          {account_id && this.renderAvatar(values.photo as string)}
                          </Box>}
                        <Box className="login_valid_error" id="profile_error_properties">
                      {errors.photo}
                    </Box>                        
                      </Box>
                      <Box className="profie_details_cont" height={"40%"}>
                         <Box className="profile_pic_container" justifyContent={"space-between"}>
                            <Box className="profile_name_style">{full_name}</Box>
                         </Box>
                         <Box className="profile_user_id">Account ID: {accountId}</Box>
                      </Box>
                   </Box>
                )
            }

            renderHorizantalRow = () => {
                return (
                    <Box className="profile_horizantal_row">
                        <HorizantalRow width={"100%"} />
                    </Box>
                )
            }

            renderStateCountryCityOption = (option: CombineLocationType) => {
                return (
                    <Box className={`register_airport_option`}>
                        <Box color="#636E72">
                            <RoomOutlined />
                        </Box>
                        <Box>{option.name}</Box>
                    </Box>
                )
            }

            renderRow1 = (formikProps:FormikProps<formikCobmineTypeType>) => {
              const {isEditAdminModeOpen,searchedAirports} = this.state
              
              const {values, touched, errors,setFieldValue} = formikProps;
                return (
                    <>
                    <GridItem item md={6} sm={12} style={{padding:"15px 20px"}}>
                      <CustomLabel>Full Name</CustomLabel>
                      <CustomFormTextField
                        autoComplete="off"
                        value={values.full_name}
                        placeholder="Enter Name"
                        size="medium"
                        fullWidth
                        name="full_name"
                        onChange={formikProps.handleChange}
                        disabled={!isEditAdminModeOpen}
                        isFocusedTypeInput={true}
                        variant={"outlined"}
                        initialBorderColor="#2D3034"
                        isErrorTouch={touched.full_name && errors.full_name}
                        data-test-id="fullName"
                        multiline                        
                        height={"auto"}
                        minHeight={"4rem"}
                      />
                      {touched.full_name && errors.full_name && <Box className="register_valid_error" position="absolute">
                        {errors.full_name}
                      </Box>}
                    </GridItem>
                    <GridItem item md={6} sm={12} style={{padding:"15px 20px"}}>
                      <CustomLabel>Preffered Departure Airport</CustomLabel>
                      <CustomAutocomplete
                        bgColor={"#0D1015"}
                        onChange={(_event: Event, newValue: string) => {
                          setFieldValue("preferredAirport", newValue)
                          setFieldValue("preferred_departure_airport",newValue)
                        }}
                        PopperComponent={CustomStyledPopper}
                        ListboxProps={{ id: "regsiter_option_style" }}
                        data-test-id="airport"
                        getOptionLabel={(option: Airports) => this.getOptionLabel({ name: option.name, city: option.countryCode, code: option?.code })}
                        renderOption={(option: Airports, { selected }: { selected: boolean }) => renderOptionFrom(option, selected)}
                        options={searchedAirports}
                        value={values.preferredAirport}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                          <CustomFormTextField
                            {...params}
                            placeholder="Search Departure Location"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <Box className="profile_hover_icons">
                                  {isEditAdminModeOpen ? <KeyboardArrowDown /> : <></>}
                                </Box>
                              ),
                              startAdornment: (
                                <Box>
                                  {isEditAdminModeOpen ? <SearchOutlined /> : <></>}
                                </Box>
                              ),
                            }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleAirportSearch(event.target.value)}
                            isFocusedTypeInput={true}
                            variant={"outlined"}
                            initialBorderColor="#2D3034"                           
                          />
                        )}
                        showError={touched.preferredAirport && errors.preferredAirport}
                        disabled={!isEditAdminModeOpen}
                      />
                      {touched.preferredAirport && errors.preferredAirport && <Box className="register_valid_error" position="absolute">
                        {errors.preferredAirport}
                      </Box>}
                    </GridItem>
                    </>
                )
            }

            renderRow2 = (formikProps:FormikProps<formikCobmineTypeType>) => {
              const {profileData,isEditAdminModeOpen} = this.state
                const {values, touched, errors,handleChange} = formikProps;
                return (
                    <>
                        <GridItem item md={6} sm={12} style={{padding:"15px 20px"}}>
                            <CustomLabel>Phone Number</CustomLabel>
                            <PhoneNumberBox
                            actionType={isEditAdminModeOpen}
                            showError={touched.phoneNumber && errors.phoneNumber}
                            className="phoneNumberField">
                            <PhoneInput
                                onChange={(value, data:{dialCode:string}, event, formattedValue)=>{
                                    const countryCode = data.dialCode; 
                                    const phoneNumber = countryCode && value.substring(countryCode.length);
                                    formikProps.setFieldValue("phoneNumber",phoneNumber);
                                    formikProps.setFieldValue("countryCode",countryCode); 
                                    formikProps.setFieldValue("phone_number",value)                                   
                                  }}
                                enableSearch={true}
                                searchClass="search_country_code"
                                searchPlaceholder="Search"  
                                inputStyle={touched.phoneNumber && errors.phoneNumber ? {border:"1px solid #F87171"}: {border:"1px solid #2D3034"}}
                                containerStyle={{border:"none"}}
                                inputClass={`phone_number_inputField`}
                                dropdownClass="register_drop_down_class"
                                containerClass="regsiter_contaitner_class"
                                country="us"
                                value={values.phone_number}
                                placeholder="Your Phone Number"
                                disabled={!isEditAdminModeOpen}
                                dropdownStyle={{width:document.querySelector(".react-tel-input")?.clientWidth,backgroundColor:"#3E4247",borderTopRightRadius:"0",borderTopLeftRadius:"0px",marginTop:"2px"}}
                            />
                            </PhoneNumberBox>
                            {touched.phoneNumber && errors.phoneNumber && <Box className="login_valid_error" id="profile_error_properties">
                      {errors.phoneNumber}
                    </Box>}
                        </GridItem>
                        <GridItem item md={6} sm={12} style={{padding:"15px 20px"}}>
                            <CustomLabel>Company Name</CustomLabel>
                            <CustomFormTextField
                                autoComplete="off"
                                value={values.companyName}
                                placeholder={isEditAdminModeOpen?"Enter company name":"---"}
                                size="medium"
                                fullWidth
                                name="companyName"
                                disabled={!isEditAdminModeOpen}
                                isFocusedTypeInput={true}
                                variant="outlined"
                                initialBorderColor="#2D3034"
                                onChange={handleChange}
                                multiline                        
                                height={"auto"}
                                minHeight={"4rem"}                               
                            />
                        </GridItem>
                    </>
                )
            }

            renderRow3 = (formikProps:FormikProps<formikCobmineTypeType>) => {
                const { isEditAdminModeOpen } = this.state;
                const {values, handleChange} = formikProps;
                return (
                    <>
                        <GridItem item md={6} sm={12} style={{padding:"15px 20px"}}>
                            <CustomLabel>Street Address</CustomLabel>
                            <CustomFormTextField
                                autoComplete="off"
                                placeholder={isEditAdminModeOpen?"Enter street address":"---"}
                                size="medium"
                                fullWidth
                                name="street_address"
                                value={values.street_address}
                                onChange={handleChange}
                                disabled={!isEditAdminModeOpen}
                                isFocusedTypeInput={true}
                                variant="outlined"
                                initialBorderColor="#2D3034"
                                multiline                        
                                height={"auto"}
                                minHeight={"4rem"}
                            />
                        </GridItem>
                        <GridItem item md={6} sm={12} style={{padding:"15px 20px"}}>
                            <CustomLabel>City</CustomLabel>
                            <CustomFormTextField
                                autoComplete="off"
                                value={values.city}
                                placeholder={isEditAdminModeOpen?"Enter city":"---"}
                                size="medium"
                                fullWidth
                                name="city"
                                disabled={!isEditAdminModeOpen}
                                variant="outlined"
                                isFocusedTypeInput={true}
                                initialBorderColor="#2D3034"
                                onChange={handleChange}
                                data-test-id="city"
                                multiline                        
                                height={"auto"}
                                minHeight={"4rem"}
                            />
                            
                        </GridItem>
                    </>
                )
            }

            renderRow4 = (formikProps:FormikProps<formikCobmineTypeType>) => {
                const { isEditAdminModeOpen } = this.state;
                const {values, errors, touched,handleChange} = formikProps
                return (
                    <>
                        <GridItem style={{ zIndex: 1, padding:"15px 20px" }} item md={6} sm={12}>
                            <CustomLabel>State</CustomLabel>
                            <CustomFormTextField
                                autoComplete="off"
                                value={values.state}
                                placeholder={isEditAdminModeOpen?"Enter state":"---"}
                                size="medium"
                                fullWidth
                                name="state"
                                disabled={!isEditAdminModeOpen}
                                variant="outlined"
                                isFocusedTypeInput={true}
                                initialBorderColor="#2D3034"
                                onChange={handleChange}
                                data-test-id="state"
                                multiline                        
                                height={"auto"}
                                minHeight={"4rem"}

                            />
                        </GridItem>
                        <GridItem item md={6} sm={12} style={{padding:"15px 20px"}}>
                            <CustomLabel>Zip Code</CustomLabel>
                            <CustomFormTextField
                                autoComplete="off"
                                placeholder={isEditAdminModeOpen?"Enter zip code":"---"}
                                size="medium"
                                fullWidth
                                name="zip_code"
                                className={touched.zip_code && errors.zip_code ? "register_border_error" : ""}
                                onChange={handleChange}
                                value={values.zip_code}
                                disabled={!isEditAdminModeOpen}
                                isFocusedTypeInput={true}
                                variant="outlined"
                                initialBorderColor="#2D3034"
                                multiline                        
                                height={"auto"}
                                minHeight={"4rem"}
                            />
                        </GridItem>
                    </>
                )
            }

            renderRow5 = (formikProps: FormikProps<formikCobmineTypeType>) => {
                const { dietryData, countries,isEditAdminModeOpen,focusedLabel } = this.state
                const {values} = formikProps
                return (
                    <>
                        <GridItem item md={6} sm={12} style={{padding:"15px 20px"}}>
                            <CustomLabel>Main Country of Residence</CustomLabel>
                            <CustomAutocomplete
                                bgColor={"#0D1015"}                                
                                onChange={(_event: Event, newValue: ICountry) => {
                                    formikProps.setFieldValue('optedCountry',newValue);
                                    
                                }}
                                id = 'profile_country_selector'
                                PopperComponent={CustomStyledPopper}
                                value={values.optedCountry}
                                ListboxProps={{ id: "regsiter_option_style",style:{border:"none !important"} }}
                                isFocusedTypeInput={true}
                                
                                getOptionLabel={(option: {name : string}) => option.name}
                                options={countries}
                                renderOption={(
                                    option: ICity | IState | ICountry,
                                    { selected }: { selected: boolean },
                                ) => <>{this.renderStateCountryCityOption(option)}</>}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <CustomFormTextField
                                        {...params}
                                        placeholder={isEditAdminModeOpen?"Search Country":"---"}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <Box className="profile_hover_icons">
                                                    {isEditAdminModeOpen?<KeyboardArrowDown/>:<></>}
                                                </Box>
                                            ),
                                            startAdornment: (
                                                <Box>
                                                    {isEditAdminModeOpen ?<SearchOutlined />:<></>}
                                                </Box>
                                            ),
                                        }}
                                        variant="outlined"
                                        isFocusedTypeInput={true}
                                        initialBorderColor="#2D3034"                                        
                                    />
                                )}
                                noOptionsText={<Box pt={1}>No Options</Box>}
                                disabled={!isEditAdminModeOpen}
                                
                            />
                        </GridItem>

                        <GridItem item md={6} sm={12} style={{padding:"15px 20px"}}>
                            <CustomLabel>Dietery Requirements</CustomLabel>
                            <FormControl placeholder={isEditAdminModeOpen?"Please Verify":"---"} fullWidth>
                                <CustomFormSelectNew
                                    labelId="demo-simple-select-label"
                                    variant="outlined"
                                    className="profile_custom_select"
                                    name="dietary_requirements"
                                    id="dietary_requirements"
                                    value={values.dietary_requirements || 'Please Specify'}    
                                    onChange={formikProps.handleChange}
                                    inputProps={{
                                        IconComponent:isEditAdminModeOpen?KeyboardArrowDown:()=><></>
                                    }}
                                    MenuProps={{
                                      PaperProps:{
                                        style:{
                                          marginTop:"4.1rem",
                                          height:180,
                                          borderTop:"none",
                                          borderRadius:"0 0 0.5rem 0.5rem"
                                        }
                                      },
                                      getContentAnchorEl: null,
                                      className: "profile_ditry_menu diet_dropdown",
                                    }}
                                    userProfile={true}
                                    disabled={!isEditAdminModeOpen}
                                    onOpen={()=>this.handleActiveFocusedLabelProfie("diet")}
                                    onClose={()=>this.handleActiveFocusedLabelProfie("")}
                                    focusedField={focusedLabel==="diet"}
                                >
                                    <MenuItem
                                        style={{ display: "none" }}
                                        value={"Please Specify"}
                                    >
                                        {isEditAdminModeOpen?"Please Specify":"---"}
                                    </MenuItem>
                                    {dietryData.map((dietryElem: string) => (
                                        <MenuItem key={v4()} value={dietryElem}>
                                            {dietryElem}
                                        </MenuItem>
                                    ))}
                                </CustomFormSelectNew>
                            </FormControl>
                        </GridItem>
                    </>
                )
            }

            renderForm = (formikProps: FormikProps<formikCobmineTypeType>) => {
                return (
                    <Box>                  
                                    <Grid container spacing={5}>
                                        {this.renderRow1(formikProps)}
                                        {this.renderRow2(formikProps)}
                                        {this.renderRow3(formikProps)}
                                        {this.renderRow4(formikProps)}
                                        {this.renderRow5(formikProps)}
                                    </Grid>
                    </Box>
                )
            }

            renderSubmitAndCancel=(formikProps: FormikProps<formikCobmineTypeType>)=>{
                return <Box className="profile_submission">
                <FormSubmitButton onClick={()=>{formikProps.handleReset();this.handleResetForm();}} style={{color:"white",background:"#3E4247"}}  fullWidth>Cancel</FormSubmitButton>
                <FormSubmitButton onClick={()=>formikProps.handleSubmit()} fullWidth className="profile_test_submit" disabled={this.state.isLoading}>Save Changes</FormSubmitButton>
                </Box>
            }

            renderProfileModal = (handleSuccess:()=>void,setFieldValue:setFieldValueType,setFieldError :setFieldErrorType,deleteProfilePic:()=>void) => {
                const {isCreateNewAdmin} = this.state;
                const addEditText = isCreateNewAdmin ? 'Add' : 'Edit';
                return (
                  <MuiModal
                    open={this.state.isEditProfileModalVisible}
                    onClose={() => {this.handleProfilePictureModalClose(false)}}
                  >
                    <StyledModalContent>
                            <ModalHeading>{addEditText} Profile Picture</ModalHeading>
                            <Box className="profile_pic_edit_buttons_cont">
                                      
                            <Button fullWidth className="profile_pic_edit_buttons" onClick={()=>{this.inputFileRef.current?.click()}}>Upload 
                            
                                <input
                                     type="file"
                                     id="file-input"
                                     ref={this.inputFileRef}
                                     accept=".png, .jpg, .jpeg"
                                     style={{ display: "none" }}
                                     onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>,
                                     ) => {
                                        if (
                                           event.target.files &&
                                           event.target.files.length > 0
                                        ) {
                                            const file = event?.target?.files[0];
                                            const maxSize = 5 * 1024 * 1024;
                                            const allowedTypes = ["image/png", "image/jpg", "image/jpeg"];
                                            if (allowedTypes.includes(file.type) && file.size <= maxSize) {
                                                setFieldValue("photo", file);
                                                handleSuccess();
                                            } else {
                                                setFieldError("photo","Please add valid image");
                                            }
                                        }
                                     
                                     }}
                                  />
                            </Button>
                            {!isCreateNewAdmin && <Button fullWidth className="profile_pic_edit_buttons" onClick={deleteProfilePic}>Remove photo</Button>}
                            </Box>
                            <FormSubmitButton onClick={()=>this.handleProfilePictureModalClose(false)} style={{color:"white",background:"#3E4247"}}  fullWidth>Cancel</FormSubmitButton>
                    </StyledModalContent>
                  </MuiModal>
                )
            }

            renderFullNameEditModal = ()=>{
                const {profileData:{full_name}} = this.state;
                return <MuiModal
                    open = {this.state.isEditFullNameModalVisible}
                    onClose={() => {this.handleEditFullNameModalClose(false)}}
                >
                      <StyledModalContent>
                           <ModalHeading>Change Your Name</ModalHeading>
                           <Box className='profile_gen_modal_margin'>Enter Your Full Name</Box>
                           <Box>        
                                <Formik
                                    initialValues={{full_name}}
                                    onSubmit={(values) => {
                                        this.updateUserProfileDetails((values as {full_name : string}),'full_name');
                                    }}
                                    validationSchema={fullNameValidation}
                                    validateOnChange
                                    validateOnBlur
                                    enableReinitialize 
                                >
                                    {({
                                        handleSubmit,
                                        errors,
                                        values,
                                        handleChange,
                                        touched
                                    }) => (<>
                                        <CustomLabel>Full Name</CustomLabel>
                                        <CustomFormTextField
                                        autoComplete="off"
                                        placeholder="Enter Full Name"
                                        size="medium"
                                        fullWidth
                                        name="full_name"
                                        value={values.full_name}
                                        onChange={handleChange}
                                        id="profile_test_full_name"
                                        className={touched.full_name && errors.full_name ? "register_border_error" : ""}
                                        />
                                        {touched.full_name && errors.full_name && <Box className="register_valid_error" position="absolute">
                                            {errors.full_name}
                                        </Box>}
                                        <Box className="profile_gen_modal_submit_cont">
                                        <FormSubmitButton style={{color:"white",background:"#3E4247"}}  fullWidth onClick={()=>this.handleEditFullNameModalClose(false)}>Cancel</FormSubmitButton>
                                        <FormSubmitButton fullWidth className="profile_test_submit" onClick={()=>handleSubmit()}>Save Changes</FormSubmitButton>
                                        </Box>
                                    </>)}
                                </Formik>       
                                
                           </Box>
                      </StyledModalContent>  
                </MuiModal>
            }

            renderFooter=(formikProps: FormikProps<formikCobmineTypeType>)=>{
                return <Footer>
                    {this.state.isEditAdminModeOpen ?this.renderSubmitAndCancel(formikProps):(
                      <Box display={"flex"} justifyContent={"center"} width={"100%"}>
                        <FormSubmitButton style={{width:"20rem"}} fullWidth  className="edit_profile_admin" onClick={()=>{formikProps.handleReset(); this.handleEditAdminProfileMode(true)}}>Edit Profile</FormSubmitButton>
                      </Box>
                    )}
                    
                </Footer>
            }

            renderUserProfileParent = ()=>{
              return (
                <Box className="booking_section">
                  {this.renderProfileHeading()}
                  {this.renderUserProfile()}
                  {!this.state.isEditAdminModeOpen && (
                    <Box className="profile_main_container">
                    <Box display={"flex"} justifyContent={"space-between"} gridColumnGap={"2.5rem"}>
                      {this.renderAdminEmail()}
                      {this.renderAdminEmail(true)}
                    </Box>
                  </Box>
                  )}
                  <Box height={"4rem"}></Box>
                  {this.renderEditEmailModal()}
                  {this.renderChangePasswordModal()}
                  {this.renderOtp()}
                  {this.renderMuiModal()}
                </Box>
              )
              
            }

            renderUserProfile = () => {
              const {isEditAdminModeOpen,profileData} = this.state
                const initialValues = {
                  optedCountry: {name:profileData.main_country_of_residence},
                  preferredAirport:{name:profileData.preferred_departure_airport,city:"",code:""},
                  ...this.state.profileData,
                }
              
                return (
                  <Box className='profile_main_container' style={{marginBottom:isEditAdminModeOpen?"9rem":"2.5rem"}}>
                    <Formik
                      initialValues={initialValues}
                      onSubmit={(values) => {
                        this.updateUserProfileDetails(values as userProfileFormDataType, "profileDetails")
                      }}
                      validationSchema={profileValidations}
                      validateOnChange
                      validateOnBlur
                      enableReinitialize
                      innerRef={(formikProps) => {
                        this.formikForm = formikProps as FormikProps<unknown>
                      }}
                    >
                      {(props: FormikProps<formikCobmineTypeType>) => (
                        <form onSubmit={props.handleSubmit}>
                          {this.renderProfileContainer(props)}
                          {this.renderHorizantalRow()}
                          {this.renderForm(props)}
                          {this.renderFooter(props)}
                        </form>
                      )}
                    </Formik>
                  </Box>
                )
              }


              renderAdminProfileParent=()=>{
                return <Box className="booking_section admin_section_parent">
                    {this.renderProfileHeading()}
                    {this.renderAdminProfile()}
                    {this.renderAdminFooter()}
                    {this.renderEditEmailModal()}
                    {this.renderChangePasswordModal()}
                    {this.renderMuiModal()}
                    {this.renderOtp()}
                    {this.renderDeleteAccountConfirmationModal()}
                </Box>
              }

              renderMuiModal=()=>{
                const {statusModalContent:{heading,subHeading},userRole} = this.state;
                const isAdmin = userRole !== "user"
                return <MuiModal
                open={this.state.isStatusModalVisible}
              >
                <StatusModal className="status_update_modal">
                <MuiResuableStatusModalBody
                  heading = {heading}
                  subHeading = {subHeading}
                >
                  <FormSubmitButton onClick={()=>this.handleStatusVisible(false)} fullWidth className="okay_button">{isAdmin?"Okay":"Back To Profile"}</FormSubmitButton>
                </MuiResuableStatusModalBody>
                              
                </StatusModal>
              </MuiModal>
              }

              renderDeleteAccountConfirmationModal=()=>{
                return <MuiModal
                open={this.state.isDeleteProfileModalVisible}
              >
                <StatusModal className="status_update_modal_delete">
                <MuiResuableStatusModalBody
                  heading = {'Delete Account'}
                  subHeading = {'Are you sure? This action is permanent.'}
                  iconCont = {()=>renderDeleteIconContainer()}
                >
                   <Box style={{display:"flex",justifyContent:"space-between",width:"30rem",columnGap:"1rem"}}>
                         <AddLegtTypeButton className = 'yes_button' style={{width:'50rem',color:'#F87171',border:'1px solid #F87171'}} fullWidth onClick={this.deleteAdminAccount}>Yes</AddLegtTypeButton>
                           <FormSubmitButton style={{width:'50rem'}} fullWidth  className="no_button" onClick={()=>this.handleDeleteAccountModalVisible(false)}>No</FormSubmitButton>
                         </Box>
                </MuiResuableStatusModalBody>
                              
                </StatusModal>
              </MuiModal>
              }



              renderProfileHeading=()=>{
                const {isEditAdminModeOpen,isCreateNewAdmin,isAdminProfileUpdateRoute} = this.state;
                const mainViewHead = isAdminProfileUpdateRoute ? <><ArrowBack className = 'cf_avinod_arrow_search' onClick={this.handleNavigateToAdminList}/> Admin Information</> : 'Profile';
                const editSubHead = this.handleSubheading()
                const editHead = !isCreateNewAdmin ?  editSubHead : 'Create New Admin';
                const heading  = isEditAdminModeOpen ? <><ArrowBack className = 'cf_avinod_arrow_search' onClick={this.handleCancel}/> {editHead}</> : mainViewHead
                return <GoldishSimpleButton style={{ color: 'white', textTransform: "capitalize", justifyContent:'start',fontSize:'1.6rem'}}>{heading}</GoldishSimpleButton>
              }

              renderAdminProfile=()=>{
                const {isEditAdminModeOpen} = this.state;
                return <GeneralParentBox style={{marginBottom:'7rem',padding:'1rem'}}>
                   {!isEditAdminModeOpen ? <Grid container justifyContent="space-between">{this.renderViewAdminProfile()}</Grid> : this.renderEditAdminProfile()}
                </GeneralParentBox>
              }

              renderViewAdminProfile=()=>{
                return <>
                    <Grid item lg={4}  md={12} style={{padding:'1rem'}}>{this.renderImageAndEmail()}</Grid>
                    <Grid item lg={8}  md={12} style={{padding:'1rem'}}>{this.renderPersonalInfo()}</Grid>
                </>
              }

              renderEditAdminProfile = () => {
                const {adminData, isCreateNewAdmin} = this.state;
                const createNewAdminInitialValue = isCreateNewAdmin ? {email:''}:{}
                const newSchema = isCreateNewAdmin ? createNewAdminSchema : adminProfileSchema;
                return (
                  <Formik
                    initialValues={{...adminData,...createNewAdminInitialValue}}
                    validationSchema={newSchema}
                    onSubmit={(values) => {
                      this.updateAdminProfileDetails(values);
                    }}
                    innerRef={(formikProps) => {
                      this.adminFormik = formikProps as FormikProps<unknown>
                    }}
                    validateOnChange
                    validateOnBlur
                    enableReinitialize
                  >
                    {(formikProps) => (
                      <Grid container justifyContent='space-between'>
                        {this.renderProfileModal(()=>this.handleProfilePictureModalClose(false),formikProps.setFieldValue,formikProps.setFieldError,this.deleteAdminProfilePicture)}
                        <Grid item lg={2} md={12}>
                          <Box className='profile_center_iamge_edit profile_center_iamge_edit_image_box'>
                          {this.renderProfilePictureEdit(formikProps.values.photo)}
                          { !isCreateNewAdmin && this.renderAccountId()}
                          </Box>
                        </Grid>
                        <Grid item lg={10} md={12}>
                          {this.renderEditAdminForm(formikProps)}
                        </Grid>
                      </Grid>
                    )}
                  </Formik>
                )
              }
              

              renderEditAdminForm = (formikProps:FormikProps<AdminProfileDataType>) => {
                const { focusedLabel,roles,jobRolesData, isCreateNewAdmin } = this.state;
                const {values:{full_name,commission,account_type,job_id},handleChange,touched,errors} = formikProps;
                return (
                  <Box
                    className='booking_section edit_system_setting_parent edit_admin_form'
                    style={{ rowGap: "0rem"}}
                  >

                    {isCreateNewAdmin &&   <Box style={{borderBottom:'1px solid #3E4247',padding:'2rem 1.5rem'}}>
                      <CustomLabel
                        style={{ margin: "0", marginBottom: "5px" }}
                        isFocused={focusedLabel === "email"}
                        isError={touched.email && errors.email}
                      >
                        Email Address
                      </CustomLabel>
                      <CustomFormTextField
                        isFocusedTypeInput={true}
                        isErrorTouch = {touched.email && errors.email}
                        variant='outlined'
                        fullWidth
                        id='email'
                        name='email'
                        className={`admin_full_name`}
                        value={formikProps.values.email}
                        placeholder={"Enter Email Address"}
                        onFocus={() => {
                          this.handleActiveFocusedLabelProfie("email")
                        }}
                        onBlur={() => {
                          this.handleActiveFocusedLabelProfie(null)
                        }}
                        onChange={handleChange}
                        autoComplete='off'
                      />
                       {touched.email && errors.email && <Box className="register_valid_error" position="absolute">
                            {errors.email}
                        </Box>}
                    </Box>}

                    <Box className="admin_profile_each_sec">
                      <CustomLabel
                        style={{ margin: "0", marginBottom: "5px" }}
                        isFocused={focusedLabel === "full_name"}
                        isError={touched.full_name && errors.full_name}
                      >
                        Full Name
                      </CustomLabel>
                      <CustomFormTextField
                        isFocusedTypeInput={true}
                        isErrorTouch = {touched.full_name && errors.full_name}
                        variant='outlined'
                        fullWidth
                        id='full_name'
                        name='full_name'
                        className={`admin_full_name`}
                        value={full_name}
                        placeholder={"Enter Full Name"}
                        onFocus={() => {
                          this.handleActiveFocusedLabelProfie("full_name")
                        }}
                        onBlur={() => {
                          this.handleActiveFocusedLabelProfie(null)
                        }}
                        onChange={handleChange}
                        autoComplete='off'
                        InputProps={{style:{padding:'0'}}}
                      />
                       {touched.full_name && errors.full_name && <Box className="register_valid_error" position="absolute">
                            {errors.full_name}
                        </Box>}
                    </Box>
                    <Box className="admin_profile_each_sec">
                      <CustomLabel
                        style={{ margin: "0", marginBottom: "5px" }}
                        isFocused = {focusedLabel ===  'job_title'}
                      >
                        Job Title
                      </CustomLabel>
                      <FormControl
                          className="mui_select_unkownn-1"
                          fullWidth 
                       >
                        <CustomFormSelectNew
                          labelId='demo-simple-select-label'
                          onFocus={() => {
                            this.handleActiveFocusedLabelProfie("job_title")
                          }}
                          onBlur={() => {
                            this.handleActiveFocusedLabelProfie(null)
                          }}
                          variant='outlined'
                          className='admin_job_title'
                          inputProps={{
                            IconComponent: KeyboardArrowDown,
                          }}
                          value={job_id}
                          name='job_id'
                          onChange={handleChange}
                          fullWidth
                          MenuProps={{...adminSelectProps,
                            style:{marginTop:'5px',zIndex:6},
                            className: "advance_search_picker job_title_picker",
                          }}
                          style={{color : this.conditionHandler(job_id === 'none','grey',''),backgroundColor:'black'}}
                        >
                          {
                          (jobRolesData as JobRoleType[]).map(({attributes:{job_title},id}:JobRoleType)=>{
                            return <MenuItem value={id}>
                              <Box style={{maxWidth:'65rem',overflow:'hidden',textOverflow:"ellipses",whiteSpace:'nowrap'}}>{job_title}</Box>
                              </MenuItem>
                          })}
                          <MenuItem value={'none'} style={{display:'none'}}>Choose Job Title</MenuItem>
                        </CustomFormSelectNew>
                      </FormControl>
                    </Box>
                    <Box className="admin_profile_each_sec">
                      <CustomLabel
                        style={{ margin: "0", marginBottom: "5px" }}
                        isFocused={focusedLabel === "account_type"}
                      >
                        Account Type
                      </CustomLabel>
                      <FormControl 
                       className="mui_select_unkownn"
                       fullWidth
                       >
                        <CustomFormSelectNew
                          style={{color : this.conditionHandler(account_type === 'none','grey',''),backgroundColor:"black"}}
                          onFocus={() => {
                            this.handleActiveFocusedLabelProfie("account_type")
                          }}
                          onBlur={() => {
                            this.handleActiveFocusedLabelProfie(null)
                          }}
                          variant='outlined'
                          className='admin_account_type'
                          value={account_type}
                          inputProps={{
                            IconComponent: KeyboardArrowDown, 
                          }}
                          fullWidth
                          id={'account_type'}
                          name={'account_type'}
                          MenuProps={{...adminSelectProps,
                            className: "advance_search_picker",
                            style:{marginTop:'5px'},
                          }}
                          onChange={handleChange}
                        >
                          <DefaultMenuItem/>
                          {roles.map((role)=><MenuItem value={role.roleValue} key={role.roleValue}>{role.role}</MenuItem>)}
                          <MenuItem value={'none'} style={{display:'none'}}>Choose Account Type</MenuItem>
                        </CustomFormSelectNew>
                      </FormControl>
                      {touched.account_type && errors.account_type && <Box className="register_valid_error" position="absolute">
                            {errors.account_type}
                        </Box>}
                    </Box>
                    <Box className="admin_profile_each_sec">
                      <CustomLabel
                        style={{ margin: "0", marginBottom: "5px" }}
                        isFocused={focusedLabel === "commission"}
                        isError={touched.commission && errors.commission}
                      >
                        Commission (%)
                      </CustomLabel>
                      <CustomFormTextField
                        isErrorTouch = {touched.commission && errors.commission}
                        isFocusedTypeInput={true}
                        value={commission}
                        variant='outlined'
                        fullWidth
                        id='commission'
                        className='admin_commission'
                        placeholder={"Enter Commission"}
                        onChange={handleChange}
                        onFocus={() => {
                          this.handleActiveFocusedLabelProfie("commission")
                        }}
                        onBlur={() => {
                          this.handleActiveFocusedLabelProfie(null)
                        }}
                        InputProps={{style:{padding:'0'}}}
                        autoComplete='off'
                      />
                        {touched.commission && errors.commission && <Box className="register_valid_error" position="absolute">
                            {errors.commission}
                        </Box>}
                    </Box>
                  </Box>
                )
              }
              
              
              renderImageAndEmail=()=>{
                const {adminData:{account_number,photo}} = this.state;
                return <Box className="admin_profile_avatar_parent">
                            <Box className="profie_profile_pic" height={'12.5rem'} width={'12.5rem'}><Avatar style={{height:'100%',width:'100%'}} src={photo as string} className="profile_avatar"/></Box>
                            <Box className="profile_admin_key">Account ID:</Box>
                            <Box className="acc_number" fontSize={'1.4rem'}>{account_number}</Box>
                            {this.renderAdminEmail()}
                </Box>
              }

              renderAdminEmail=(password=false)=>{
                const {userRole,adminData:{email},profileData} = this.state;
                const userEmail = userRole === "user"?profileData.email:email

                return <Box className="admin_profile_email" onClick={()=>this.handleEditEmailModalOpen(true,password)}>
                    <Box className="profile_mail_parent">
                        <Box className="profile_mail_box">{password?<PasswordSvg/>:<EmailSvg/>}</Box>
                        <Box className="profiel_email_parent">
                            <Box color={'#CBD5E1'}>{password ? "Update Password":"Update Email"}</Box>
                            <Box fontSize={'1.2rem'} color={"white"} id="user_email">{password? "********":userEmail}</Box>
                        </Box>
                    </Box>
                    <ChevronRight style={{color:"white"}}/>
                </Box>
              }

              renderPersonalInfo=()=>{
                const {adminData:{full_name,account_type,job_title,commission}} = this.state;
                const account_info = account_type === 'admin' ? 'Admin' : 'Super Admin'
                return <Box className="profile_admin_info_box" >
                    <Box fontSize={'1.8rem'} fontWeight={'500'}>Personal Info.</Box>
                    {this.renderAdminEachRowInfo('Full Name',full_name)}
                    <HorizantalRow/>
                    {this.renderAdminEachRowInfo('Job Title',job_title)}
                    <HorizantalRow/>
                    {this.renderAdminEachRowInfo('Account Type',account_info,'#34D399')}
                    <HorizantalRow/>
                    {this.renderAdminEachRowInfo('Your Commission (%)',commission + '%','#F59E0B')}
                </Box>
              }


              renderAdminEachRowInfo=(info:string,infoValue:null | string,color='string')=>{
                const value = infoValue || '- - -';
                return <Box className="profile_admin_row">
                    <Box>{info}</Box>
                    <Box color={color} style={{width:'65%', textAlign:'right', wordBreak: 'break-all'}}>{value}</Box>
                </Box>
              }

              renderAdminFooter=()=>{
                const {isEditAdminModeOpen} = this.state;
                return <Footer>
                    { !isEditAdminModeOpen ?this.renderUpdateAndEditMailButtonParent() : this.renderAdminCancelAndSaveChanges()}
                </Footer>
            }

            renderAdminCancelAndSaveChanges=()=>{
                return <Box className="profile_submission">
                <FormSubmitButton style={{color:"white",background:"#3E4247"}} className="admin_cancel_changes"  fullWidth onClick={()=>this.handleCancel()}>Cancel</FormSubmitButton>
                <FormSubmitButton fullWidth className="profile_test_submit" onClick={this.handleSaveAdminProfileChanges}>Save</FormSubmitButton>
                </Box>
            }


            renderUpdateAndEditMailButtonParent=()=>{
              const {myTokenId,adminData:{account_id}} = this.state;
              const isMyAccount = myTokenId === account_id;
              const update_pw_button = isMyAccount ? 'Update Password' : 'Reset Password';
                return <Box className="profile_submission" style={{ width : this.isAdminProfileBoolean() ? '40%' : '60%'}}>
                {!this.isAdminProfileBoolean() && <AddLegtTypeButton className = 'delete_account' style={{width:'50rem',color:'#F87171',border:'1px solid #F87171',backgroundColor:'#1A1C1E'}} fullWidth onClick={()=>this.handleDeleteAccountModalVisible(true)}>Delete Account</AddLegtTypeButton>}
                <AddLegtTypeButton className = 'update_password_button' onClick={()=>this.updateResetPwHandler(isMyAccount)} style={{width:'50rem',backgroundColor:'#1A1C1E'}} fullWidth>{update_pw_button}</AddLegtTypeButton>
                <FormSubmitButton style={{width:'50rem'}} fullWidth  className="edit_profile_admin" onClick={()=>this.handleEditAdminProfileMode(true)}>Edit Profile</FormSubmitButton>
            </Box>
            }

            
            renderEditEmailModal = ()=>{
                const {focusedLabel,myTokenId,adminData:{account_id}} = this.state;
                const isMyAccount = myTokenId === account_id;
                return <MuiModal
                    open = {this.state.isEditEmailModalOpen}
                    onClose={() => {this.handleEditEmailModalOpen(false)}}
                >
                  <Formik
                    initialValues={{email:''}}
                    innerRef={(formikProps)=>{
                      this.emailFormik = formikProps as FormikProps<unknown>
                    }}
                    onSubmit={(values)=>{
                      this.setChangeEmail(values);
                      this.updateEmailHandler(values);
                    }}
                    validationSchema={emailValidationSchema}
                    validateOnChange
                    validateOnBlur

                  >
                    {({handleChange,touched,errors,handleSubmit})=>(
                      <StyledModalContent>
                           <ModalHeading>New Email</ModalHeading>
                           <Box className='profile_gen_modal_margin'>Enter your new email address</Box>
                           <Box>        

                                <CustomLabel>Email</CustomLabel>
                                <CustomFormTextField
                                isFocusedTypeInput={true}
                                variant={'outlined'}
                                autoComplete="off"
                                placeholder="Enter New Email"
                                size="medium"
                                fullWidth
                                name = {'email'}
                                id = {'email'}
                                onChange={handleChange} 
                                className='email_update'
                                isErrorTouch={touched.email && errors.email}
                                />            
                                <Box className="profile_verification_text">
                                  {touched.email && errors.email ? <Box className="login_valid_error">
                                      {errors.email}
                                  </Box>: <Box>
                                      {isMyAccount && "A verification code will be sent to the new email address"}
                                    </Box>}
                                </Box>
                                <Box className="profile_gen_modal_submit_cont">
                                <FormSubmitButton fullWidth className="profile_test_submit email_continue" onClick={()=>{handleSubmit()}}>Continue</FormSubmitButton>
                                </Box>                     
                           </Box>
                      </StyledModalContent>  
                    )}

                  </Formik>
                </MuiModal>
            }

            renderProfilePictureEdit=(photo:PhotoType)=>{
                return <Box className="profie_profile_pic profile_pen_icon_cont_open" width={'10rem'} height={'10rem'} onClick={()=>this.handleProfilePictureModalClose(true)}>
                {!photo
                  ? this.renderCameraContainer()
                  : <>
                    {this.renderAvatar(photo)}
                    {this.renderNewPicText()}
                    {this.renderOverLay()}
                  </>   
                }
             </Box>
            }

            renderNewPicText=()=>{
              return <Box className="new_pic_text">
                  <CameraAltOutlined style={{ fontSize: "2rem" }} className="profile_pen_test"/>
                  <Box style={{textDecoration : "underline"}}>New Picture</Box>
              </Box>
            }

            renderOverLay=()=>{
              return <Box className="overlay_pic"/>
            }

            renderAccountId=()=>{
              const {adminData:{account_number}} = this.state;
            return <Box className = 'profile_mail_parent'>
                <Box className="profile_admin_key">Account ID:</Box>
                <Box className="acc_number" fontSize={'1.4rem'}>{account_number}</Box>
            </Box>
            }

            renderChangePasswordModal = () => {
              const { passwordVisiblity,isErrorPasswordContainerVisible } = this.state;
              const password_initial_values = {
                old_password:'',
                new_password:'',
                confirm_password:''
              }
              return (
                <MuiModal
                  open={this.state.isChangePasswordModalOpen}
                  onClose={() => {
                    this.handleChangePasswordModalOpen(false);
                    this.handleOffPasswordVisibility();
                  }}
                >
                  <Formik
                    initialValues={password_initial_values}
                    onSubmit={(values) => {
                      this.updateAdminPassword(values);
                    }}
                    innerRef={(formikProps) => {
                      this.updatePasswordFormik = formikProps as FormikProps<unknown>
                    }}
                    validationSchema={passwordValidationSchema}
                    validateOnChange
                    validateOnBlur
                  >
                    {(formikProps) => (
                      <StyledModalContent>
                        <ModalHeading style={{fontSize:"2.3rem"}}>Change password</ModalHeading>
                        <Box className="profile_gen_modal_margin" style={{margin:"1.5rem 0rem",marginBottom:"1.6rem"}}>
                          Your new password must be different from your previous password
                        </Box>
                        <Box display={'flex'} flexDirection={'column'} style={{rowGap:"1rem"}}>
                          {this.renderPasswordTextFieldWithLabel(
                            "Old",
                            passwordVisiblity.Old,
                            formikProps,
                            'old_password'
                          )}
                          {this.renderPasswordTextFieldWithLabel(
                            "New",
                            passwordVisiblity.New,
                            formikProps,
                            'new_password'
                          )}
                          {this.renderPasswordTextFieldWithLabel(
                            "Confirm",
                            passwordVisiblity.Confirm,
                            formikProps,
                            'confirm_password'
                          )}

                          {isErrorPasswordContainerVisible && renderPasswordErrorBox(formikProps.values.new_password,this.state.isErrorPasswordContainerVisible)}


                          <Box className="profile_gen_modal_submit_cont" marginTop={'1.5rem'}>
                            <FormSubmitButton fullWidth className="profile_test_submit" onClick={()=>formikProps.handleSubmit()}>
                              Continue
                            </FormSubmitButton>
                          </Box>
                        </Box>
                      </StyledModalContent>
                    )}
                  </Formik>
                </MuiModal>
              );
            };
            

            renderPasswordTextFieldWithLabel=(label:string,isPasswordVisible:boolean,formikProps:FormikProps<password_initial_values_type>,keyName:keyof password_initial_values_type)=>{
              const {focusedLabel} = this.state;
              const {values,handleChange,touched,errors} = formikProps;
              return <Box>
                  <CustomLabel>{label} Password</CustomLabel>
                          <CustomFormTextField
                          autoComplete="off"
                          placeholder={`Enter ${label} Password`}
                          isFocusedTypeInput={true}
                          variant={'outlined'}
                          size="medium"
                          fullWidth
                          value={values[keyName]}
                          name={keyName}
                          onChange={handleChange}
                          id={keyName}
                          type={!isPasswordVisible ? 'password' : 'text'}
                          onFocus={()=>  {this.handleErrorContainerVisible(true);this.handleActiveFocusedLabelProfie(keyName)}}
                          onBlur={()=>this.handleActiveFocusedLabelProfie(null)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    this.handleVisibilityPassword(label,!isPasswordVisible);
                                  }}
                                  className="password_button_type"
                                >
                                  {!isPasswordVisible ? (
                                    <VisibilityOff style={{ color: colors.GRAY_COLOR_FORM }} />
                                  ) : (
                                    <Visibility style={{ color: colors.GRAY_COLOR_FORM }} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          isErrorTouch={touched[keyName] && errors[keyName]}
                          />    
                           {touched[keyName] && errors[keyName] && <Box className="login_valid_error" id="profile_error_properties">
                            {errors[keyName]}
                      </Box>}

              </Box>
            }

            renderOtp=()=>{
              const otpInitialValues = {otpValue:''}
              return (<Box className="forp_otp_form">
                 <MuiModal
                  open={this.state.isOtpModalOpen}
                  onClose={() => {
                    this.handleisOtpModalOpen(false);
                  }}
                >
                    <StyledModalContent style={{width:"400px"}}>
                      <Formik
                        initialValues={otpInitialValues}
                        onSubmit={(values) => {
                          this.verifyOtp(values);
                        }}
                        innerRef={(formikProps) => {
                          this.otpFormik = formikProps as FormikProps<unknown>;
                        }}
                        validationSchema={otpValidationSchema}
                        validateOnChange
                        validateOnBlur
                      >
                        {({
                            values,
                            errors,
                            touched,
                            handleSubmit,
                            setFieldValue
                        }) => (
                            <>
                              <ModalHeading>Enter Code</ModalHeading>
                              <Box className='profile_gen_modal_margin'>Check your email to find your verification code and enter below</Box>
                              <Box>
                              <OtpInput
                                  value={values.otpValue}
                                  onChange={(otpFigure:string) => {setFieldValue('otpValue',otpFigure)}}
                                  numInputs={4}
                                  inputStyle={{
                                   ...otpinputStyle,
                                    border: `1px solid ${touched.otpValue && errors.otpValue ? "red" : "#6C7278"}`,
                                  }}
                                  focusStyle={{outline:'none', border : '2px solid #FFDA94',borderRadius:'8px'}}
                                  containerStyle={otpcontainerStyle}
                              />
                              {touched.otpValue && errors.otpValue && <Box className="register_valid_error" position="absolute">
                            {errors.otpValue}
                        </Box>}
                            </Box>

                            <Box className="profile_gen_modal_submit_cont">
                                <FormSubmitButton fullWidth className="profile_test_submit email_continue" onClick={()=>{handleSubmit()}}>Verify</FormSubmitButton>
                                </Box>       
                            </>
                        )}
                      </Formik>
                      <RenderTimerAndResend seconds={this.state.seconds} submit={this.resendClick}/>
                    </StyledModalContent>
                </MuiModal>
              </Box>)
            }

            renderPaymentMethods = ()=>{
              const {showPaymentModal,action} = this.state
              return(
                <>
                <Box className="booking_section" style={{rowGap:"1.5rem"}}>
                  {this.renderHeading()}
                  <Box className="profile_main_container" mb={0} pt={"1.5rem"}>
                    {this.renderCardsWithTitle("Sky Cards")}
                  </Box>
                  <Box className="profile_main_container" mb={0} pt={"1.5rem"}>
                    {this.renderCardsWithTitle("Debit/Credit Cards")}
                    <ButtonBox mt={"2.2rem"} onClick={()=>this.handleStatusVisible(true)}>Add New Credit/Debit Card</ButtonBox>
                  </Box>
                </Box>
                {showPaymentModal &&(
                  <AddPaymentMethodModal
                  showModal = {showPaymentModal}
                  actionType={action}
                  />
                )}
                {this.renderPaymentUpdateModal()}

                </>
              )
            }

            renderHeading = ()=>{
              return(
                <GoldishSimpleButton style={{ color: 'white', textTransform: "capitalize", justifyContent:'start',fontSize:'1.6rem'}}>Payment Methods</GoldishSimpleButton>
              )
            }

            renderCardsWithTitle = (title:string)=>{
              return(
                <>
                {this.renderSubHeading(title)}
                {title==="Sky Cards"?this.renderCards(title):this.renderDebitCards()}                  
                </>
              )
              
            }

            renderSubHeading = (heading:string)=>{
              return(
                <Box style={{marginBottom:"1.5rem", color: 'white', textTransform: "capitalize", justifyContent:'start',fontSize:"1.5rem", fontWeight:600}}>{heading}</Box>

              )
            }

            renderCards = (cardType:string)=>{
              return <Grid container spacing={2}>
                {skyCards.map((card)=>this.renderSkyCard(card))}
                
                
              </Grid>
            }

            renderSkyCard = (card:any)=>{
              const isDebit = card.type === "Debit"
              const cardIconContainerStyle = isDebit ? "card_img debitskyCard":"sky_card card_img"
              return(
                <Grid item sm={12} md={6} lg={4} xl={3}>
                  <StyledBox>
                    <Grid container className="card_parent">
                      <Grid item xs={8} className="card_content">
                        {isDebit ?this.renderDebitCardContent(card):this.renderSkyCardContent(card)}
                      </Grid>
                      <Grid item xs={4} className={cardIconContainerStyle}>{ isDebit?<DebitSkyCardIcon/>:<SkyCardIcon/>}</Grid>
                    </Grid>
                  </StyledBox>        
                </Grid>
              )
            }

            renderSkyCardContent = (card:any)=>{
              const {type,remaining,aircraft} = card
              return <Box className="card_container">
                <Box className="view_button_parent" gridColumnGap={1}>
                  <Box><SkyCardDebitCardIcon /></Box>
                  <Box className="card_heading" color={"#fff"}>{type} Sky Card</Box>
                </Box>

                <Box className="booking_section" style={{color:"#fff",rowGap: "0.1rem" }}>
                  <Box display={"flex"} justifyContent={"center"} gridColumnGap={"0.5rem"} mb={0.5}>
                  <Box display={"flex"} alignItems={"center"}><AirplaneSvg/></Box>
                  <Box className="card_name">{aircraft}</Box>
                  </Box>
                  
                  <Box fontSize={"1rem"} color={"#cbd5e1"}>Remaining</Box>
                  <Box fontSize={"1.4rem"}>{remaining}</Box>
                </Box>
              </Box>
            }

            renderDebitCardContent=(card:any)=>{
              const {type,remaining} = card
              return <Box className="card_container">
                  <Box className="view_button_parent" style={{columnGap:'1rem'}}>
                    <Box><DebitSkyCardSmallerIcon/></Box>
                    <Box className="card_heading" color={"#fff"} >{type} Sky Card</Box>
                  </Box>
          
                  <Box className="booking_section" style={{rowGap:"0.3rem",color:"#fff"}}>
                      <Box className="card_content_bottom" style={{justifyContent:'flex-start'}}>
                        <Box className="view_button_parent" style={{marginTop:'-4px',color:"#cdd5e1"}}>
                        <Box  className="view_debit_card" component={'span'}>Remaining</Box>
                        </Box>
                        
                      </Box>
                      <Box fontSize={"1.4rem"}>{remaining}</Box>
                  </Box>
              </Box>
            }

            renderDebitCards = ()=>{
              return<Grid container spacing={2}>
                {debitCards.map((card)=>this.renderDebitCard(card))}
                
              </Grid>
            }

            renderDebitCard = (card:any)=>{
              const {type,cardholder_name,card_number} = card
              return <Grid item sm={12} md={6} lg={4} xl={3}>
                <PaymentCard >
                    <DFlexBetween>
                    <div className="card-btns">
                    <div className="div-img"><img  src={this.handleCardProvider(type)} alt="" height={"28px"} width={"44px"} /></div>
                    <button className="card-btn">Primary</button>
                    </div>
                    <DFlexAlignCenter gridGap={"1rem"}>
                    <><img src={deleteIcon} alt="" className="pointer delCardModal" onClick={(event:React.MouseEvent<HTMLImageElement>)=>{
                    }}/>
                    <img src={edit_icon} alt="" className="pointer" onClick={(event:React.MouseEvent<HTMLImageElement>)=>{
                      }}/></>                                 
                    </DFlexAlignCenter>
                    </DFlexBetween>                    
                    <Box className="card-name">{cardholder_name}</Box>
                    <p className="card-info"> **** **** **** {card_number}</p>
                  </PaymentCard>
              </Grid>
            } 
            
            renderPaymentUpdateModal = ()=>{
                return <MuiModal
                open={this.state.isStatusModalVisible}
              >
                <StatusModal className="status_update_modal" payment={true}>
                <MuiResuableStatusModalBody
                  heading = "Payment Method Added Successfully"
                  subHeading = "You can now access payment methods at checkout"
                >
                  <FormSubmitButton onClick={()=>this.handleStatusVisible(false)} fullWidth className="okay_button">Back to Payments</FormSubmitButton>
                </MuiResuableStatusModalBody>
                              
                </StatusModal>
              </MuiModal>

            }
            
              
              
  

 // Customizable Area End
  render() {
    // Customizable Area Start
    const {userRole,page,} = this.state;
    const isAdmin = userRole !== "user"
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
      {isAdmin && this.renderAdminProfileParent()}
      {page === "/PaymentMethods" && this.renderPaymentMethods()}
      {(page === "/userProfileBasicBlock" && !isAdmin) && this.renderUserProfileParent()}

      
      </>
      // Customizable Area End
    );
  }
}
