import React, { useEffect, useRef, useState } from "react";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { useRunEngine } from "../../utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../utilities/src/hooks/useBlockHelpers";

import OrderManagementView from "./OrderManagementView";

// Customizable Area Start
import {updateOrderDetails, searchClientByName , SelectMenuOptionType,  Order, RequirementsType, FileData, ConvertFilesToBase64CallbackType, preventDefaultPropagationType, preventDefaultPropagationTypeExact, manageIncidentalInvoiceType, setEditInvoiceInitialValueType, IncidentalInvoiceFormType, updateFlightIteneraryType, uploadContractsType, FlightInteneraryType, OrderDetailsType, paymentSummaryType, CommissionType, LeadAndAddPassType, AnnoutSearchType, OrderSummaryType, FromAndToType, MainInvoiceType, IncidentalsInvoiceType, OrderDetailsNewType, HandlePopularSearchResponseType, BookingResponseType , ClientFormValue, AddNewPassType, addEditPassengerInitialValuesType, CardFormType, DeleteFunctionType, FlightIteneraryValueType, StatusModalHeadingType, VirtualTerminalDataTyoe, PaymentStatusOpenAndStatus, SavedCardStateType, FormikRefType, RefundDataType, RefundInitValueType, PaymentMethodStateType, IsPaymentProcessingType, ParseFloatFunction, CvvModalType, FileInputRefsType } from "./types";
import moment from "moment";
import {getStorageData} from "../../../framework/src/Utilities"
import { FormikProps, FormikValues } from "formik";
import { handleNavigation } from "../../../components/src/Navigator";
const baseURL = require("../../../framework/src/config.js").baseURL;


// Customizable Area End

export const configJSON = require("./config");

export interface ViewProps {
  testID: string;
  // Customizable Area Start
  orders: Order[];
  loading: boolean;
  hideKeyboard: () => void;
  openCreateModal: () => void;
  isVisibleCreateModal: boolean;
  catalogueId: string;
  setCatalogueId: (text: string) => void;
  catalogueVariantId: string;
  setCatalogueVariantId: (text: string) => void;
  quantity: string;
  setQuantity: (text: string) => void;
  onSubmitCreateOrder: () => void;
  navigateToOrderDetail: (orderId: string) => void;
  openAddItemModalHandler: (orderId: number) => void;
  resetCreateOrderModal: () => void;
  selectedOrderId: null | number;
  orderDetails:OrderDetailsType | unknown;
  paymentSummary:paymentSummaryType;
  commission:CommissionType;
  getBookingDetails:()=>void;
  bookingNumber:string;
  legsData:null | FlightInteneraryType[];
  passengerData:LeadAndAddPassType,
  isInvoiceVisible:boolean,
  setIsInvoiceVisible:React.Dispatch<React.SetStateAction<boolean>>,
  updtePaymentSummary:(bodyToSend: unknown) => Promise<void>,
  setIsPaymentSumaryModalOpen:React.Dispatch<React.SetStateAction<boolean>>,
  isPaymentSumaryModalOpen:boolean,
  optionsbyName:AnnoutSearchType[]
  optionsbyId:AnnoutSearchType[],
  searchClientByName:searchClientByName,
  updateOrderDetails:updateOrderDetails,
  isOrderDetailsModelOpen:boolean,
  setIsOrderDetailsModelOpen:React.Dispatch<React.SetStateAction<boolean>>,
  utilFunction:unknown,
  adminData:SelectMenuOptionType[],
  isCommissionModelOpen:boolean,
  setIsCommissionModelOpen:React.Dispatch<React.SetStateAction<boolean>>,
  redirectBack:()=>void,
  updateCommission:(admin_id: string) => Promise<void>,
  orderSummary:OrderSummaryType,
  requirement:RequirementsType,
  updateRequirements:(body: unknown) => Promise<void>,
  setIsRequirementModalOpen:React.Dispatch<React.SetStateAction<boolean>>,
  isRequirementModalOpen:boolean
  fromAiportsData:FromAndToType[],
  toAiportsData:FromAndToType[],
  getSearchedAirports:(query:string) => Promise<void>,
  isFromAirportTouched:React.MutableRefObject<boolean>,
  flightInternariesInitValues : FlightIteneraryValueType,
  setFlightInternariesInitValues:React.Dispatch<React.SetStateAction<FlightIteneraryValueType>>,
  updateFlightItenerary:updateFlightIteneraryType,
  isFlightIteneraryModalOpen:boolean,
  setIsFlightIteneraryModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  popularAirports:FromAndToType[] | [],
  mainInvoice:MainInvoiceType | null,
  incidentals:IncidentalsInvoiceType[],
  uploadContracts:uploadContractsType,
  isContractsModalOpen:null | number,
  setIsContractsModalOpen: React.Dispatch<React.SetStateAction<null | number>>,
  setIsVaccinationModalOpen:React.Dispatch<React.SetStateAction<boolean>>,
  isVaccinationModalOpen:boolean,
  isSavedPassengerModalOpen:boolean,
  setIsSavedPassengerModalOpen:React.Dispatch<React.SetStateAction<boolean>>
  isAddPassengerModalOpen:boolean,
  setIsAddPassengerModalOpen:React.Dispatch<React.SetStateAction<boolean>>,
  setIsNewClientModalOpen:React.Dispatch<React.SetStateAction<boolean>>,
  isNewClientModalOpen:boolean,
  manageIncidentalInvoice:manageIncidentalInvoiceType,
  downloadInvoice:(invoiceId:string) => Promise<void>,
  setEditInvoiceInitialValue:setEditInvoiceInitialValueType,
  editInvoiceInitialValue:null | IncidentalInvoiceFormType,
  convertFilesToBase64:ConvertFilesToBase64CallbackType,
  inputFileRef: React.MutableRefObject<null>,
  inputFileRefAccordion:React.MutableRefObject<null>,
  inputFileRefContracts:React.MutableRefObject<null>,
  requirementAccordionRef:React.MutableRefObject<null>,
  requirementRef:React.MutableRefObject<null>,
  defaultPrevent:preventDefaultPropagationType,
  propagationStop:preventDefaultPropagationType,
  moment: typeof moment,
  paymentStatusOptions:Array<SelectMenuOptionType>,
  vendorStatusOption:Array<SelectMenuOptionType>,
  addSavePassengerToBooking : (saved_passenger : number[])=>Promise<void>,
  currentBookingPage:string,
  focusedField:null|string,
  setFocusedField:React.Dispatch<React.SetStateAction<null|string>>,
  createNewProfile:(values:ClientFormValue)=>void,
  clientForm:React.MutableRefObject<FormikProps<FormikValues> | null>
  passportRef:React.MutableRefObject<null>
  addOrEditSavePassenger : (body:AddNewPassType,initial_value:unknown) => Promise<void>
  setaddEditPassengerInitialValues:React.Dispatch<React.SetStateAction<undefined>>
  addEditPassengerInitialValues : addEditPassengerInitialValuesType
  deletePassenger:(delete_id:string)=>Promise<void>,
  makeLeadPassenger : (passenger_id:string)=> Promise<void>,
  cardDetails : CardFormType,
  setCardDetails : React.Dispatch<React.SetStateAction<CardFormType>>
  setActiveFieldName:React.Dispatch<React.SetStateAction<string>>,
  activeFieldName:string,
  setIsSureModalOpen:React.Dispatch<React.SetStateAction<boolean>>,
  isSureModalOpen:boolean
  setDeleteFunction:React.Dispatch<React.SetStateAction<DeleteFunctionType>>
  deleteFunction : DeleteFunctionType,
  manageClickOnYes:(lastClicked:string,deleteId:string)=>void
  downloadAllPassportAndCustomer:(type:'passenger' | 'passport')=>string
  handleFlightItenariesInitialValues:(eachLeg:FlightInteneraryType)=>FlightIteneraryValueType
  setIsEditFlightMode: React.Dispatch<React.SetStateAction<null | number>>
  isEditFlightMode:null | number
  flight_init_values:FlightIteneraryValueType
  setHoveredLeg: React.Dispatch<React.SetStateAction<string>>,
  hoveredLeg:string,
  setFlightIteneraryActive:React.Dispatch<React.SetStateAction<string>>,
  flightIteneraryActive:string,
  isStatusModalOpen:boolean,
  setIsStatusModalOpen:React.Dispatch<React.SetStateAction<boolean>>,
  statusModalHeading:StatusModalHeadingType,
  setStatusModalHeading : React.Dispatch<React.SetStateAction<StatusModalHeadingType>>
  setVirtualTerminalData : React.Dispatch<React.SetStateAction<VirtualTerminalDataTyoe>>
  virtualTerminalData : VirtualTerminalDataTyoe,
  chargeNewCard:(body: unknown,isNewCardUsed:boolean) => Promise<void>
  paymentStatusAndOpen : PaymentStatusOpenAndStatus,
  setPaymentStatusAndOpen : React.Dispatch<React.SetStateAction<PaymentStatusOpenAndStatus>>,
  savedCard:SavedCardStateType,
  isApiProcessing:boolean
  existingCardFormikRef:FormikRefType
  newCardFormRef:FormikRefType
  requirementFiledUploadRef:FormikRefType
  isNewCardUsed:boolean
  refundData:RefundDataType
  setRefundInitValue : React.Dispatch<React.SetStateAction<RefundInitValueType>>
  refundInitValue : RefundInitValueType
  processRefund : (body: RefundInitValueType & {order_payment_id:string}) => Promise<void>,
  setRefundProcessingLoaderIndex:React.Dispatch<React.SetStateAction<null | number>>
  refundProcessingLoaderIndex : null | number
  paymentMethod:PaymentMethodStateType,
  setIsPaymentProcessing:React.Dispatch<IsPaymentProcessingType>,
  isPaymentProcessing : IsPaymentProcessingType,
  commaToFigure: ParseFloatFunction,
  isProfitCommissionSaving : boolean,
  isPaymentSummarySaving:boolean,
  isRequirementSaving:boolean,
  isOrderDetailsSaving:boolean,
  isInvoiceProcessing:boolean,
  navigationToClientDetails:(client_id:string)=>void
  isCvvModalOpen : CvvModalType
  setIsCvvModalOpen : React.Dispatch<React.SetStateAction<CvvModalType>>
  fileInputRefs: FileInputRefsType
  // Customizable Area End
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

const subscribedMessages = [
  // Customizable Area Start
  MessageEnum.RestAPIResponceMessage,
  MessageEnum.SessionResponseMessage,
  // Customizable Area End
];

const OrderManagement: React.FC<Props> = (props) => {
  // Customizable Area Start
  const flight_init_values = {
    call_sign : null,
    departure: null,
    arrival:null,
    no_of_passengers:null,
    no_of_stops:null,
    duration:null,
    aircraft_type:null,
    tail_number:null,
    fbo_telephone:null,
    fbo_address:null,
    fbo:null,
    name:null,
    vendor_charged:null,
    vendor_booking_ref:null,
    from_airport:null,
    to_airport:null,
    isTimePickerOneOpen:false,
    isTimePicketTwoOpen:false,
    isCalenderOneOpen:false,
    isCalenderTwoOpen:false,
    order_id:getBookingIdFromUrl(),
    uploadedDocs:[],
    contract_ids:[],
    contracts : {
      files : []
    }
  }
  const getOrdersCallId = useRef("");
  const createOrdersCallId = useRef("");
  const updatePaymentSummaryCallId = useRef("");
  const chargeNewCardCallId = useRef("");
  const chargeRefundCallId = useRef("");
  const getNameOptionsCallId = useRef("");
  const updateOrderDetailsCallId = useRef("");
  const makeLeadPassengerCallId = useRef("");
  const getAdminsApiCallId = useRef("");
  const updateCommissionCallId = useRef("");
  const updateRequirementCallId = useRef("");

  const getFromAndToAiporCallId = useRef("");
  const getPopularSearchCallId = useRef("");
  const updateFlightIteneraryCallId = useRef("");
  const uploadContractsFlightIteneraryCallID = useRef('');
  const createNewInvoiceCallId = useRef('');
  const inputFileRef = React.useRef(null);
  const inputFileRefAccordion = React.useRef(null);
  const inputFileRefContracts = React.useRef(null);
  const requirementRef = React.useRef(null);
  const requirementAccordionRef = React.useRef(null);
  const createNewProfileAPICallId = React.useRef("")
  const clientForm = React.useRef<FormikProps<FormikValues> | null>(null)
  const passportRef = React.useRef(null);
  const addNewSavePassengerBookingCallId = useRef("");
  const deletePassengerApiId = useRef("");
  const deleteLegApiId = useRef("");





  const searchDataHandlerKey = useRef("");




  const [isVisibleCreateModal, setIsVisibleCreateModal] =
    useState<boolean>(false);
  const [catalogueId, setCatalogueId] = useState<string>("");
  const [catalogueVariantId, setCatalogueVariantId] = useState<string>("");
  const [quantity, setQuantity] = useState<string>("");
  const [selectedOrderId, setSelectedOrderId] = useState<null | number>(null);

  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const token = useRef<string>("");
  const [isInvoiceVisible, setIsInvoiceVisible] = useState<boolean>(false);
  
  const addSavedPassengertoBookingCallId = useRef("");
  const [editInvoiceInitialValue,setEditInvoiceInitialValue] = useState<null | IncidentalInvoiceFormType>(null);
  const [popularAirports,setPopularAirports] = useState<[] | FromAndToType[]>([]);
  const [fromAiportsData,setFromAirportsData] = useState<[] | FromAndToType[]>([]);
  const [toAiportsData,setToAirportsData] = useState<[] | FromAndToType[]>([]);
  const isFromAirportTouched = useRef(true);

  const [isEditFlightMode,setIsEditFlightMode] = useState<null | number>(null);
  const [flightInternariesInitValues,setFlightInternariesInitValues] = useState<FlightIteneraryValueType>(flight_init_values);
  const [mainInvoice,setMainInvoices] = useState<MainInvoiceType | null>(null);
  const [incidentals,setIncidentals] = useState<[] | IncidentalsInvoiceType[]>([]);
  const [addEditPassengerInitialValues,setaddEditPassengerInitialValues] = useState(undefined);
  const [hoveredLeg,setHoveredLeg] = useState("");
  const [isStatusModalOpen,setIsStatusModalOpen] = useState(false);
  const [statusModalHeading,setStatusModalHeading] = useState({
    heading : '',
    sub_heading : ''
  });



  const getBookingDetailsCallId = useRef("");
  const [bookingNumber,setBookingNumber] = useState('');
  const [orderDetails,setOrderDetails] = useState<OrderDetailsNewType>({
    client_name: {
      full_name:null
    },
    account_id: {
      account_number:null
    },
    order_origin: null,
    order_date: null,
    payment_status: null,
    vendor_status: null,
    is_guest : false,
    client_email:null,
    navigation_id:null
  });

  const [paymentSummary,setPayemntSummary] = useState<paymentSummaryType>({
    booking_amount: null,
    taxes: null,
    segment_fees: null,
    order_total: null,
    profit: null,
    landing_fee:null,
    payment_processing_fee:null
  });

  const [commission,setComission] = useState<CommissionType>({
    admin_id:null,
    sales_rep: null,
    commission_percentage: null,
    commission_value: null,
    profit_exc_landing_fee:null,
    profit_exc_landing_fee_percent:null,
    profit_inc_landing_fee:null,
    profit_inc_landing_fee_percent:null
  });
  const [optionsbyName,setOptionsByName] = useState<[] | AnnoutSearchType[]>([]);
  const [optionsbyId,setOptionsById] = useState<[] | AnnoutSearchType[]>([]);
  const [adminData,setAdminData] = useState([]);
  const [orderSummary,setOrderSummary] = useState<OrderSummaryType>({
    flight_price: null,
    landing_fee: null,
    tax: null,
    segment_fee: null,
    payment_processing: null,
    total_price: null,
    payment_recevied: null,
    payment_remaining: null
})
const [requirement,setRequirements] = useState<RequirementsType>({
      requirements:{
      "is_special_luggage": false,
      "special_luggage_details": null,
      "is_car_transport_required": false,
      "car_transportation_required": null,
      "is_catering_required": false,
      "catering_required": null,
      "is_any_special_requests": false,
      "special_requests": "false",
      "are_they_bringing_pets": false,
      "will_they_be_bringing_pets": null
      },
      vaccination_pdf:{files:[]}}
)

  const [isPaymentSumaryModalOpen , setIsPaymentSumaryModalOpen] = React.useState(false);
  const [isOrderDetailsModelOpen , setIsOrderDetailsModelOpen] = React.useState(false);
  const [isCommissionModelOpen , setIsCommissionModelOpen] = React.useState(false);
  const [isRequirementModalOpen , setIsRequirementModalOpen] = React.useState(false);

  const [isFlightIteneraryModalOpen , setIsFlightIteneraryModalOpen] = React.useState<boolean>(false);
  const [isContractsModalOpen , setIsContractsModalOpen] = React.useState<null | number>(null);
  const [isVaccinationModalOpen,setIsVaccinationModalOpen] = React.useState<boolean>(false);
  const [isSavedPassengerModalOpen,setIsSavedPassengerModalOpen] = React.useState(false);
  const [isAddPassengerModalOpen,setIsAddPassengerModalOpen] = React.useState(false);
  const [isNewClientModalOpen,setIsNewClientModalOpen] = React.useState(false);  
  const [flightIteneraryActive,setFlightIteneraryActive] = useState('');
  const cardInitDetails = {
    amount : null,
    card_number : null,
    name_on_card : null,
    expiry : null,
    security_code : null,
    street_address : null,
    city : null,
    state : null,
    zip_code : null,
    country : null,
    invoice_id : null,
    is_paid : true,
    saved_payment_card_id:null
  }
  const [cardDetails,setCardDetails] = useState<CardFormType>(cardInitDetails);
  const [activeFieldName,setActiveFieldName] = useState("");
  const refund_init_value = {
    amount : null
  }
  const [refundInitValue,setRefundInitValue] = useState<RefundInitValueType>(refund_init_value);
  const refundAmount = useRef("");


  const [legsData,setLegsData] = useState<null | FlightInteneraryType[]>(null);
  const [passengerData,setPassengerData] = useState<LeadAndAddPassType>({
    additional_passenger:{data:[]},
    lead_passenger:{data:[]},
    save_passenger : {data : []}
  });
  const paymentStatusOptions:Array<SelectMenuOptionType> = [
    {label:'Outstanding',value :'1',stringValue:'outstanding'},
    {label:'Paid',value :'2',stringValue:'paid'}
  ]

  const vendorStatusOption = [
    {label:'Vendor Pending',value:'vendor_pending',stringValue:'vendor_pending'},
    {label:'Vendor Confirmed',value:'vendor_confirmed',stringValue:'vendor_confirmed'},
    {label:'Trip Cancelled',value:'trip_cancelled',stringValue:'trip_cancelled'}
  ];
  const [currentBookingPage,setCurrentBookingPage] = useState<string>("")
  const [focusedField,setFocusedField] = React.useState<string|null>(null)
  const [isSureModalOpen,setIsSureModalOpen] = useState(false);
  const [deleteFunction,setDeleteFunction] = useState({
    lastClicked:'',
    deleteId:''
  });

  const [virtualTerminalData,setVirtualTerminalData] = useState<VirtualTerminalDataTyoe>(null);
  const [paymentMethod,setPaymentMethod] = useState<PaymentMethodStateType>([]);
  const [paymentStatusAndOpen,setPaymentStatusAndOpen] = useState<PaymentStatusOpenAndStatus>({
    isOpen:false,
    isSuccess:false
  });
  const [savedCard,setSavedCards] = useState<SavedCardStateType>(null);
  const [isApiProcessing,setIsApiProcessing] = useState(false);
  const existingCardFormikRef = useRef(null);
  const newCardFormRef = useRef(null);
  const requirementFiledUploadRef = useRef(null);
  const [isNewCardUsed,setIsNewCardUsed] = useState(false);
  const [refundData,setRefundData] = useState<RefundDataType>([]);
  const [refundProcessingLoaderIndex,setRefundProcessingLoaderIndex]  = useState<null | number>(null);
  const [isPaymentProcessing,setIsPaymentProcessing] = useState<IsPaymentProcessingType>(null);
  const [isProfitCommissionSaving,setIsProfitCommissionSaving] = useState<boolean>(false);
  const [isPaymentSummarySaving,setIsPaymentSummarySaving] = useState<boolean>(false);
  const [isRequirementSaving,setIsRequirementSaving] = useState<boolean>(false);
  const [isOrderDetailsSaving,setIsOrderDetailsSaving] = useState<boolean>(false);
  const [isInvoiceProcessing,setIsInvoiceProcessing] = useState<boolean>(false);
  const [isCvvModalOpen,setIsCvvModalOpen] = useState<CvvModalType>({newCard:false,existingCard:false,cardEndingNum:null});
  const fileInputRefs = useRef(Array(10).fill(null).map(()=>React.createRef()));
  
  // Customizable Area End

  useEffect(() => {
    setReceiveCallback(receive);

    subscribedMessages.forEach((message) => subscribe(message));

    // Customizable Area Start
    getBookingDetails();
    getPopularAirports();
    getAdminRepresentatives();
    // Customizable Area End

    return () => {
      subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
    };
  }, []);

  const receive = (from: string, message: Message) => {
    // Customizable Area Start
    debugLog("API Message Received", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const resToken = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      debugLog("TOKEN", resToken);
      handleRestoken(resToken)
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } =
        extractNetworkResponse(message);
      setLoading(false);

      if (responseJson) {
        switch (apiRequestCallId) {
          case getOrdersCallId.current:
            setOrders(responseJson.data);
            getOrdersCallId.current = "";
            break;
          
          case createOrdersCallId.current:
            if (responseJson.data) {
              resetCreateOrderModal();
              getListOfOrders();
            } else if (responseJson?.msg) {
              alert(JSON.stringify(responseJson.msg));
            }
            createOrdersCallId.current = "";
            break;

          case getBookingDetailsCallId.current:
              handleSuccessBookingResponse(responseJson);
              getBookingDetailsCallId.current = "";
              break;  

          case updatePaymentSummaryCallId.current:
            checkSuccessAndProceed(responseJson);
                updatePaymentSummaryCallId.current = "";
                break; 
                
          case getNameOptionsCallId.current:
                handleSuccessClientSearchResponse(responseJson);
                getNameOptionsCallId.current = "";
                break;     
                
          case updateOrderDetailsCallId.current:
                checkSuccessAndDecline(responseJson)
                updateOrderDetailsCallId.current = "";
                break;  

          case uploadContractsFlightIteneraryCallID.current:
              checkSuccessAndProceed(responseJson);
              uploadContractsFlightIteneraryCallID.current = "";
                break;                  
                
          case updateCommissionCallId.current:
            checkSuccessAndProceed(responseJson);
                updateCommissionCallId.current = "";
                break;  

          case getAdminsApiCallId.current:
                handleAdminResponse(responseJson);
                getAdminsApiCallId.current = "";
                break;   
                
          case updateRequirementCallId.current:
            checkSuccessAndProceed(responseJson);
                updateRequirementCallId.current = "";
                break;  
                
          case getPopularSearchCallId.current:
                handlePopularSearchResponse(responseJson,true);
                getPopularSearchCallId.current = "";
                break;   
                
          case getFromAndToAiporCallId.current:
                handlePopularSearchResponse(responseJson,false);
                getFromAndToAiporCallId.current = "";
                break;  
                
          case updateFlightIteneraryCallId.current:
                checkSuccessAndProceed(responseJson);
                updateFlightIteneraryCallId.current = "";
                break;   
                
          case addSavedPassengertoBookingCallId.current:
                checkSuccessAndProceed(responseJson);
                addSavedPassengertoBookingCallId.current = "";
                break;  
                
          case addNewSavePassengerBookingCallId.current:
                addNewPassengerResponse(responseJson);
                addNewSavePassengerBookingCallId.current = "";
                break;                 
                
          case createNewInvoiceCallId.current:
            checkSuccessAndProceed(responseJson);
            createNewInvoiceCallId.current = "";
            break;      
            
          case deletePassengerApiId.current:
            checkSuccessAndProceed(responseJson);
            deletePassengerApiId.current = "";
            break;
            
          case deleteLegApiId.current:
            checkSuccessAndProceed(responseJson);
            deleteLegApiId.current = "";
            break; 

          case makeLeadPassengerCallId.current:
            checkSuccessAndProceed(responseJson);
            makeLeadPassengerCallId.current = "";
            break;  
          
          case chargeNewCardCallId.current:
            handlePaymentSuccess(responseJson);
            chargeNewCardCallId.current = "";
            break;  

            case chargeRefundCallId.current:
            handleRefundResponse(responseJson);
            chargeRefundCallId.current = "";
            break;  
            
            case createNewProfileAPICallId.current:
              handleCreateClientAPIResponse(responseJson)               
              createNewProfileAPICallId.current = "";
              break; 

          default:
        }
      }
      handleApiProcessing(false);
      setIsPaymentProcessing(null);
      resetLoaders();


    }
    // Customizable Area End
  };

  // Customizable Area Start
  const {
    sendBlockMessage,
    sendNetworkRequest,
    setReceiveCallback,
    subscribe,
    debugLog,
    unsubscribeFromMessage,
  } = useRunEngine();

  const { extractNetworkResponse, hideKeyboard, isPlatformWeb } =
    useBlockHelpers();


  useEffect(() => {
    if (props.navigation.getParam("orderDeleted", null) && !isPlatformWeb()) {
      getListOfOrders();
    }
  }, [props.navigation.getParam("orderDeleted", null)]);

  const getListOfOrders = () => {
    setLoading(true);
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: token.current,
    };

    sendNetworkRequest(
      getOrdersCallId,
      configJSON.listOfOrdersMethod,
      configJSON.listOfOrdersEndPoints,
      headers
    );
  };

  const handleRestoken=(resToken:string)=>{
    if (resToken) {
      token.current = resToken;
      getListOfOrders();
    } else {
      alert(configJSON.loginAlertMsg);
    }
  }

  const onSubmitCreateOrder = () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: token.current,
    };

    const order_item = {
      catalogue_id: Number(catalogueId),
      catalogue_variant_id: Number(catalogueVariantId),
      quantity: Number(quantity),
    };

    const httpBody =
      selectedOrderId === null ? order_item : { order_items: [order_item] };

    const endPoint =
      selectedOrderId === null
        ? configJSON.createOrderEndPoint
        : configJSON.createOrderItemInOrderEndPoint.replace(
            ":id",
            selectedOrderId
          );

    sendNetworkRequest(
      createOrdersCallId,
      configJSON.createOrderMethod,
      endPoint,
      headers,
      httpBody
    );
  };

  const resetLoaders=()=>{
    setIsProfitCommissionSaving(false);
    setIsPaymentSummarySaving(false);
    setIsRequirementSaving(false);
    setIsOrderDetailsSaving(false);
    setIsInvoiceProcessing(false);
    (requirementFiledUploadRef as unknown as {current : {setFieldValue:(value1:string,value2:[])=>void}}).current?.setFieldValue('uploadedFiles', []);
  }

  const openCreateModal = () => {
    setIsVisibleCreateModal(true);
  };

  const defaultPrevent=(event:preventDefaultPropagationTypeExact)=>{
    event.preventDefault();
  }

  const propagationStop=(event:preventDefaultPropagationTypeExact)=>{
    event.stopPropagation();
  }

  const resetCreateOrderModal = () => {
    setCatalogueId("");
    setCatalogueVariantId("");
    setQuantity("");
    setSelectedOrderId(null);
    setIsVisibleCreateModal(false);
  };

  const openAddItemModalHandler = (orderId: number) => {
    setSelectedOrderId(orderId);
    openCreateModal();
  };

  const handleApiProcessing=(isProcessing:boolean)=>{
    setIsApiProcessing(isProcessing);
  }

  const navigateToOrderDetail = (orderId: string) => {
    props.navigation.navigate("OrderDetails", {
      orderId: orderId,
    });
  };

  const getBookingDetails = async() => {
    const headers = {
       'token': await getStorageData('token')
    };
    sendNetworkRequest(
      getBookingDetailsCallId,
      configJSON.getOrderByIdMethod,
      configJSON.getBookingDetailsApiEndPoint + `/${getBookingIdFromUrl()}`,
      headers
    );
  };

  const addSavePassengerToBooking = async(saved_passenger:number[]) => {
    const bodyToSend = {
      order_id : getBookingIdFromUrl(),
      passenger_ids : saved_passenger
    }
    const headers = {
       'token': await getStorageData('token'),
       "Content-Type": configJSON.apiContentType,
    };
    sendNetworkRequest(
      addSavedPassengertoBookingCallId,
      configJSON.addAddressToAddressMethod,
      configJSON.addSavendPassengertoBookingEndpoint,
      headers,
      bodyToSend
    );
  };

  function getBookingIdFromUrl() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('booking_id');
  }

  const getPopularAirports = async() => {
    sendNetworkRequest(
      getPopularSearchCallId,
      configJSON.getOrderByIdMethod,
      configJSON.popularAiports,
    );
  };

  const downloadInvoice = async(invoiceId:string)=>{
    let downloadLink = `${baseURL}/bx_block_admin/bookings/invoice_generate?data[invoice_id]=${invoiceId}`
    downloadInvoiceUsingLink({pdf_url:downloadLink})
  }

  const invoiceMethodHandler=(methodType:string)=>{
    if(methodType === 'create'){
      return configJSON.createOrderMethod
    }else if(methodType === 'edit'){
      return configJSON.addAddressToAddressMethod
    }else{
      return configJSON.deleteOrderItemMethod
    }
  }

  const manageIncidentalInvoice = async(bodyToSend:unknown,methodType='create',invoice_id='') => {
    setIsInvoiceProcessing(true)
    const apiType = invoiceMethodHandler(methodType);

    const invoiceData = {
      data : {
        order_id:getBookingIdFromUrl(),
        ...bodyToSend as {}
      }
    }
    const headers = {

          'token': await getStorageData('token'),
          "Content-Type": configJSON.apiContentType,
    };
    sendNetworkRequest(
      createNewInvoiceCallId,
      apiType,
      configJSON.createNewInvoiceApiEndPoint + '/' + invoice_id,
      headers,
      invoiceData
    );
  };


  const getSearchedAirports = async(query:string) => {
    sendNetworkRequest(
      getFromAndToAiporCallId,
      configJSON.getOrderByIdMethod,
      configJSON.getSearchAirportsEndpoint + `?query=${query}`,
    );
  };

  const handlePopularSearchResponse=(responseJson:HandlePopularSearchResponseType,isInitialLoad:boolean)=>{
    if(isInitialLoad){
      const {data} = responseJson;
      let formattedPopularAirportData = handleAirportData(data)
      setPopularAirports(formattedPopularAirportData as unknown as FromAndToType[]);
      setFromAirportsData(formattedPopularAirportData as unknown as FromAndToType[]);
      setToAirportsData(formattedPopularAirportData as unknown as FromAndToType[]);
    }else{
      const {airports} = responseJson;
      let formattedData = handleAirportData(airports);
      isFromAirportTouched.current && setFromAirportsData(formattedData as unknown as FromAndToType[]);
      !isFromAirportTouched.current && setToAirportsData(formattedData as unknown as FromAndToType[]);
    }
  }

  const handleAirportData=(airportData:FromAndToType[])=>{
    let airportManipulatedData = airportData.map((item)=>{
      return handlEachAirportData(item);
    })
    return airportManipulatedData;
  }

  const handlEachAirportData=(eachAirportData:FromAndToType)=>{
    const item = eachAirportData;
    return {name:item.name,city:item.city,countryCode:item.country.code,code:item.icao,id :item.id,iata : item.iata}
  }

  const updtePaymentSummary = async(bodyToSend:unknown) => {
    setIsPaymentSummarySaving(true);
    const headers = {
       'token': await getStorageData('token'),
       "Content-Type": configJSON.apiContentType,
    };
    sendNetworkRequest(
      updatePaymentSummaryCallId,
      configJSON.updateMethod,
      configJSON.updatePaymentSummaryEndPoint(getBookingIdFromUrl()),
      headers,
      bodyToSend as {}
    );
  };

  const chargeNewCard = async(bodyToSend:unknown,isNewCardUsed:boolean) => {
    const headers = {
       'token': await getStorageData('token'),
       "Content-Type": configJSON.apiContentType,
    };
    sendNetworkRequest(
      chargeNewCardCallId,
      configJSON.createOrderMethod,
      configJSON.chargeNewCardEndPoint,
      headers,
      bodyToSend as {}
    );
    setIsNewCardUsed(isNewCardUsed);
    handleApiProcessing(true);
  };

  const processRefund = async(bodyToSend:RefundInitValueType & {order_payment_id:string}) => {
    refundAmount.current = bodyToSend.amount as unknown as string
    const headers = {
       'token': await getStorageData('token'),
       "Content-Type": configJSON.apiContentType,
    };
    sendNetworkRequest(
      chargeRefundCallId,
      configJSON.createOrderMethod,
      configJSON.refundAPiEndPoint,
      headers,
      bodyToSend as {}
    );
    handleApiProcessing(true);
  };

  const updateRequirements = async(bodyToSend:unknown) => {
    setIsRequirementSaving(true);
    const headers = {
       'token': await getStorageData('token'),
       "Content-Type": configJSON.apiContentType,
    };
    const bodyData = {
      id: getBookingIdFromUrl(),
      ...bodyToSend as {}
    }
    sendNetworkRequest(
      updateRequirementCallId,
      configJSON.addAddressToAddressMethod,
      configJSON.updateRequirementEndPoint,
      headers,
      bodyData
    );
  };

  const uploadContracts = async(bodyToSend:unknown,type = 'flight') => {
    const endPoint = type === 'flight' ? configJSON.uploadContractsApiEndpoint : configJSON.uploadRequirementVaccincationApiEndpoint
    let dataToSend = {...bodyToSend as {},order_id:getBookingIdFromUrl()}
    const headers = {
       'token': await getStorageData('token'),
       "Content-Type": configJSON.apiContentType,
    };
    sendNetworkRequest(
      uploadContractsFlightIteneraryCallID,
      configJSON.createAddressMethod,
      endPoint,
      headers,
      dataToSend
    );
  };

  const updateFlightItenerary = async(formData:unknown,_isEditForm:null | number) => {
    const arrival_value = {arrival_date : moment((formData as {data:{arrival_date:Date}}).data.arrival_date).local().format("YYYY-MM-DDTHH:mm:ssZ")};
    const departure_value = {departure_date : moment((formData as {data:{departure_date:Date}}).data.departure_date).local().format("YYYY-MM-DDTHH:mm:ssZ")};
    const endPoint = configJSON.updateFlightIteneraryEndpoint;
    const method = configJSON.addAddressToAddressMethod;
    const headers = {
       'token': await getStorageData('token'),
       "Content-Type": configJSON.apiContentType
    };
    sendNetworkRequest(
      updateFlightIteneraryCallId,
      method,
      endPoint,
      headers,
      {...formData as {},order_id : getBookingIdFromUrl(),data:{...(formData as {data:{}}).data,...arrival_value,...departure_value}} as {}
    );
  };

  const dateFormat=(date:string | null)=>{
    return date ? moment(date).format("DD-MM-YYYY") : ''
  }

  

  const addOrEditSavePassenger=async(values:AddNewPassType,addEditPassengerInitialValues:unknown)=>{
    const isAddPassenger = addEditPassengerInitialValues === undefined;
    const fileArray = values.passportFile.filter((file:any)=>file.url === undefined)
    const passportFile = (base64file: FileData[]): Record<string, unknown> => {
      const { passportFile } = values as unknown as { passportFile?: { url: string } };
      return passportFile?.url ? {} : { passport_file: base64file };
    };

    convertFilesToBase64(fileArray,(base64Files:FileData[])=>{
      const getIdOrderId =  isAddPassenger ? {order_id:getBookingIdFromUrl()} : {id : (values as unknown as {id : string}).id,order_id:getBookingIdFromUrl()}
      let dataToSend = {
        ...getIdOrderId,
        data : {
          first_name: values.firstName,
          last_name:values.lastName,
          passport_number:values.passportNumber,
          date_of_issuance:dateFormat(values.dateOfInsurance),
          date_of_expiration:dateFormat(values.dateOfExpiration),
          country_of_issue:values.countryOfIssue.name,
          country_code:values.countryCode,
          phone_number:values.phoneNumber,
          email:values.emailId,
          full_address:values.address,
          gender:values.gender,
          weight:values.weight,
          date_of_birth:dateFormat(values.dateOfBirth),
          ...passportFile(base64Files)
        },
        passport_images:true,
        passport_image_ids:values.passport_image_ids
      }
      addNewPassengerData(dataToSend,addEditPassengerInitialValues);
    })
    handleApiProcessing(true);
  }

  const addNewPassengerData=async(values:unknown,addEditPassengerInitialValues:unknown)=>{
    const endPoint = addEditPassengerInitialValues === undefined ? configJSON.addNewPassengerEndPoint : configJSON.updatePassengerEndPoint
    const method = addEditPassengerInitialValues === undefined ? configJSON.createOrderMethod : configJSON.addAddressToAddressMethod
    const headers = {
    'token': await getStorageData('token'),
    "Content-Type": configJSON.apiContentType
   };
   sendNetworkRequest(
     addNewSavePassengerBookingCallId,
     method,
     endPoint,
     headers,
     values as {}
   );
      
  }

  const deletePassenger=async(delete_id:string)=>{
    const headers = {
    'token': await getStorageData('token'),
    "Content-Type": configJSON.apiContentType
   };
   sendNetworkRequest(
     deletePassengerApiId,
     configJSON.deleteOrderItemMethod,
     configJSON.deletePassengerEndPoint + `?passenger_id=${delete_id}&order_id=${getBookingIdFromUrl()}`,
     headers,
   );
  }

  const deleteLeg = async(leg_id:string)=>{
    const headers = {
    'token': await getStorageData('token'),
    "Content-Type": configJSON.apiContentType
   };
   sendNetworkRequest(
     deleteLegApiId,
     configJSON.deleteOrderItemMethod,
     configJSON.deleteLegEndPoint + `?order_item_id=${leg_id}&order_id=${getBookingIdFromUrl()}`,
     headers,
   );
  }



  const handleSuccessBookingResponse=(responseJson:BookingResponseType)=>{
    const {order:{data : {attributes : {refund,incidential_invoice, main_invoice,booking_type, order_details, payment_summary, commission, booking_no,legs,passenger_details,order_summary,requirements:{data :{attributes} } ,virtual_terminal,existing_card:{cards},payment_methods}}}} = responseJson;
    const {main_invoice:{amount_to_pay,is_paid,id}} = virtual_terminal
    setOrderDetails({...order_details,account_id:{account_number:order_details.account_number},client_name:{full_name:order_details.client_name},navigation_id:order_details.account_id as unknown as string});
    setPayemntSummary(payment_summary);
    setComission(commission);
    setBookingNumber(booking_no);
    setLegsData(legs.data);
    setPassengerData(passenger_details);
    setOrderSummary(order_summary);
    setRequirements(attributes);
    setMainInvoices(main_invoice.data);
    setIncidentals(incidential_invoice);
    setCurrentBookingPage(booking_type);
    setVirtualTerminalData(virtual_terminal)
    setCardDetails({...cardDetails,invoice_id:id,amount:amount_to_pay as unknown as number,is_paid:is_paid,saved_payment_card_id:null});
    setSavedCards(cards.data);
    setRefundData(refund.data);
    setPaymentMethod(payment_methods.data)
  }

  const commaToFigure = (stringWithCommas:string | null)=> typeof stringWithCommas ==='string' ? parseFloat(stringWithCommas.replace(/,/g, '')) : '';

  const handleFlightItenariesInitialValues = (eachLeg:FlightInteneraryType) => {
  
    const { attributes:{ departure_time,
      arrival_time,
      departure_date,
      arrival_date,
      no_of_passengers,
      no_of_stops,
      duration,
      aircraft_type,
      tail_number,
      fbo_telephone,
      fbo_address,
      fbo,
      vendor_name: name,
      vendor_charged,
      vendor_booking_ref,
      from_airport,
      to_airport,
      contracts,
      call_sign
    }, id
    } = eachLeg;

    return {
      call_sign,
      departure:moment(departure_date + departure_time, 'YYYY-MM-DD hh:mm A').toDate(),
      arrival:moment(arrival_date + arrival_time, 'YYYY-MM-DD hh:mm A').toDate(),
      no_of_passengers,
      no_of_stops,
      duration,
      aircraft_type,
      tail_number,
      fbo_telephone,
      fbo_address,
      fbo,
      name,
      vendor_charged,
      vendor_booking_ref,
      from_airport:handlEachAirportData(from_airport as FromAndToType),
      to_airport:handlEachAirportData(to_airport as FromAndToType),
      order_id:getBookingIdFromUrl(),
      id:id,
      isTimePickerOneOpen:false,
      isTimePicketTwoOpen:false,
      isCalenderOneOpen:false,
      isCalenderTwoOpen:false,
      contracts,
      uploadedDocs:[],
      contract_ids:[]
    } as unknown as FlightIteneraryValueType;
};

  const handleSuccessClientSearchResponse=(responseJson:{data : AnnoutSearchType[]})=>{
    const {data} = responseJson;
    if(searchDataHandlerKey.current === 'full_name'){
      setOptionsByName(data);
    }else{
      setOptionsById(data);
    }
  }

  const handleAdminResponse=(responseJson:AnnoutSearchType[])=>{
    if(responseJson.length){
      setAdminData( responseJson.map((admin) => {
        if (admin.attributes && admin.attributes.profile && admin.attributes.profile.data) {
            return admin.attributes.profile.data.attributes;
        }
        return null;
    }).filter((profile) => profile !== null) as unknown as []);
    }
  }

  const checkSuccessAndDecline = (responseJson:{order:{meta:{message : string}}} & BookingResponseType) => {
    if((responseJson as unknown as {error: string}).error){
      triggerAlert((responseJson as unknown as {error: string}).error, "fail")
    }else
      checkSuccessAndProceed(responseJson)
  }

  const checkSuccessAndProceed=(responseJson:{order:{meta:{message : string}}} & BookingResponseType)=>{
    if(responseJson.order.meta.message){
      handleSuccessBookingResponse(responseJson);
      setIsPaymentSumaryModalOpen(false);
      setIsOrderDetailsModelOpen(false);
      setIsCommissionModelOpen(false);
      setIsRequirementModalOpen(false);
      setIsFlightIteneraryModalOpen(false);
      setIsContractsModalOpen(null);
      setIsVaccinationModalOpen(false);
      setIsInvoiceVisible(false);
      setIsSavedPassengerModalOpen(false);
      setIsSureModalOpen(false);
      triggerAlert();
    }
  }

  const handlePaymentSuccess=(responseJson:{order:{meta:{message : string}}} & BookingResponseType)=>{
    if((responseJson as unknown as {error:unknown})?.error){
      setPaymentStatusAndOpen({isOpen:true,isSuccess:false})
    }
    else if(responseJson.order.meta.message === 'Payment Successfully Done!'){
      checkSuccessAndProceed(responseJson);
      setPaymentStatusAndOpen({isOpen:true,isSuccess:true})
      setCardDetails(cardInitDetails);
    }
  }

  const handleRefundResponse=(responseJson : {error_message : string} | BookingResponseType)=>{
    if((responseJson as {error_message : string}).error_message){
      setIsStatusModalOpen(true);
      handleModalHeadAndSubHead('Refund Failed',(responseJson as {error_message : string}).error_message);
    }
    else if((responseJson as BookingResponseType).order.meta.message === 'Refund Processed'){
      setRefundInitValue({...refund_init_value,amount:''});
      handleModalHeadAndSubHead(`$${refundAmount.current} Refunded`,'Your refund amount has been successfully paid.');
      checkSuccessAndProceed(responseJson as BookingResponseType);
      setIsStatusModalOpen(true);
    }
    setRefundProcessingLoaderIndex(null);
  }

  const handleModalHeadAndSubHead=(heading:string,subHeading:string)=>{
    setStatusModalHeading({heading,sub_heading:subHeading})
  }

  const addNewPassengerResponse=(responseJson:{order:{meta:{message : string}}} & BookingResponseType)=>{
    if(responseJson.order.meta.message){
      handleSuccessBookingResponse(responseJson);
      setIsAddPassengerModalOpen(false);
      triggerAlert();
    }
  }


  
  const convertFilesToBase64 = (files: File[], callback: (base64Files: FileData[]) => void) => {
    const base64Files: FileData[] = [];
    let processedFiles = 0;
    const totalFiles = files.length;
  
    if (totalFiles === 0) {
      callback(base64Files);
      return;
    }
  
    files.forEach((file: File) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        base64Files.push({ filename: file.name, data: reader.result });
        processedFiles++;
        if (processedFiles === totalFiles) {
          callback(base64Files);
        }
      };
    });
  };

  const downloadInvoiceUsingLink=(pdf_object:{pdf_url:string})=>{
    const {pdf_url} = pdf_object;
    const link = document.createElement('a');
    link.href = pdf_url;
    link.download = 'document.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const triggerAlert = (message = "Changes saved successfully", alertType = "success")=>{
      const messageTriger = new Message(getName(MessageEnum.AlertMessage));
      messageTriger.addData(getName(MessageEnum.AlertBodyMessage), message);
      messageTriger.addData(getName(MessageEnum.AlertTypeMessage), alertType);
      sendBlockMessage(messageTriger);
  }

  const searchClientByName=async(value:string,keyName:'full_name' | 'account_id')=>{
    searchDataHandlerKey.current = keyName
    const headers = {
      'token': await getStorageData('token'),
      "Content-Type": configJSON.apiContentType,
   };
   sendNetworkRequest(
     getNameOptionsCallId,
     configJSON.getAddressListMethod,
     configJSON.getOptionsByNameAndIdEndPoint + `?${keyName}=${value}`,
     headers
   );
  }

  const getAdminRepresentatives=async()=>{
    const headers = {
      'token': await getStorageData('token'),
      "Content-Type": configJSON.apiContentType,
   };
   sendNetworkRequest(
     getAdminsApiCallId,
     configJSON.getAddressListMethod,
     configJSON.getAdminsApiEndPoint,
     headers
   );
  }

  const updateOrderDetails=async(bodyToSend:OrderDetailsType)=>{
    setIsOrderDetailsSaving(true);
    const headers = {
      'token': await getStorageData('token'),
      "Content-Type": configJSON.apiContentType,
   };
   sendNetworkRequest(
     updateOrderDetailsCallId,
     configJSON.addAddressToAddressMethod,
     configJSON.getBookingDetailsApiEndPoint + `/${getBookingIdFromUrl()}`,
     headers,
     bodyToSend
   );
  }

  const makeLeadPassenger=async(passenger_id:string)=>{
    const bodySend = {
      order_id:getBookingIdFromUrl(),
      passenger_id:passenger_id
    }
    const headers = {
      'token': await getStorageData('token'),
      "Content-Type": configJSON.apiContentType,
   };
   sendNetworkRequest(
     makeLeadPassengerCallId,
     configJSON.updateMethod,
     configJSON.makeLeadPassengerEndpoint,
     headers,
     bodySend
   );
  }

  const createNewProfile= async(values:ClientFormValue)=>{
    const airport = `${values.preferredAirport.name} , ${values.preferredAirport.city} - ${values.preferredAirport.code}`
    const headers = {
      'token': await getStorageData('token'),
      "Content-Type": configJSON.apiContentType
    };

    const httpBody = {
      data:{
        email:values.emailId,
        profile_attributes:[
        {
        country_code:values.countryCode, 
        phone_number: values.phoneNumber,
        preferred_departure_airport: airport,
        full_name:values.fullName
        }
        ]
    }
      
    };
    sendNetworkRequest(
      createNewProfileAPICallId,
      configJSON.createProfileMethod,
      configJSON.createProfileAPIEndpoint,
      headers,
      httpBody
    );
        }

  const handleCreateClientAPIResponse = (responseJson:{errors:{account : string}[]} | {errors : string[]})=>{
    if(responseJson.errors){
      if((responseJson as {errors:{account : string}[]}).errors[0].account){
        clientForm.current?.setFieldError("emailId",(responseJson as {errors:{account : string}[]}).errors[0].account)
      }
      else if(responseJson.errors[0] === "Profile full phone number This Phone Number is already taken"){
        clientForm.current?.setFieldError("phoneNumber","This number is already taken")
      }
    }
    else{
      setIsNewClientModalOpen(false)
      setIsOrderDetailsModelOpen(true)         
      const messageTriger = new Message(getName(MessageEnum.AlertMessage));
      messageTriger.addData(getName(MessageEnum.AlertBodyMessage), "Client Registered Successfully");
      sendBlockMessage(messageTriger);
    }
  }      

  const updateCommission=async(admin_id:string)=>{
    const adminIdParam = !admin_id ?  "" : `admin_id=${admin_id}`;
    setIsProfitCommissionSaving(true);
    const headers = {
      'token': await getStorageData('token'),
      "Content-Type": configJSON.apiContentType,
   };
   sendNetworkRequest(
     updateCommissionCallId,
     configJSON.updateMethod,
     configJSON.updateCommissionEndPoint + `?id=${getBookingIdFromUrl()}&${adminIdParam}`,
     headers,
   );
  }

  const manageClickOnYes=(lastClicked:string,deleteId:string)=>{
    if(lastClicked === 'passenger'){
      deletePassenger(deleteId);
    }else if(lastClicked === 'incidental_invoice'){
      manageIncidentalInvoice(null , true as unknown as string,deleteId);
    }else if(lastClicked === 'leg_del'){
      deleteLeg(deleteId);
    }
  }

  const redirectBack=()=>{
    const {navigation:{goBack}} = props;
    goBack();
  }

  const downloadAllPassportAndCustomer = (downloadType:'passenger' | 'passport')=>{
    const apiEndPoint = downloadType === 'passenger' ? configJSON.downloadPassengerEndPoint : configJSON.downloadPassportEndpoint;
    const urlToGo = baseURL + '/' + configJSON.downloadPassportAndCustomerStart + `/${getBookingIdFromUrl()}/` + apiEndPoint
    return urlToGo;
  }

  const navigationToClientDetails=(client_id:string)=>{
    handleNavigation(`/AdminConsole2?client_id=${client_id}`,props,sendBlockMessage);
  }

  const utilFunction={
    triggerAlert,
    checkSuccessAndProceed,
    handleSuccessClientSearchResponse,
    handleSuccessBookingResponse,
    updateRequirements,
    getPopularAirports,
    handlePopularSearchResponse,
    getSearchedAirports,
    updateFlightItenerary,
    uploadContracts,
    manageIncidentalInvoice,
    downloadInvoice,
    downloadInvoiceUsingLink,
    createNewProfile,
    handleCreateClientAPIResponse,
    addNewPassengerResponse,
    handlePaymentSuccess,
    handleRefundResponse,
    resetLoaders,
    navigationToClientDetails,
    checkSuccessAndDecline
  }

  // Customizable Area End

  const viewProps: ViewProps = {
    testID: "OrderManagementView",
    // Customizable Area Start
    orders,
    loading,
    hideKeyboard,
    openCreateModal,
    isVisibleCreateModal,
    catalogueId,
    setCatalogueId,
    catalogueVariantId,
    setCatalogueVariantId,
    quantity,
    setQuantity,
    onSubmitCreateOrder,
    navigateToOrderDetail,
    openAddItemModalHandler,
    resetCreateOrderModal,
    selectedOrderId,
    orderDetails,
    paymentSummary,
    commission,
    getBookingDetails,
    bookingNumber,
    legsData,
    passengerData,
    setIsInvoiceVisible,
    isInvoiceVisible,
    updtePaymentSummary,
    setIsPaymentSumaryModalOpen,
    isPaymentSumaryModalOpen,
    optionsbyName,
    optionsbyId,
    searchClientByName,
    updateOrderDetails,
    isOrderDetailsModelOpen,
    setIsOrderDetailsModelOpen,
    utilFunction,
    adminData,
    isCommissionModelOpen,
    setIsCommissionModelOpen,
    redirectBack,
    updateCommission,
    orderSummary,
    requirement,
    updateRequirements,
    setIsRequirementModalOpen,
    isRequirementModalOpen,
    fromAiportsData,
    toAiportsData,
    getSearchedAirports,
    isFromAirportTouched,
    flightInternariesInitValues,
    setFlightInternariesInitValues,
    updateFlightItenerary,
    setIsFlightIteneraryModalOpen,
    isFlightIteneraryModalOpen,
    popularAirports,
    uploadContracts,
    setIsContractsModalOpen,
    isContractsModalOpen,
    mainInvoice,
    incidentals,
    isVaccinationModalOpen,
    setIsVaccinationModalOpen,
    isSavedPassengerModalOpen,
    setIsSavedPassengerModalOpen,
    isAddPassengerModalOpen,
    setIsAddPassengerModalOpen,
    isNewClientModalOpen,
    setIsNewClientModalOpen,
    manageIncidentalInvoice,
    downloadInvoice,
    setEditInvoiceInitialValue,
    editInvoiceInitialValue,
    convertFilesToBase64,
    inputFileRef,
    inputFileRefAccordion,
    inputFileRefContracts,
    defaultPrevent,
    propagationStop,
    moment,
    paymentStatusOptions,
    vendorStatusOption,
    addSavePassengerToBooking,
    currentBookingPage,
    focusedField,
    setFocusedField,
    createNewProfile,
    clientForm,
    addOrEditSavePassenger,
    passportRef,
    setaddEditPassengerInitialValues,
    deletePassenger,
    addEditPassengerInitialValues,
    makeLeadPassenger,
    cardDetails,
    setCardDetails,
    setActiveFieldName,
    activeFieldName,
    setIsSureModalOpen,
    isSureModalOpen,
    setDeleteFunction,
    deleteFunction,
    manageClickOnYes,
    downloadAllPassportAndCustomer,
    handleFlightItenariesInitialValues,
    setIsEditFlightMode,
    isEditFlightMode,
    flight_init_values,
    setHoveredLeg,
    hoveredLeg,
    requirementRef,
    requirementAccordionRef,
    flightIteneraryActive,
    setFlightIteneraryActive,
    isStatusModalOpen,
    setIsStatusModalOpen,
    setStatusModalHeading,
    statusModalHeading,
    setVirtualTerminalData,
    virtualTerminalData,
    chargeNewCard,
    paymentStatusAndOpen,
    setPaymentStatusAndOpen,
    savedCard,
    isApiProcessing,
    existingCardFormikRef,
    newCardFormRef,
    isNewCardUsed,
    refundData,
    setRefundInitValue,
    refundInitValue,
    processRefund,
    setRefundProcessingLoaderIndex,
    refundProcessingLoaderIndex,
    paymentMethod,
    setIsPaymentProcessing,
    isPaymentProcessing,
    commaToFigure,
    isProfitCommissionSaving,
    isPaymentSummarySaving,
    isRequirementSaving,
    isOrderDetailsSaving,
    isInvoiceProcessing,
    navigationToClientDetails,
    requirementFiledUploadRef,
    isCvvModalOpen,
    setIsCvvModalOpen,
    fileInputRefs
    // Customizable Area End
  };

  return <OrderManagementView {...viewProps} />;
};

export default OrderManagement;
