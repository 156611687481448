import React from "react";

// Customizable Area Start
import {
  Box,
  Typography
} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import { GoldishSimpleButton } from "../../../components/src/MuiCustomComponents";
// Customizable Area End

import TermsConditionsDetailController, {
  Props,
  configJSON,
} from "./TermsConditionsDetailController";

export default class TermsConditionsDetail extends TermsConditionsDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {htmlContent} = this.state;
    return <Box position={'relative'} className="detail_web">
        <Box style={webStyles.backButton}><GoldishSimpleButton className="back_login" onClick={()=>this.handleNavigationToLogin()} style={{ color: 'white', textTransform: "capitalize", justifyContent:'start',fontSize:'1.4rem'}}><ArrowBack style={{marginRight:"10px"}}/> Back to Login</GoldishSimpleButton></Box>
        <GoldishSimpleButton style={{ color: 'white', textTransform: "capitalize", justifyContent:'start',fontSize:'1.6rem'}}>{this.handleCondition('Terms & Conditions','Privacy Policy')} </GoldishSimpleButton>
        <Typography style={{...webStyles.text,wordBreak:'break-all'}} dangerouslySetInnerHTML={{ __html: htmlContent}} />
    </Box>
    // Customizable Area End
  }
}

// Customizable Area Start

type CSSPropertiesMap = {
  [key: string]: React.CSSProperties;
};
const webStyles:CSSPropertiesMap = {
  text: {
    fontWeight: 400,
    fontFamily: "Manrope",
    color: "#AEAEB2",
    fontSize: "16px",
  },
  backButton:{
    position:"absolute",
    top : '-7rem',
    left: "-2rem"
  }
}
// Customizable Area End
