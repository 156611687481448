interface RoleObj{
    is_admin: boolean;
    is_super_admin: boolean;
}

const theme = {
    PRIMARY_COLOR:"#FFFFFF",
    GRAY_TEXT_COLOR:"#808080",
    GRAY_COLOR_FORM:"#94A3B8",
    GOLD_COLOR: '#FFD27E',
    SAVE_DARK_COLOUR:'#FFB833'
}

const roles = {
  admin : "admin",
  user: "user",
  super_admin : "super_admin"
}

const handleRole=(roleObj:RoleObj)=>{
    const {is_admin,is_super_admin} = roleObj;
    if(is_admin) return 'admin';
    else if(is_super_admin)  return 'super_admin';
    return 'user';
}

function base64UrlDecode(base64Url:string) {
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    return JSON.parse(jsonPayload) || {id:null};
  }
  
  function getTokenInfo(token:string) {
    const [, payload] = token.split('.');
    return base64UrlDecode(payload);
  }

  const loginRoute = '/login';
  const registerRoute = '/register';

export {theme, handleRole, getTokenInfo, loginRoute, registerRoute, roles}