import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { CSSPropertiesMap, CustomFormTextField, CustomLabel, CustomTypography, ErrorBoxes, Footer, FooterCancelSaveChanges, FormSubmitButton, GeneralParentBox, quill_formats, quill_modules } from "../../../components/src/MuiCustomComponents";
import { ArrowBack } from "@material-ui/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Formik, FormikProps } from "formik";
import { cardDescriptionSchema } from "../../../components/src/Validations";

// Customizable Area End

import Cfdebitskycard3Controller, {
  Props,
  configJSON,
} from "./Cfdebitskycard3Controller";

export default class Cfdebitskycard3 extends Cfdebitskycard3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

    renderHeading=()=>{
      const {isViewState,desc_type} = this.state;
      return <CustomTypography
      style={webStyle.head}
      className="header_back"
      onClick={()=>isViewState ?  this.handleNavigationDescription() : this.handleViewEditState(true)}
      >
      <ArrowBack className="cf_avinod_arrow_search"/> {!isViewState ? "Edit" : ''} {this.handleEndPoint(desc_type as string,'25 Hour','Debit', 'Custom')} Sky Card Description
    </CustomTypography>
    }

    renderViewEditDescriptionParent=()=>{
      return <GeneralParentBox style={webStyle.mainParentStyle} className="card_parent">
        {this.renderViewEditForm()}
      </GeneralParentBox>
    }

    renderViewEditForm=()=>{
      const {focusLabel, isViewState, init_value} = this.state;
      const textFieldStyle = isViewState ? webStyle.disableBgg : {}
      const isOutlined = !isViewState ? 'outlined' : 'standard';
      const quill_border = !isViewState ? '#636F73' : 'none'

      return <Formik
      initialValues={init_value}
      onSubmit={(values) => this.updateDescription(values)}
      key={this.handleCondition(isViewState,'desc_view','desc_edit')}
      validationSchema={cardDescriptionSchema}
      innerRef={(formikProps) => {
        this.descFormik = formikProps as FormikProps<unknown>
      }}
      enableReinitialize
      validateOnChange
      validateOnBlur
    >
      {({handleChange,setFieldValue,values,touched,errors}) => {
      return  <Box className="booking_section" style={webStyle.viewEditarent}>

        <Box >
           <CustomLabel isError={this.handleDesError(touched.title as unknown as boolean,errors.title as unknown as boolean)} isFocused={focusLabel === 'cardName'}>Edit Sky Card Name</CustomLabel>
           <CustomFormTextField
                   autoComplete="off"
                   placeholder="Enter Card Name"
                   className = 'cardName'
                   onFocus={()=>this.handleLableFocus('cardName')}
                   onBlur={()=>this.handleLableFocus(null)}
                   size="medium"
                   name = 'title'
                   onChange={handleChange}
                   fullWidth
                   isFocusedTypeInput = {true}
                   disabled={isViewState}
                   initialBorderColor='#636F73'
                   value={values.title}
                   variant={isOutlined}
                   style={textFieldStyle}
                   isErrorTouch={this.handleDesError(touched.title as unknown as boolean,errors.title as unknown as boolean)}
                   InputProps={{
                     inputProps:{style :{marginLeft: this.handleCondition(isViewState,'0.3rem','-0.5rem')}}
                   }}
                 />
                   <ErrorBoxes touched={touched.title} errors={errors.title as unknown as string} message={errors.title as unknown as string}/>
        </Box>

        <Box marginTop={!isViewState && '-3rem'}>
           <CustomLabel style={!isViewState ? webStyle.quill_label : {}} isFocused={focusLabel === 'description' && !isViewState}>About Sky Card</CustomLabel>
           <ReactQuill
               theme="snow"
               modules={quill_modules}
               formats={quill_formats}
               placeholder = 'Enter Description'
               style={webStyle.quill_style}
               id="mdEditor"
               value={values.description as string} 
               onFocus={()=>!isViewState && this.handleLableFocus('description')}
               onBlur={()=>focusLabel !== 'cardName' && this.handleLableFocus(null)}
               readOnly={isViewState}
               onChange={(value)=>setFieldValue('description',value)}                     
             />
              <style>
                 {`
                   .ql-container.ql-snow .ql-editor {
                     max-height:40vh; 
                     border:1px solid ${focusLabel === 'description' && !isViewState ? '#FFD67B' : quill_border};
                     border-radius:7px;
                     background-color : ${isViewState ? '#2D3034' : 'inherit'}
                   }
                   .ql-toolbar.ql-snow + .ql-container.ql-snow{
                     height:auto; 
                     border-radius:5px;
                     'border:none;'
                     border-radius:'5px'
                   }
                   .ql-container.ql-snow{
                     border:none !important;
                   }
                   .ql-toolbar.ql-snow{
                     position:relative;
                     top:5px;
                     ${isViewState  && 'display : none'}
                   }
                   .ql-editor{
                    height : auto;
                   }
                 `}
             </style>

        </Box> 

        </Box>
      }}</Formik>
    }


    renderHandleFooter=()=>{
      const {isViewState} = this.state;
      return <Footer>
        {isViewState ? this.renderViewFooter() : this.renderEditFooter()}
      </Footer>
    }

    renderViewFooter=()=>{
      return <Box className="profile_submission" width={'20%'}>
          <FormSubmitButton onClick={()=>this.handleViewEditState(false)} fullWidth className="edit_debit_sky_card">Edit</FormSubmitButton>
      </Box>
    }

    renderEditFooter=()=>{
      return <FooterCancelSaveChanges cancel={()=> this.handleViewEditState(true)} submit={()=>this.descFormik?.handleSubmit()}/>
    }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <Box className="desc_parent">
            {this.renderHeading()}
            {this.renderViewEditDescriptionParent()}
            {this.renderHandleFooter()}
        </Box>  
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle : CSSPropertiesMap = {
  head:{
    cursor:'pointer',
    color:'white'
  },
  mainParentStyle:{
    padding:'1rem',
    marginTop:'1rem',
    marginBottom:'7rem',
  },
  viewEditarent:{
    backgroundColor : '#1A1C1E',
    borderRadius:'10px',
    padding : '1rem',
  },
  quill_style:{
   height: 'auto', 
   wordBreak : 'break-all',
  },
  quill_label:{
    top:"4.2rem",
    position:'relative'
  },
  disableBgg:{
    color:'white',
    backgroundColor : '#2D3034',
    borderRadius:'5px'
  }
};
// Customizable Area End
