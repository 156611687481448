import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  TableContainer,
  TextFieldProps,
  PopperProps,
  Typography
} from "@material-ui/core";
import { CallMade, Search, SwapVert, ChatBubbleOutline } from '@material-ui/icons';
import "./Dashboard.css";
import { FlightImg, AppleIcon, AndroidIcon } from "./assets";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import {theme as colors} from "../../../components/src/utils"
import {AddLegtTypeButton, SearchStyledAutocomplete, SearchStyledPopper, renderDashboardSearchOptions, GreySkeleton, DetailsColorFul, getStatus} from "../../../components/src/MuiCustomComponents";
import {balance_img,play_store,app_store,chat} from "../../../components/src/assets";
import {AutoCompleteMenuType,UpcomingBookingRoot,ButtonTogglerType, ButtonTogglerKey, OutstandingPayment, BookingData, AppMatricesCounts, UpcomingBookingAttribute, OutstandingPaymentData} from './types';


const CustomDashTextField = styled(TextField)({
  border: "2px solid #2D3034",
  borderRadius: "8px",
  fontSize: "1rem",
  background:"#000000",
  '&.Mui-focused fieldset': {
    border: 'none',
    outline: "none"
  },
  '& .MuiInput-underline:after': {
    borderBottom: "none"  
},
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none', 
    borderBottom:'none'
  },
  '& .MuiInputBase-input': {
    color: 'white',  
    fontSize: "1rem",
    height:'2.5rem',
  },
  '& .MuiInputBase-root':{
    padding:'0rem 1rem'
  },
  '& ::placeholder': { 
    color: '#636E72',
    fontWeight:"600", 
  },
});

const CustomDashboardButton = styled(Button)({
  backgroundImage: `linear-gradient(#FFD078, #FFE3AF)`,
  color: "black",
  backgroundColor: "#FFD078",
  '&:hover': {
    backgroundColor: '#FFD078',
  },
  textTransform: "none",
  height: "3.5rem",
  fontSize: "1rem",
  fontWeight: "bold",
  fontFamily: "sans-serif",
});

const CustomStyledButton = styled(Button)(({isToggled=false}:{isToggled?:boolean})=>({
  borderRadius: '1.2rem',
  border: isToggled ? '1px solid #FFD27E' : '1px solid #6C7278',
  color: isToggled ? 'FFD27E' :'white',
  fontSize:'0.65rem',
  textTransform:"none",
  backgroundColor:'#2D3034'
}));

const total_bookings_buttons = [
  {name:'MTD',togglerValue:'mtd'},
  {name:'Prev. 60',togglerValue:'prev_60'},
  {name:'Prev. 90',togglerValue:'prev_90'},
  {name:'YTD',togglerValue:'ytd'},
];

const total_clients_button = [
  {name:'Overall',togglerValue:'overall'},
  {name:'Last Week',togglerValue:'last_week'},
  {name:'Last Month',togglerValue:'last_month'},
  {name:'Last Year',togglerValue:'last_year'}
];

const app_metrics_button = [
  {name : 'MTD',togglerValue:"mtod"},
  {name : 'Prev 90',togglerValue:"previous_90"},
  {name : 'All Time',togglerValue:"all_time"},
]

const upcoming_booking_count_button = [
  {name:'All',togglerValue:'all'},
  {name:'This Week',togglerValue:'this_week'},
  {name:'Next 30',togglerValue:'next_30'},
  {name:'Next 90',togglerValue:'next_90'}
]

const client_card_images = [balance_img,chat,play_store,app_store];
const fontSize = {
  fontSize: {
    xs: '3rem',  
    sm: '1.5rem', 
    md: '1.2rem',  
    lg: '1.5rem',
    xl : "1rem"
  },
}

// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start


  renderColumns = (bookingType:string) => {
    const {sortType,outstandingSort} = this.state
    const sorting = bookingType === "upcoming" ? sortType :outstandingSort
    return <TableHead >
      <TableRow className={"dash_table_head"}>
        {(this.columnProvider(bookingType)).map((headingData:{title:string,value:string} ) => {
          return <TableCell>
          <Box className="order_detail_table_head_cell">
              <Box>{headingData.title}</Box>
              <Box className="sortBookingsArrow" 
                style={{border:sorting===headingData.value ? "1px solid moccasin":"none"}}
                onClick={()=>{
                bookingType==="outstanding"?this.handleOutStandingAndPrevSort(headingData.value as keyof OutstandingPaymentData):this.handleUpcomingSort(headingData.value as keyof UpcomingBookingAttribute)
              }}
              id="order_detail_view_menu_sort_button"
                >
                <SwapVert style={{color:colors.GOLD_COLOR}}/>
              </Box>
          </Box>
        </TableCell>
        })}
      </TableRow>
    </TableHead>
  }


  renderExistingAndCreateNewBookingParent=()=>{
    const {currentRole} = this.state;
    return <Grid container justifyContent={'space-between'} spacing={2} style={{rowGap:'1rem'}}>
      {currentRole !== 'user' &&  <>
        <Grid item xl={6} lg={12} md={12} sm={12}>
            {this.renderSearchExistngBooking()}        
        </Grid>
        <Grid item xl={6} lg={12} md={12} sm={12}>
            {this.renderCreateNewBooking()}
        </Grid>
      </>}
      {this.renderAnalyticCardParent()}
    </Grid>
  }

  renderCreateNewBooking=()=>{
    return <Box className= 'dash_top_header_box'>
    <Grid container justifyContent={'space-between'}>
        <Grid xs={6} item>
          <Box className = 'dash_flex_column'>
              <Box className = 'dash_top_head_gold'>{webConfigJSON.newBooking}</Box>
              <AddLegtTypeButton onClick={()=>this.handleNavigationToBooking('/AdvancedSearch')} style={{width:'80%'}}><Search style={{color:colors.GOLD_COLOR,marginRight:'0.5rem'}}/> Search for flights</AddLegtTypeButton>
          </Box>
        </Grid>
        <Grid xs={5} item>
            <FlightImg/>
        </Grid>
      </Grid>
  </Box>
  }

  renderAnalyticCardParent=()=>{
    const {isDashboardDataLoading} = this.state;
    return <>
     {isDashboardDataLoading ?  this.renderAnalyticsSkeletonParent() : this.renderCardHandler()}
    </>
  }


  renderCardHandler=()=>{
    const {currentRole} = this.state;
    if(currentRole !== 'user') return this.renderAdminCards();
    return this.renderClientCardParent();
  }

  renderAdminCards=()=>{
    const {dashboardData:{upcoming_booking_count,total_bookings,total_client,app_metrics},totalBookingToggler,totalClientToggler,totalUpcomingBookingToggler,totalDownloadsToggler} = this.state;
    return <>
    {this.renderAnalyticCard({title:'Upcoming Bookings',buttons:upcoming_booking_count_button,figure:upcoming_booking_count[totalUpcomingBookingToggler],togglerType:'totalUpcomingBookingToggler',endPoint:'/OrderDetailsView'})}
    {this.renderAnalyticCard({title:'Total Bookings',buttons:total_bookings_buttons,figure:total_bookings[totalBookingToggler],togglerType:'totalBookingToggler',endPoint:'/OrderDetailsView'})}
    {this.renderAnalyticCard({title:'Total Clients',buttons:total_clients_button,figure:total_client[totalClientToggler],togglerType:'totalClientToggler',endPoint:'/Clients'})}
    {this.renderMatrixCard({title:'App Metrics',buttons:app_metrics_button,figure:app_metrics[totalDownloadsToggler],togglerType:"totalDownloadsToggler"})}
   </>
  }

  renderClientCardParent=()=>{
    return <>
      {this.renderClientDashboardCard(0)}
      {this.renderClientDashboardCard(1)}
      {this.renderClientDashboardCard(2)}
      {this.renderClientDashboardCard(3)}
    </>
  }

  renderClientDashboardCard=(cardIndex:number)=>{
    return <Grid item md={6} lg={6} xl={3} sm={12} component='div' className="client_img_card">
      <Box position={'relative'}>
          {cardIndex === 0 && this.renderClientDebitSkyCardInfo(cardIndex)}
          {cardIndex === 1 && this.renderClientDebitSkyCardInfo(cardIndex)}
          <img src={client_card_images[cardIndex]} style={{borderRadius:cardIndex == 1 ? '14px' : ''}} width={'100%'} height={'100%'} alt="balance" />
      </Box>
      </Grid>
  }

  renderClientDebitSkyCardInfo=(cardIndex:number)=>{
    const isFirst = cardIndex === 0;
    const {clientDashboardData:{sky_card,concierge}} = this.state;
    const lil_head = isFirst ? 'Debit sky card' : 'concierge';
    const title = isFirst ? 'Debit Sky Card balance' : 'Talk to our Concierge';
    const content = isFirst ? 'Remaining' : 'Active chats';

    return <Box className="dash_client_debit_parent">
      <Box className="dash_client_debit_flex">
          <Box className="dash_client_card_lil_flex">
              <Box className="uppercase" color={isFirst ? '#856A39': '#8C949C'} fontSize={'0.65rem'} fontWeight={'bold'}>{lil_head}</Box>
              <Box sx={fontSize}>{title}</Box>
          </Box>
          <Box className="dash_client_card_lil_flex">
            <Box fontSize={'0.7rem'} color={'#CBD5E1'}>{content}</Box>
            <Box sx={fontSize}>{isFirst ? sky_card : concierge}</Box>
          </Box>
          <Box fontWeight={'bold'} color={'#FFD27E'}>
            <Box component={'span'} style={{textDecoration:'underline'}}>View All</Box>
            <Box component={'span'}>{">"}</Box>
          </Box>
      </Box>
    </Box>
  }

  renderAnalyticsSkeletonParent=()=>{
    const analyticsArray = ['analy1','analy2','analy3','analy4'];
    return <>
       {analyticsArray.map((_analytics)=>this.renderAnalyticsSkeleton())}
    </>
  }

  renderAnalyticsSkeleton=()=>{
    const skelArray = ['skel1','skel2','skel3','skel4'];
    return <Grid item md={6} lg={6} xl={3} sm={12} component={'div'} className="anayltics_loader">
        <Box className="dash_card_analytics">
           <Box className="order_detail_view_heading_parent">
              <GreySkeleton variant="rect" width={'10rem'} height={'1.8rem'} style={{borderRadius:"5px"}}/>
              <GreySkeleton variant="rect" width={'1.8rem'} height={'1.5rem'} style={{borderRadius:"5px"}}/>
           </Box>
           <Typography component={'div'} variant="h3" style={{width : '12rem'}}><GreySkeleton/></Typography>
           <Box className="dash_button-toggle-parent">
            {skelArray.map((_skeleton)=> <GreySkeleton variant="rect" width={'4rem'} height={'2.5rem'} style={{borderRadius:'1rem'}}/>)}
           </Box>
        </Box>
    </Grid>
  }

  renderMatrixCard=({title,buttons = [],figure,togglerType}:{title:string,buttons?:ButtonTogglerType[] | [],figure:AppMatricesCounts,togglerType:ButtonTogglerKey})=>{        
    return <Grid item md={6} lg={6} xl={3} sm={12} component='div'>
    <Box className = 'dash_card_analytics'>
        {this.renderAnalyticCardHeadAndRedirect({title:title})}
        <Box className = 'dash_flex_row' >
            <Box>
            {this.renderFigures({total:"",count:figure.total})}
            {buttons.length > 0 && this.renderTogglerParent(buttons,togglerType)}
            </Box>
            
            <Box className = 'dash_flex_column'>
                {this.renderDownloads({type:'apple',count:figure.ios})}
                {this.renderDownloads({type:'android',count:figure.android})}
            </Box>

        </Box>
    </Box>
</Grid>
  }

  renderDownloads=({type,count}:{type : 'android' | 'apple',count:number})=>{
    const Icon = type === 'android' ? <AndroidIcon/> : <AppleIcon/>
    return  <Box className = 'dash_button-toggle-parent'>
    {Icon}
    <Box fontSize={'1.4rem'}>{count}</Box> 
    </Box>
  }

  renderAnalyticCard=({title,buttons = [],figure={count:0,total:''},togglerType = 'totalBookingToggler',endPoint}:{title:string,buttons?:ButtonTogglerType[] | [],figure?:{count:number,total:string},togglerType?:ButtonTogglerKey,endPoint:string})=>{
    return <Grid item md={6} lg={6} xl={3} sm={12} component='div'>
          <Box className = 'dash_card_analytics'>
              {this.renderAnalyticCardHeadAndRedirect({title:title,endPoint})}
              {this.renderFigures(figure)}
              {buttons.length > 0 && this.renderTogglerParent(buttons,togglerType)}
          </Box>
      </Grid>
  }

  renderTogglerParent=(buttons:ButtonTogglerType[],togglerType:ButtonTogglerKey)=>{
    return <Box className = 'dash_button-toggle-parent'>
      {buttons.map((buttonProps:ButtonTogglerType)=> this.rendertogglerButton(buttonProps,togglerType))}
    </Box>
  }

  rendertogglerButton=(buttonProps:ButtonTogglerType,togglerType:ButtonTogglerKey)=>{
    const {totalClientToggler,totalBookingToggler,totalUpcomingBookingToggler,totalDownloadsToggler} = this.state;
    const {name , togglerValue} = buttonProps;
    return <CustomStyledButton className="toggler_button" isToggled={togglerValue === totalClientToggler  || togglerValue === totalBookingToggler || togglerValue === totalUpcomingBookingToggler || togglerValue === totalDownloadsToggler } onClick={()=>{this.handleToggler(togglerType,togglerValue)}}>{name}</CustomStyledButton>
  }



  renderFigures=(figure={count:0,total:""})=>{
    const {count,total} = figure
    return <Box className='dash_figures_parent'>
      <Box fontSize={'2.5rem'} fontWeight='bold'>{count}</Box>
      {total && <Box fontSize={'1.3rem'} fontWeight='bold'>/ {this.getCorrectAmount(total)}</Box> }
    </Box>
  }

  renderAnalyticCardHeadAndRedirect=({title,endPoint=''}:{title:string,endPoint?:string})=>{
    return <Box className='order_detail_view_heading_parent'>
        <Box>{title}</Box>
        {title !== 'App Metrics' && <CallMade onClick={()=>this.handleNavigationToBooking(endPoint)} style={{color:colors.GOLD_COLOR,cursor:"pointer"}}/>}
    </Box>
  }

  renderSearchExistngBooking=()=>{
    const {focusSearchBar,searchValue,bookings,selectedOption} = this.state
    return <Box className= "dash_top_header_box dash_flex_column">
      <Box className = "dash_top_head_gold">Search Existing Bookings</Box>
      <Grid container>
          <Grid xs={12}>           
        <SearchStyledAutocomplete      
        options={bookings}
        PopperComponent={(props:PopperProps)=>(<SearchStyledPopper {...props} dashboard={true}/>)}
        filterOptions={(option:BookingData,{inputValue}:{inputValue:string})=>option}
        value={selectedOption}    
        id="globalSearch"       
        ListboxProps={{ id: "bookings_list" }}
        isErrorBorder={true}
        disabled={false}
        loading={this.state.loading}
        getOptionLabel={(option:BookingData) =>this.getSelectedOptionTitle(option) }
        renderOption={(option:BookingData,{selected}:AutoCompleteMenuType) => renderDashboardSearchOptions(option,selected)}
        onChange={(event:React.ChangeEvent<{}>,newValue:BookingData)=>{            
            this.handleNavigationToBookings(newValue)            
        }}
        navbar={true}
        groupBy={(option:BookingData) => option.title}
        renderInput={(params:TextFieldProps) => (<CustomDashTextField 
          {...params} 
          onFocus={()=>this.handleFocusSearchbar()}
          onBlur={()=>this.handleBlur()}
          fullWidth 
          placeholder="Search by booking no, client name or a/c no."
          
          onChange={this.handleSearchValue}/>)}
          open={focusSearchBar && searchValue}                        
      />
          </Grid>
        </Grid>
    </Box>
  }

  renderUpcomingFlightsTable = () => {
    const { currentRole} = this.state;
    return <Grid item className="dash_gen_table" style={{width:'100%'}}>
      {this.renderHeadAndViewButton('Upcoming Bookings','/OrderDetailsView')}
      <TableContainer className="dash_table_container dash_table_flights">
        <Table style={{ borderCollapse: "separate" }} >
          {this.renderColumns("upcoming")}
          <Box height={"15px"} />
          {currentRole !== 'user' ?this.renderAdminUpcomingBookingTable() : this.renderClientUpcomingBookingTable()}
        </Table>
      </TableContainer>
    </Grid>
  }

  renderAdminUpcomingBookingTable=()=>{
    const {dashboardData:{upcoming_booking}} = this.state;
    return <>
          {upcoming_booking?.map((booking: UpcomingBookingRoot) => {
            let { attributes:{booking_no,payment_status,booking_amount,departure_date,vendor:{name},client,id,order_id}} = booking;
            return <>
              <TableRow className="dash_table_row go_to_booking" onClick={()=>this.handleNavigationToBooking(`/OrderManagement?booking_id=${order_id}`)}>
                <TableCell className="dash_rounded-row-first-cell"><Box className="dash_times">#{booking_no}</Box> </TableCell>
                <TableCell>{client || '- - -'}</TableCell>
                <TableCell>{this.handleDate(departure_date)}</TableCell>
                <TableCell>{this.getCorrectAmount(booking_amount)}</TableCell>
                <TableCell style={{textTransform:'capitalize',color:payment_status  === 'paid' ? '#20E70E' : '#FFB833'}}>{payment_status}</TableCell>
                <TableCell className="dash_rounded-row-last-cell">{name || '- - -'}</TableCell>
              </TableRow>
              <Box height={"5px"} />
            </>
          })}
    </>
  }

  renderClientUpcomingBookingTable=()=>{
    const {clientDashboardData:{upcoming_booking}} = this.state;
    return <>
          {upcoming_booking?.map((booking) => {
            let {attributes:{booking_no,number_of_legs,time_to_first_leg,departing_from,departure_date,additional_details} } = booking;
            const tagText = additional_details?  'Additional Details Complete' : 'Additional Details Required';
            const textColor = additional_details ? '#34D399' : '#FFB833';
            const bgColor = additional_details ? '#254A40' : '#524429';
            return <>
              <TableRow className="dash_table_row go_to_booking">
                <TableCell className="dash_rounded-row-first-cell"><Box className="dash_times">#{booking_no}</Box> </TableCell>
                <TableCell style={{textTransform:'capitalize'}}>{time_to_first_leg}</TableCell>
                <TableCell>{number_of_legs}</TableCell>
                <TableCell>{departing_from}</TableCell>
                <TableCell>{this.handleDate(departure_date)}</TableCell>
                <TableCell className="dash_rounded-row-last-cell"><DetailsColorFul text={tagText} textColor={textColor} bgColor={bgColor}/></TableCell>
              </TableRow>
              <Box height={"5px"} />
            </>
          })}
    </>
  }

  renderTableSkeleton=(widthTable = '49%')=>{
    const tableRow = ['row1','row2','row3','row4'];
    return <Grid item className="dash_gen_table" style={{width:widthTable}}>
       <Box className="order_detail_head_edit">
          <Typography component={'div'} variant="h3" style={{width:'15rem'}}><GreySkeleton/></Typography>
          <Typography component={'div'} variant="h3" style={{width:'8rem'}}><GreySkeleton/></Typography>
       </Box> 
          <Typography component={'div'} variant="h2"><GreySkeleton/></Typography>
          <Box height={'15px'}/>
          <Box className="booking_section" style={{rowGap:'0.1rem'}}>
             {tableRow.map((rowTab)=> <Typography component={'div'} variant="h3"><GreySkeleton/></Typography>)}
          </Box>
    </Grid>
  }

  renderInboxTable=()=>{
    return <Grid item className="dash_gen_table" style={{position:"relative"}}>
      {this.renderHeadAndViewButton('Inbox','/',true)}
      <Box>
         {this.renderComingSoon()}
      </Box>
    </Grid>
  }

   renderComingSoon=()=>{
    return <Box style={{display:"flex",flexDirection:"column",rowGap:"1rem",justifyContent:'center',alignItems:"center",position:"absolute", top:"50%",left:'50%',transform:'translate(-50%,-50%)',width:'90%'}}>
       <ChatBubbleOutline style={{color:'#FFD485',fontSize:'2rem',borderRadius:"50%",backgroundColor:"#272A2D",padding:"1rem"}}/>
       <Box style={{color:'#FFD381',fontWeight:'bold',fontSize:"1.5rem"}}>COMING SOON!</Box>
       <Box textAlign={'center'} color={'#CBD5E1'}>
          <Box>Get excited as we'll shortly be launching your inbox -</Box>
          <Box>the home of your customer interactions.</Box>
       </Box>
    </Box>
   }



  renderHeadAndViewButton=(heading:string,endPoint:string,isDisable=false)=>{
    return <Box className="order_detail_head_edit">
      <Box className="dash_golden_text" style={{color:"white",fontSize:'1.2rem',fontWeight:'bold'}}>{heading}</Box>
      <CustomDashboardButton onClick={()=>this.handleNavigationToBooking(endPoint)} disabled={isDisable} style={{padding:'20px 30px', height: '40px',backgroundImage : isDisable ? `linear-gradient(#636E72, #636E72)` : '',color:isDisable ? '#94A3B8' : ''}}>View All</CustomDashboardButton>
    </Box>
  }

  renderOutstandingPayment=()=>{
    const {currentRole} = this.state;
    const isUser = currentRole === 'user';
    const heading = !isUser ? 'Outstanding Payments' : 'Previous Bookings';
    return <Grid item className="dash_gen_table">
      {this.renderHeadAndViewButton(heading,'/OrderDetailsView?outstanding=true')}
      <TableContainer className="dash_table_container">
        <Table style={{borderCollapse:'separate'}} id="table">
            {this.renderColumns("outstanding")}
            <Box height={'15px'}/>
          {!isUser ? this.renderOutstandingTableContent() : this.renderPrevBookingTableContent()}
        </Table>
      </TableContainer>      
    </Grid>
  }

  renderOutstandingTableContent=()=>{
    const {dashboardData:{outstanding_payments}} = this.state;
    return <>
          {outstanding_payments && (outstanding_payments as OutstandingPayment[]).map((booking) => {
            let { attributes:{data:{amount,booking_no,date,vendor_status}},id} = booking;
            return <>
              <TableRow className="dash_table_row go_to_booking" onClick={()=>this.handleNavigationToBooking(`/OrderManagement?booking_id=${id}`)}>
                <TableCell className="dash_rounded-row-first-cell"><Box className="dash_times">{booking_no}</Box> </TableCell>
                <TableCell>{this.handleDate(date)}</TableCell>
                <TableCell>{this.getCorrectAmount(amount)}</TableCell>
                <TableCell style={{color:this.handleVendorColor(vendor_status)}} className="dash_rounded-row-last-cell">{this.handleVendor(vendor_status)}</TableCell>
              </TableRow>
              <Box height={"5px"} />
            </>
          })}
    </>
  }

  renderPrevBookingTableContent=()=>{
    const {clientDashboardData:{previous_bookings}} = this.state;
    return <>
          {previous_bookings && previous_bookings.map((booking) => {
            let { attributes:{booking_no,booking_date,number_of_legs,departing_from,departure_date,status}} = booking;
            return <>
              <TableRow className="dash_table_row go_to_booking">
                <TableCell className="dash_rounded-row-first-cell"><Box className="dash_times">#{booking_no}</Box> </TableCell>
                <TableCell>{this.handleDate(booking_date)}</TableCell>
                <TableCell>{number_of_legs}</TableCell>
                <TableCell>{departing_from}</TableCell>
                <TableCell>{this.handleDate(departure_date)}</TableCell>
                <TableCell className={`dash_rounded-row-last-cell ${getStatus(status).className}`} style={{textTransform:'capitalize'}}> {status}</TableCell>
              </TableRow>
              <Box height={"5px"} />
            </>
          })}
    </>
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const {name,isDashboardDataLoading} = this.state
    return (
      <ThemeProvider theme={theme}>
        <Box>
          <Box className={"dash_main dash_flex_column"} >
            <Box className='dash_name_welcome'>
              Welcome, {<Box className={"dash_admin_name"} component={"span"}>{name}</Box>}
            </Box>
            {this.renderExistingAndCreateNewBookingParent()}
            <Grid
              container
              className="dash_tables_parent"
            >
              {isDashboardDataLoading ? <>
                {this.renderTableSkeleton('100%')}
                {this.renderTableSkeleton()}
                {this.renderTableSkeleton()}
              </> : <>
              {this.renderUpcomingFlightsTable()}
              {this.renderInboxTable()}
              {this.renderOutstandingPayment()}
              </>}
              
            </Grid>
          </Box>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End
