//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
export type SavedPassengerType = {
  id: string
  type: string
  attributes: {
    id: number
    passenger_type: string
    first_name: string
    last_name: string
    full_name: string
    passport_number: string
    country_of_issue: string
    email: string
    full_address: string
    weight: string
    gender: string
    country_code: string
    country_of_issue_country_code: string
    phone_number_country_code: string
    date_of_issuance: string
    date_of_expiration: string
    date_of_birth: string
    phone_number: string
    account_id: number
    passport_image: null
    passport_images: Array<{
      id: number
      name: string
      url: string
    }>
  }
}

export interface PassengerFormValuesType{
  phoneNumber:string,
  emailId:string,
  address:string,
  gender:string,
  weight:string,
  dateOfBirth:Date,
  firstName: string,
  lastName:string,
  passportNumber:string,
  dateOfInsurance:Date,
  dateOfExpiration:Date,
  countryOfIssue:{name:string},
  countryCode:string,
  companyName:string,
  passportFile:File[]|{id:string,url:string|null}[],
  passport_image_ids:number[]
}

interface ResponseMessageType {
  meta:{
    message : string
  },
  data : SavedPassengerType
}

export interface Options {
  width?: number;
  height?: number;
  cropping?: boolean;
  cropperCircleOverlay?: boolean;
  includeBase64?: boolean;
  includeExif?: boolean;
  compressImageMaxWidth?: number;
  compressImageMaxHeight?: number;
  compressImageQuality?: number;
  mediaType?: 'photo' | 'video' | 'any';
  smartAlbums?: string[];
  useFrontCamera?: boolean;
  writeTempFile?: boolean;
  loadingLabelText?: string;
  cropperToolbarTitle?: string;
  cropperToolbarColor?: string;
  cropperToolbarWidgetColor?: string;
  cropperActiveWidgetColor?: string;
  cropperStatusBarColor?: string;
  cropperTintColor?: string;
  forceJpg?: boolean;
  cropperToolbarWidgetColor?: string;
}
import { Alert } from "react-native";
import { getStorageData } from "../../../framework/src/Utilities";
import { handleTriggerAlertMessage } from "../../../components/src/Navigator";
import { convertToMMDDYYYY } from "../../../components/src/ReUsableModal";
const CategoryData = [
  {
    idss: 0,
    title: "User 1",
    value: 'user_1'
  },
  {
    idss: 1,
    title: "User 2",
    value: 'user_2'

  },
];

interface Imagetype {
  uris: string;
  width: number;
  height: number;
}
interface CategoryType {
  idss: number;
  title: string;
  value: string;
}

interface ProductItem  {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    status: boolean;
    price: number;
    user_type: string;
    quantity: string;
    created_at: string;
    updated_at: string;
    images: [
      {
        id: number;
        url: string;
        type: string;
        filename: string;
      }
    ];
  };
}
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  // Customizable Area Start
  navigation: object;
  idContent: string | number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  catagorydata: CategoryType[];
  showCategory: boolean;
  category: CategoryType;
  showModel: boolean;
  images: Imagetype[];
  imagesWeb: File[];
  baseImages: string[];
  title: string;
  description: string;
  price: string;
  quantity: string;
  userDataList:ProductItem[]
  isAddPassengerModalOpen:boolean,
  modalOpenType:'add' | 'edit',
  isDeleteModalOpen:boolean
  savedPassengerData : SavedPassengerType[]
  focusField:string|null
  deletePassengerId:string | null
  editIndex:number | null
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  idManagement: number | string;
  // Customizable Area End
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userDataListCall:string|Message=''
  addDataCall:string|Message=''
  getAllPassengerApiId = ''
  passportRef = React.createRef<HTMLInputElement>();
  addNewSavePassengerApiId = ''
  deleteSavePassenger = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      catagorydata: CategoryData,
      showCategory: false,
      category: {
        idss: 0,
        title: "User 1",
      value:'user_1'
      },
      showModel: false,
      images: [],
      imagesWeb:[],
      baseImages: [],
      title: "",
      description: "",
      price: "",
      quantity: "",
      userDataList:[],
      isAddPassengerModalOpen:false,
      modalOpenType:'add',
      isDeleteModalOpen:false,
      savedPassengerData:[],
      focusField:null,
      deletePassengerId:null,
      editIndex:null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.userDataListCall !== null &&
      this.userDataListCall ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson1 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse1 = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson1) {
        this.setState({
           userDataList: responseJson1.data,
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
        this.parseApiErrorResponse(responseJson1);
      }
      this.parseApiCatchErrorResponse(errorResponse1);
    }
    else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addDataCall !== null &&
      this.addDataCall ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson2 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse2 = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson2) {
        Alert.alert(configJSON.success)
        this.setState({
          loading: false,
          showModel:false
        });
        this.getUserDataListApi()
      } else {
        this.setState({
          loading: false,
        });
        this.parseApiErrorResponse(responseJson2);
      }
      this.parseApiCatchErrorResponse(errorResponse2);
    }

    this.handleNewCalledAPI(message)
    // Customizable Area End
  }

  // Customizable Area Start

async componentDidMount() {
  this.getAllSavedPassenger();
  this.getUserDataListApi()
}

  getUserDataListApi = async () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userDataListCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userListUrl+this.state.category.value
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  postAddDataApi=async()=>{
    const header = {
    };
    const formData = new FormData();
    formData.append("title", this.state.title)
    formData.append("description", this.state.description)
    formData.append("price", this.state.price);
    formData.append("quantity", this.state.quantity)
    formData.append("user_type", this.state.category.value)

    if(this.isPlatformWeb()){
      this.state.imagesWeb.forEach((item: File) => {
        formData.append("images[]", item)
      })
    }else{
      this.state.images.forEach((item: Imagetype) => {
        formData.append("images[]", {
          uri: item.uris,
          type: "image/png",
          name: "image"
        })
      })
    }

   

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addDataCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.addDataUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;


  }

  showCategory = () =>
    this.setState({
      showCategory: !this.state.showCategory,
    });

  onSelectCategory = (item: CategoryType) => {
    this.setState({ category: item, showCategory: false },()=>{
      this.getUserDataListApi()
    });
  };

  onSelectCategoryWeb = (value: string) => {
    const filteredData = this.state.catagorydata.find((finddd) => finddd.value === value)
    filteredData && this.setState({ category: filteredData },()=>{
      this.getUserDataListApi()
    });
  };


  addNewProduct = () => this.setState({ showModel: true });

  hideModal = () => this.setState({ showModel: false });

  openImagePicker = () => {
    this.setState({
      images: [
        ...this.state.images,
        {
          uris: 'ress.path',
          width: 300,
          height: 400,
        },
      ],
    });
  };

  onChangeImage = (event:React.ChangeEvent) => {
    const targetData= event.target as HTMLInputElement;
    const fileValue: File = (targetData.files as FileList)[0];
    const base64Img = URL.createObjectURL(fileValue)
    this.setState({ imagesWeb: [...this.state.imagesWeb, fileValue], baseImages: [...this.state.baseImages, base64Img] });
  };

  onChangeTitle = (text: string) => {
    this.setState({ title: text });
  };

  onChangeDescription = (text: string) => {
    this.setState({ description: text });
  };

  onChangePrice = (text: string) => {
    this.setState({ price: text });
  };

  onChangeQuantity = (text: string) => {
    this.setState({ quantity: text });
  };

  handleSavePassengerModal=(isOpen=false,openType='add',editIndex=null)=>{
    this.setState({isAddPassengerModalOpen:isOpen,modalOpenType:openType,editIndex});
  }

  propagationStop=(event:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
    event.stopPropagation();
  }

  handleDeleteModalOpen=(isOpen:boolean,pass_id=null)=>{
    this.setState({isDeleteModalOpen:isOpen,deletePassengerId:pass_id});
  }



  getAllSavedPassenger = async()=>{
    const headers = {
      token : await getStorageData('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
      
    this.getAllPassengerApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllPassengerEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNewCalledAPI= (message: Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId){
        case this.getAllPassengerApiId:
          this.handleGetSavedPassengerResponse(responseJson);
          break;
        case this.addNewSavePassengerApiId:
          this.handleAddSavePassengerResponse(responseJson);
          break;
        case this.deleteSavePassenger  :
          this.handleDeletePassengerResponse(responseJson);
          break;
      }
    }
  }

  handleDeletePassengerResponse=(responseJson:{message:string})=>{
    handleTriggerAlertMessage(this.send,responseJson.message);
    this.setState({deletePassengerId:null,isDeleteModalOpen:false});
    this.getAllSavedPassenger();
  }

  handleGetSavedPassengerResponse=(responseJson:{data : SavedPassengerType[]})=>{
    if(responseJson.data){
      const {data} = responseJson;
      this.setState({savedPassengerData:data,isAddPassengerModalOpen:false,isDeleteModalOpen:false,modalOpenType:'add'});
    }
  }

  handleSavePassengerFocus=(fieldName:string|null)=>{
    this.setState({focusField:fieldName});
  }

  handleSubmitForm= async(values:PassengerFormValuesType)=>{
    const {modalOpenType} = this.state;
    const addForm = modalOpenType === 'add';
    const method = addForm ? configJSON.postMethod : configJSON.putMethod;
    const endPoint = addForm ? configJSON.addSavePassengerEndPoint : configJSON.updateEndPoint;
    const header = {
      token: await getStorageData("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.addNewSavePassengerApiId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      this.handlePassengerData(values)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePassengerData = (values:PassengerFormValuesType)=>{
    const {modalOpenType,editIndex,savedPassengerData} = this.state;
    const {passport_image_ids,passportFile,firstName,lastName,passportNumber,dateOfExpiration,dateOfBirth,dateOfInsurance,countryOfIssue,countryCode,phoneNumber,emailId,address,gender,weight} = values;
    const startKey = `data`;
    const formData = new FormData();
    // return
    const passengerType = 'passenger';
    (modalOpenType === 'edit' && formData.append(`id`,savedPassengerData[editIndex as number].id));
    formData.append(`${startKey}[passenger_type]`,passengerType);
    formData.append(`${startKey}[first_name]`,firstName);
    formData.append(`${startKey}[last_name]`,lastName);
    formData.append(`${startKey}[passport_number]`,passportNumber);
    formData.append(`${startKey}[date_of_issuance]`,dateOfInsurance as unknown as string);
    formData.append(`${startKey}[date_of_expiration]`,dateOfExpiration as unknown as string);
    formData.append(`${startKey}[country_of_issue]`,countryOfIssue.name);
    formData.append(`${startKey}[country_code]`,countryCode);
    formData.append(`${startKey}[phone_number]`,phoneNumber);
    formData.append(`${startKey}[email]`,emailId);
    formData.append(`${startKey}[full_address]`,address);
    formData.append(`${startKey}[date_of_birth]`,dateOfBirth);
    formData.append(`${startKey}[weight]`,weight);
    formData.append(`${startKey}[gender]`,gender);
    passport_image_ids.forEach((passport)=>formData.append('passport_image_ids[]',passport));
    passportFile.forEach((passport,passInd)=>{
      if(passport?.size){formData.append(`${startKey}[passport_images][]`,passport)}
    });
    return formData;
  }
  
  handleAddSavePassengerResponse=(responseJson:ResponseMessageType)=>{
    if(responseJson.meta){
      this.handleAddSavePassenger(responseJson);
      this.setState({isAddPassengerModalOpen:false,isDeleteModalOpen:false,modalOpenType:'add'});
    }else{
      handleTriggerAlertMessage(this.send,'Something went wrong','error');
    }
  }
  
  handleAddSavePassenger=(responseJson:ResponseMessageType)=>{
    const {savedPassengerData} = this.state;
    const {meta:{message},data} = responseJson;
    handleTriggerAlertMessage(this.send,message);
    if(message === 'Passenger added successfully'){
      this.setState({savedPassengerData:[data,...savedPassengerData]});
      return;
    } 
    let updatedData = savedPassengerData.map((passenger)=> passenger.id === data.id ? data : passenger);
    this.setState({savedPassengerData:[...updatedData]});
  }

  deleteSavedPassenger = async()=>{
    const {deletePassengerId} = this.state;
    const headers = {
      token : await getStorageData('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteSavePassenger = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteSavePassengerEndPoint + deletePassengerId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getEditPassengerDetails=()=>{
    const {savedPassengerData,editIndex} = this.state;
    const passengerData = savedPassengerData[editIndex as number]
    const {first_name,id,last_name,passport_number,country_of_issue,email,weight,date_of_birth,date_of_expiration,date_of_issuance,gender,full_address,phone_number,country_code,passport_images} = passengerData.attributes
    const passengerDetails = {
      passengerId:id,
      firstName: first_name,
      lastName:last_name,
      dateOfBirth:convertToMMDDYYYY(date_of_birth),
      gender:gender,
      passportNumber:passport_number,    
      phoneNumber:phone_number,
      dateOfInsurance:convertToMMDDYYYY(date_of_issuance),
      countryOfIssue:{name:country_of_issue},
      emailId:email,
      address:full_address,
      weight:weight,
      dateOfExpiration:convertToMMDDYYYY(date_of_expiration),
      countryCode:country_code,
      fullNumber:`${country_code}${phone_number}`, 
      passportFile:passport_images?passport_images:[],
      passport_image_ids:[]  
    }

    return passengerDetails;
}

  // Customizable Area End
}
