import { ICity, ICountry, IState } from "country-state-city";
import { FormikValues } from "formik";
import * as React from "react"
import { DateObject } from "react-native-calendars";
const imgPasswordVisible = require("../assets/ic_password_visible.png");
 const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
 const backarrow = require("../assets/backarrow.png");
 const face = require("../assets/Face.png");
 const email_icon = require("../assets/email_icon.png");
 const Slider = require("../assets/Slider.png");
 const vector = require("../assets/Vector.png");
 const edit_icon = require("../assets/edit_24px.png");
 const card = require("../assets/card.png");
 const userIcon = require("../assets/user-profile.png")
 const checkboxIcon = require("../assets/group_Icons.png")
 const deleteIcon = require("../assets/delete_outline_24px.png")
 const confirmIcon = require("../assets/confirm_icon.png")
 const masterCard = require("../assets/mastercard.png")
 const visa = require("../assets/visa.png")
 const americanExpress = require("../assets/americanExpress.png")
 const defaultCard = require("../assets/creditCard.png")
 interface EachAdminRow {
    id:string,
    type:string,
    attributes:Attributes
}
 interface Attributes {
    account_id:number,
    account_number:string
    email:string,
    full_name:string,
    job_id:string,
    job_title:string,
    account_type:string,
    commission:string ,
    photo : null | string
}

 interface AdminListResponse{
    total_pages : number,
    data  : {
        data : EachAdminRow[]
    }
}

const UploadIcon2=()=>{
    return <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26 33.5969C25.3979 33.5969 24.8936 33.3929 24.487 32.9849C24.079 32.5783 23.875 32.074 23.875 31.4719V16.2781L19.8906 20.2625C19.4656 20.6875 18.9698 20.9 18.4031 20.9C17.8365 20.9 17.3229 20.6698 16.8625 20.2094C16.4375 19.7844 16.2342 19.2793 16.2526 18.6943C16.2696 18.1106 16.4729 17.624 16.8625 17.2344L24.5125 9.58437C24.725 9.37187 24.9552 9.221 25.2031 9.13175C25.451 9.04392 25.7167 9 26 9C26.2833 9 26.549 9.04392 26.7969 9.13175C27.0448 9.221 27.275 9.37187 27.4875 9.58437L35.1375 17.2344C35.5625 17.6594 35.7658 18.1637 35.7474 18.7474C35.7304 19.3325 35.5271 19.8198 35.1375 20.2094C34.7125 20.6344 34.2082 20.8554 33.6245 20.8724C33.0394 20.8908 32.5344 20.6875 32.1094 20.2625L28.125 16.2781V31.4719C28.125 32.074 27.9217 32.5783 27.5151 32.9849C27.1071 33.3929 26.6021 33.5969 26 33.5969ZM13.25 42.0969C12.0812 42.0969 11.0811 41.6811 10.2495 40.8495C9.4165 40.0165 9 39.0156 9 37.8469V33.5969C9 32.9948 9.20329 32.4897 9.60988 32.0817C10.0179 31.6752 10.5229 31.4719 11.125 31.4719C11.7271 31.4719 12.2321 31.6752 12.6401 32.0817C13.0467 32.4897 13.25 32.9948 13.25 33.5969V37.8469H38.75V33.5969C38.75 32.9948 38.954 32.4897 39.362 32.0817C39.7686 31.6752 40.2729 31.4719 40.875 31.4719C41.4771 31.4719 41.9814 31.6752 42.388 32.0817C42.796 32.4897 43 32.9948 43 33.5969V37.8469C43 39.0156 42.5842 40.0165 41.7526 40.8495C40.9196 41.6811 39.9188 42.0969 38.75 42.0969H13.25Z" fill="#CBD5E1"/>
    </svg>
    
}

interface BookingsData{
    order_id:number,
    departure_date: string,
    trip_type: string,
    booking_no: string,
    amount: string,
    payment_status: string,
    vendor: string,
    vendor_status: string,
    no_of_legs: number
}

interface PassengerData{
    
        "id": string,
        "type": string,
        "attributes": {
            "id": string,
            "passenger_type": string,
            "first_name": string,
            "last_name": string,
            "full_name": string,
            "passport_number": string,
            "country_of_issue": string,
            "email": string,
            "full_address": string,
            "weight": string,
            "gender": string,
            "country_code": string,
            "country_of_issue_country_code": string,
            "phone_number_country_code": string,
            "date_of_issuance": string,
            "date_of_expiration": string,
            "date_of_birth": string,
            "phone_number": string,
            "account_id": number,
            "passport_image": {
                "id": number,
                "name": string,
                "url": string,
            },
            passport_images:File[]
        }
    
}

interface PassengerInfo {
    client_id?: string|null; // Optional client_id
    id?: string|null;        // Optional id
    data: {
        first_name: string;
        last_name: string;
        passport_number: string;
        date_of_issuance: string;
        date_of_expiration: string;
        country_of_issue: string;
        country_code: string;
        company_name:string,
        phone_number: string;
        email:string,
        full_address:string,
        gender:string,
        weight:string,
        date_of_birth:string,
        passport_file?:FileData[]
        // Add other properties as needed
    }
    passport_images:boolean,
    passport_image_ids:number[]
}

interface AirportType{
    
        "id": string,
        "name": string,
        "city": string,
        "country": {
            "code": string,
            "name": string,
        },
        countryCode:string,
        code:string,
        "icao": string,
    }

    interface Airports{
        "id": string,
        "name": string,
        "city": string,
        countryCode:string,
        code:string,        
    }

    export type FileValues = {id:string,url:string|null}
    interface PassengerFormValues{
        firstName: string,
        lastName:string,
        passportNumber:string,
        dateOfInsurance:DateObject,
        dateOfExpiration:DateObject,
        countryOfIssue:{name:string},
        countryCode:string,
        phoneNumber:string,
        emailId:string,
        address:string,
        gender:string,
        weight:string,
        dateOfBirth:DateObject,
        companyName:string,
        passportFile:File[]|FileValues[],
        passport_image_ids:number[]
    
    
    }


interface PresonalInfoValues{
    preferredAirport:{name:string,city:string,code:string},
    full_name:string,
    dietary_requirements:string,
    optedCountry:{name:string},
    state:string,
    city:string,
    zip_code:string,
    street_address:string,
    countryCode:string,
    phoneNumber:string,
    photo:{name:string}
}

type InitiailValue = {
    optedState: IState|{name:string|null},
    optedCity: ICity|{name:string|null},
    optedCountry: ICountry|{name:string|null},
    preferredAirport : {name:string,city:string,code:string}
  }

interface ProfileDataType  {
    full_name:string,
      email:string | null,
      phone_number:string | null,
      preferred_departure_airport:string,
      street_address:string | null,
      zip_code:string | null,
      dietary_requirements: string | null,
      photo:string|null|Blob,
      account_id: null | string,
      countryCode: string,
      city:string,
      state:string|null,
      main_country_of_residence:string|null,
      phoneNumber:string,
      companyName:string,
  
  }

  type formikCobmineTypeType = ProfileDataType & InitiailValue;
  interface CardDetails{
    id:number,
    type:string,
    attributes: {
        cards:{
      id: number,
      card_type: string,
      card_number: string,
      card_holder_name: string,
      security_code: string,
      country_code: number,
      street_address: string,
      zipcode: string,
      city: string,
      state: string,
      country: string,
      country_code_string: string,
      expiry_date: string,
        }
    primary_method: boolean
        }
    
  }
  interface CardDetails{
    cardNumber:string,
    cardholderName:string,
    expiryDate:string,
    securityCode:string,
    streetAddress:string,
    zipCode:string,
    city:string,
    state:string,
    country:{name:string}
  }
  type DynamicStringObject = {
    [key: string]: string;
  };
  interface FileData {
    filename: string;
    data: string | ArrayBuffer | null;
  }

type setFieldValueType =(field: string, value: string | File|null, shouldValidate?: boolean | undefined) => void
type setFieldErrorType =  (field: string, message: string | undefined) => void;

export {Attributes,AdminListResponse,UploadIcon2,EachAdminRow,imgPasswordInVisible,imgPasswordVisible,backarrow,
    face,email_icon,Slider,vector,edit_icon,card,userIcon,checkboxIcon,deleteIcon,BookingsData,PassengerData,PassengerInfo,
AirportType,Airports,confirmIcon,PassengerFormValues,PresonalInfoValues,InitiailValue,ProfileDataType,formikCobmineTypeType,CardDetails,FileData,setFieldValueType,setFieldErrorType,
americanExpress,defaultCard,visa,masterCard}