import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { FormikProps } from "formik";
import { handleNavigation } from "../../../components/src/Navigator";

type NullString = null | string
export interface PaymentFormType{
    name : NullString,
    fedwire_routing : NullString,
    account_number : NullString,
    account_type : NullString,
    rec_address : NullString,
    zip_code : NullString
}
const payment_setting_val = {
    name : null,
    fedwire_routing : null,
    account_number : null,
    account_type : null,
    rec_address : null,
    zip_code : null
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    isEditState:boolean;
    payment_form:PaymentFormType;
    activeLabel:string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PaymentSettingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    paymentFormik:null | FormikProps<PaymentFormType> = null;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            isEditState:false,
            payment_form : payment_setting_val,
            activeLabel:''
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        // Customizable Area End
    }



    // Customizable Area Start
    handleActiveLabel=(activeLabel="")=>{
        this.setState({activeLabel});
    }

    handleEditState=(isEditState=false)=>{
        this.setState({isEditState});
    }

    handleBack=()=>{
        const {isEditState} = this.state;
        if(isEditState) this.cancelSave();
        else handleNavigation('/',this.props,this.send);
    }

    cancelSave=()=>{
        this.handleEditState();
        this.paymentFormik?.resetForm();
    }
    // Customizable Area End
}