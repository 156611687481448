import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  styled,
  Collapse,
  Grid,
  AccordionDetails,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "./Cfavinodeintegration2.css";
import {AirplanemodeActive,ArrowBack, ExpandLess, KeyboardArrowRight, Flight, FilterList, Edit} from '@material-ui/icons';
import {CustomCheckbox, handleMinutesAsHoursAndMinutes, GoldishSimpleButton, ButtonTogglerFormContainer, CustomFormTabButton, Loader, MuiModal, StyledModalContent, CustomTypography, GenralUsedAccordion, MyAccordionSummary, FormSubmitButton, OutlineButton, AddLegtTypeButton } from "../../../components/src/MuiCustomComponents";
import {Formik, FormikProps} from 'formik'
import {oneWayFormValidationSchema} from "../../../components/src/Validations";
import {NoResultsFound} from "./assets";
import {theme as colors} from '../../../components/src/utils';
import { renderOneWayForm, renderSelect } from "../../../components/src/FormComponents";
import { v4 } from 'uuid';


type FilterInitialValuesType = FormikProps<{
  selected_categories: string[]
  no_of_stops : string[]
}>;

interface FormType{
  fromLocation : unknown,
  toLocation : unknown,
  aircraftCategory: unknown,
  passengers : string
  departure? :Date
}

type OneWayFormikPropType  = FormikProps<FormType>;
const StyledTableCell = styled(TableCell)({
  color: 'white',
  borderBottom: 'none',
  fontSize : '1.2rem',
  maxWidth:'5rem'
});

const TableRowNew = styled(TableRow)({
  marginBottom:'2rem'
});
import {AvailableBookingData, ExpandRowsType, SegmentType} from "./Cfavinodeintegration2Controller";




// Customizable Area End

import Cfavinodeintegration2Controller, {
  Props,
  configJSON,
} from "./Cfavinodeintegration2Controller";

export default class Cfavinodeintegration2 extends Cfavinodeintegration2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

    renderFilterButtons=()=>{
      const {selectedFilter} = this.state; 
     return <ButtonTogglerFormContainer className="advance_search_button_container" style={{columnGap:"0.4rem"}}>
          <CustomFormTabButton
              onClick={()=>{this.formToggler(0); this.handleSorting('price')}}
              selected={selectedFilter === 0}
              className="cf_avi_filter_price"
          >
              Price Low {'>'} High
          </CustomFormTabButton>
          <CustomFormTabButton
              selected={selectedFilter === 1}
              onClick={()=>{this.formToggler(1); this.handleSorting('flightMinutes')}}
              className="cf_avi_filter_fastest"
          >
              Fastest
          </CustomFormTabButton>
          <CustomFormTabButton
              selected={selectedFilter === 2}
              onClick={()=>{this.formToggler(2); this.handleSorting('fuelStopCount')}}
              className="cf_avi_filter_stops"
          >
              No. of Stops
          </CustomFormTabButton>
          {this.renderFilterIcon()}
        </ButtonTogglerFormContainer>
    }


    renderHeader = ()=>{
      return  <GoldishSimpleButton style={{color : colors.GOLD_COLOR,textTransform : "capitalize"}} onClick={this.handleEdit} className="cf-avinode-search"><ArrowBack className = 'cf_avinod_arrow_search'/>Search Results</GoldishSimpleButton>
    }

    renderCharterHeaderRow = ()=>{  
      return  <Paper style={{ backgroundColor: '#2D3034' }}>
      <Table>
        <TableBody>
          <TableRow>
            <StyledTableCell className="aircraft_type_col">Aircraft Type</StyledTableCell>
            <StyledTableCell>Configuration</StyledTableCell>
            <StyledTableCell className="price_col_table">Price</StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
    }

    renderEditIcon = ()=>{
      return <Box className = 'advance_search_exchange_container' onClick={this.handleEdit}>
        <Edit/>
      </Box>
    }

    renderOneWayFormParentFormik = (parentIndex:number) => {
      const {availableFlightData : {request_summary}} = this.state as {availableFlightData:AvailableBookingData};
      const {from_airport , to_airport,departure_date,departure_time, passenger_configuration} = request_summary[parentIndex];
      const oneWayIntialValues = {
        fromLocation : from_airport,
        toLocation : to_airport,
        aircraftCategory: null,
        passengers : passenger_configuration
      }



      return (
        <Formik
          enableReinitialize
          key={3}
          initialValues={{...oneWayIntialValues,departure:new Date(this.handleCombineTime(departure_date as unknown as Date,departure_time as unknown as Date) as unknown as number)}}
          onSubmit={(values) => {
          }}
          validationSchema={oneWayFormValidationSchema}
          validateOnChange
          validateOnBlur
        >
          {(formikProps: OneWayFormikPropType) => (
                <Box display='flex' alignItems='center' justifyContent={'space-between'}>
                  {renderOneWayForm(
                    formikProps,
                    "fromLocation",
                    "toLocation",
                    "departure",
                    "passengers",
                    "aircraftCategory",
                  )}
                  {this.renderEditIcon()}
                </Box>
          )}
        </Formik>
      )
    }  


    renderHeadingChartedTableHead = () => {
      return (
          <Box className='cf_avinod_content_table'>
              <Box className='cf_avinod_each_leg_head'>Charter flights available</Box>
               {this.renderCharterHeaderRow()}
          </Box>
      );
  }

  renderAircarftCategoryRow = (parentIndex:number)=>{
    const { expandedRows, availableFlightData : {categorized_aircrafts} } = this.state as {availableFlightData:AvailableBookingData,expandedRows:ExpandRowsType};
    const {max_passenger_capacity, category, price_starts_from,aircrafts_count} = categorized_aircrafts[parentIndex];
    return <Box className="cf_avinode_aircraft_table_cont">

              <Paper style={{ backgroundColor: '#1A1C1E' }}>
              <Table>
                 <TableBody>
                    <TableRow onClick={() => this.flightToggler(parentIndex)} style={{cursor:'pointer'}}>
                        <StyledTableCell>
                          <Box className = 'cf_avinod_box_column'>
                            <Box className='cf_avinod_big_text_table'>{category}</Box>
                            <Box className='cf_avinod_big_flight_available cf_avinod_big_gold_color'>{max_passenger_capacity} Passengers</Box>
                          </Box>                                
                        </StyledTableCell>
                        <StyledTableCell style={{ fontSize: '1rem' }}>
                            <Box className='cf_avinod_big_text_table'>{aircrafts_count} Flights</Box>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Box className='cf_avinod_big_text_table cf_avinod_big_gold_color'>From ${price_starts_from.toLocaleString()}</Box>
                        </StyledTableCell>
                        <StyledTableCell style={{textAlign:'center'}}>
                            <IconButton className="cf_avinode_toggler">
                                {!(expandedRows as Record<string,string>)?.[parentIndex as unknown as string] ? <KeyboardArrowRight className = 'cf_avinod_big_gold_color'/> : <ExpandLess className = 'cf_avinod_big_gold_color'/>}
                            </IconButton>
                        </StyledTableCell>
                    </TableRow>
                    {this.renderAircarftBox(parentIndex)}
                </TableBody>
                </Table>
      </Paper> 


    </Box>
  }



  renderAircarftBox = ( parentIndex : number) => {
    const { isMoreLoading,pageStore } = this.state;
    const { aircrafts,total_pages } = (this.state.availableFlightData as AvailableBookingData).categorized_aircrafts[parentIndex];
    return (
      <TableRowNew>
        <StyledTableCell colSpan={4} style = {{padding : '0'}}>
          <Collapse unmountOnExit in={(this.state.expandedRows  as Record<string,string>)?.[parentIndex as unknown as string] as unknown as boolean} timeout='auto'>
          <Paper style={{ backgroundColor: '#1A1C1E',padding:'16px' }}>
    <Grid container spacing={2} justifyContent="space-between">
          {aircrafts.length !== 0 ? <>{aircrafts.map((_element,index:number)=> {
            return <>{this.renderAircarftCont(parentIndex,index)}</>
          })}
          <Grid md={12} sm={12} item><AddLegtTypeButton className="load_more_button" fullWidth onClick={()=>{this.handleLoadMore((pageStore as number[])[parentIndex]+1,parentIndex)}} disabled={this.handleIsDisabled((pageStore as number[])[parentIndex],total_pages,isMoreLoading)}>{this.handleLoadingText(isMoreLoading)}</AddLegtTypeButton></Grid>
          </>  : this.renderNoResultsFound()}
      </Grid>
    </Paper>
          </Collapse>
        </StyledTableCell>
      </TableRowNew>
    )
  }

  renderAircarftCont=(index1:number,index2:number)=>{
    const {availableFlightData : {categorized_aircrafts}} = this.state as {availableFlightData:AvailableBookingData};
    const {aircrafts} = categorized_aircrafts[index1];
    const {quote: {lift : {maxPax,aircraftType,image,aircraft_id},price,segments}} = aircrafts[index2];

  
    return <Grid md={12} sm={12} lg={6} item>
        <Box className="cf_avinode_flight_container">
          <Box className="cf_avinode_head_book" alignItems='center'>

            <Box className="cf_avinode_flight_head">
              {this.renderMainImage(image)}
              <Box className="cf_avinode_head_image_seat">
                <Box className='cf_avinod_big_text_table cf_avinod_big_gold_color'>{aircraftType}</Box>
                <Box className="cf_avinod_big_flight_available">{maxPax} Seats</Box>
              </Box> 
            </Box>

           {!aircraft_id && <Box color={'#6C7278'}>No Data Available</Box>}
           {aircraft_id && <Box className="view_aircraft_text">
                <AirplanemodeActive style={{transform:"rotate(90deg)"}}/>
                <Box>View Details</Box>
                <Box>{">"}</Box>
            </Box>}
          </Box>

          <Box className="leg_representer">
            <Grid container spacing={2} > 
            {segments && segments.map((segment,index:number)=> <>{this.renderLegStopAndTime(segment,index)}</>)}
            </Grid>


            <Box className="cf_avinode_flight_head" alignItems={'center'} justifyContent={'end'} marginTop={'0.5rem'}>
              <Box className = 'cf_avinod_mid_text_table'>${price.toLocaleString()}</Box>
              <CustomFormTabButton className="book_now" selected style={{fontSize:'1.1rem',width:'auto',padding : '0.8rem 2.2rem',height:'auto',margin:'0'}} onClick={()=>this.postNewBooking(aircrafts[index2])}>Book Now</CustomFormTabButton>
            </Box> 

          </Box>

        </Box>
    </Grid>
  }

  renderMainImage=(image:string | null)=>{
    return <Box className="main_image_aircraft">
      {image ?  <img src={image} alt="Main Image" height={'100%'} width={'100%'}/> : 
        <AirplanemodeActive className="flight_image_aircarft"/>
      }
      
    </Box>
  }

   renderNoResultsFound=()=>{
    return <Box className="cf_avinod_no_result_container">
        <NoResultsFound/>
        <Box className="cf_avinod_no_result_head" style={{color:'white'}} marginTop={'-0.8rem'}>No Result Found</Box>
        <Box className="cf_avinod_grey_text" fontSize={'1.2rem'}>Try changing the search criteria and searching again.</Box>
    </Box>
  }


  renderLegStopAndTime=(segment:SegmentType,legCount:number)=>{
    const {formType} = this.state;
    const {fuelStopCount, flightMinutes} = segment;
    const stopsText = fuelStopCount !== 0 ? `${fuelStopCount} Stop` : 'Non Stop';
    const roundTripSecLeg = 1;
    return <Grid item sm={6}>
       <Box className="cf_avinode_flight_head" style={{padding:'0.5rem 0rem'}}>
       <Flight style={{fontSize : '0.95rem',transform: formType === 1 &&  roundTripSecLeg === legCount ?  `rotate(270deg)` : `rotate(90deg)` }} className = {fuelStopCount === 0 ? 'cf_avinod_flight_black' : 'cf_avinod_flight_red'}/>
              <Box className="cf_avinode_head_seats">
                <Box className = 'cf_avinod_grey_text cf_avinod_big_flight_available'>Leg {legCount+1}</Box>
                <Box className = 'cf_avinod_mid_text_table cf_avinod_big_gold_color'>{stopsText} - <Box className="cf_avinod_mid_text_table" component={'span'} color={'white'}> {handleMinutesAsHoursAndMinutes(flightMinutes)}</Box></Box>
              </Box> 
            </Box>
    </Grid>
  }

  renderFilterIcon = ()=>{
    return (
      <Box
       className="cf_avinod_exchange_container cf_avinod_filter_button" 
       onClick={()=>this.handleFilterModalOpenAndClose(true)}
      >
        <FilterList />
      </Box>
    )
  }

  renderFormsAndFlightData=()=>{
    return <Box className="cf_avinod_legs_parent advance_search_form_parent">
          {this.renderFilterButtons()} 
          {this.renderFormAndRelatedData()}
          {this.renderAircarftData()}
    </Box>
  }

  renderFormAndRelatedData=()=>{
    const {availableFlightData : {request_summary,category}} = this.state as {availableFlightData:AvailableBookingData};
    return <Box className="cf_avinode_aircraft_table_cont">
      <Box className="cf_avinode_aircraft_aligner">{renderSelect(`${category}`)} </Box>
        {request_summary && request_summary.map((_element,index:number)=> this.renderOneWayFormParentFormik(index))}
    </Box>
  }

  renderAircarftData=()=>{
    const {availableFlightData: {categorized_aircrafts}} = this.state as {availableFlightData:AvailableBookingData};
     return <Box className="cf_avinode_aircraft_table_cont cf_avinode_aircraft_aligner" style={{marginTop:'-1rem'}}>
        {this.renderHeadingChartedTableHead()}
        {categorized_aircrafts.length !== 0 ? categorized_aircrafts.map((_element,index:number)=> this.renderAircarftCategoryRow(index)) : this.renderNoResultsFound()}
     </Box> 
  } 

  renderLoader=()=>{
    const {loadMoreText} = this.state;
    return <Loader>
      <Box className="cf_avinod_no_result_head" marginTop={'1rem'}>Please Wait</Box>
      <Box fontSize={'1.2rem'}>We're {loadMoreText}....</Box>
    </Loader>
  }

  renderActiveScreen=()=>{
    return  <Box className = 'cf_avinod_main'>
    {this.renderHeader()}
    {this.renderFormsAndFlightData()}
    {this.renderFilterModal()}
  </Box>
  }

  renderFilterModal = () => {
    const { isFilterModalVisible, aircraftsFilterOption, numberOfStopsFilterOption } = this.state
    const filterIntialValues = {
      selected_categories: aircraftsFilterOption,
      no_of_stops: numberOfStopsFilterOption
    }
    return (
          <Formik
             initialValues={filterIntialValues}
             onSubmit={(values) => {
              const {selected_categories, no_of_stops} = values;
                this.handleFilter(selected_categories, no_of_stops);
             }}
             enableReinitialize
             validateOnChange
             validateOnBlur
          >
             {(formikProps:FilterInitialValuesType) => (
                   <MuiModal
                      open={isFilterModalVisible}
                      onClose={() => {
                         this.handleFilterModalOpenAndClose(false);
                         formikProps.resetForm();
                      }}
                   >
                      <StyledModalContent className='cf_avindode_filter_modal'>
                         <CustomTypography>Filters</CustomTypography>
                         {this.renderStopsOrAircraftFilter('stops',formikProps)}
                         {this.renderStopsOrAircraftFilter(
                            "aircraft",formikProps
                         )}
                         {this.renderSubmitFilter(formikProps)}
                      </StyledModalContent>
                   </MuiModal>
             )}
          </Formik>
    )
 }

            renderStopsOrAircraftFilter=(filterType : 'aircraft' | 'stops',formikProps:FilterInitialValuesType)=>{
              const isAircraftModalFilter = filterType === 'aircraft'; 
              const heading = isAircraftModalFilter ? 'Aircraft Type': 'Number of Stops';
              const {availableFlightData : {filters : {selected_categories, no_of_stops}}} = this.state as {availableFlightData:AvailableBookingData};
              const filterToMap = isAircraftModalFilter ? selected_categories : no_of_stops;
              return <GenralUsedAccordion expanded style={{overflow:'visible'}}>
              <MyAccordionSummary
                  expandIcon={null}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className="cf_avindode_accordion_header"
              >
                <Box>{heading}</Box>
              </MyAccordionSummary>
              <AccordionDetails className="cf_avindode_accordion_content cf_avindode_accordion_content_filter_option">
                {filterToMap && filterToMap.map((_elem,index)=><React.Fragment key={v4()}>{this.renderFilterOptions(index, formikProps,isAircraftModalFilter)}</React.Fragment>)}
              </AccordionDetails>
              </GenralUsedAccordion>
            }

            
          renderFilterOptions = (index:number, formikProps:FilterInitialValuesType, isAircraftModalFilter:boolean)=>{
            const {values, setFieldValue} = formikProps;
            const {availableFlightData : {filters : {selected_categories, no_of_stops}}} = this.state as {availableFlightData:AvailableBookingData};
            const keyInValue = isAircraftModalFilter ? 'selected_categories' : 'no_of_stops';
            const classNote = isAircraftModalFilter ? 'cf_checks_' : 'cf_stops_checks_';
            const toCheckFrom = isAircraftModalFilter ? selected_categories : no_of_stops;
            const formikActiveFilterValue = isAircraftModalFilter ? values.selected_categories : values.no_of_stops;
            const option = toCheckFrom[index];
            const optionToDisplay = (toCheckFrom as unknown as number[])[index]  === 0 ? 'Non Stop' : toCheckFrom[index]
            return <Box className="cf_avindode_option_container cf-avinode-search">
               <Box>{optionToDisplay}</Box>
               <CustomCheckbox checked={values[keyInValue].includes(option)} className={`${classNote + index}`} onChange={()=>{this.handleCheckAndUncheck(values[keyInValue].includes(option),setFieldValue,option,formikActiveFilterValue,keyInValue)}}/>
            </Box>
          }

          renderSubmitFilter=(formikProps:FilterInitialValuesType)=>{
            const {handleSubmit,resetForm} = formikProps;
            return <>
              <FormSubmitButton className="cf_avinode_apply_filter" fullWidth onClick={(event:React.MouseEvent<HTMLButtonElement>)=>{handleSubmit(event as unknown as React.FormEvent<HTMLFormElement>)}}>Apply Filters</FormSubmitButton>
              <OutlineButton fullWidth onClick={() => {this.handleFilterModalOpenAndClose(false);resetForm();}}>Cancel</OutlineButton>
            </>
          }


  // Customizable Area End

  render() {
    const {loader} = this.state;
    
    return (
      // Customizable Area Start
        <>{loader ? this.renderLoader() : this.renderActiveScreen()}</>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
