//@ts-nocheck
import React, { Fragment } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CancelIcon from '@material-ui/icons/Cancel';
  // Customizable Area Start
import {
  Box,
  Container,
  Input,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Dialog,
  Divider,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  AccordionDetails
} from "@material-ui/core";
import { AddLegtTypeButton, CSSPropertiesMap, DocDownlaodDelete, EachRowInfo, formatByThree, GenralUsedAccordion, GoldishSimpleButton, MyAccordionSummary, SureModalButtonContainer, SurityModal } from "../../../components/src/MuiCustomComponents";
import { DeleteOutline, Edit, KeyboardArrowDown } from "@material-ui/icons";
import {theme as colors} from '../../../components/src/utils';
import { AddPassengerModal } from "../../../components/src/ReUsableModal";
import { UploadIcon2 } from "../../../components/src/assets";
import {PassengerFormValuesType} from './ContentManagementController';
// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";
  // Customizable Area Start
interface ProductItem {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    status: boolean;
    price: number;
    user_type: string;
    quantity: string;
    created_at: string;
    updated_at: string;
    images: [
      {
        id: number;
        url: string;
        type: string;
        filename: string;
      }
    ];
  };
}
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#376fd0",
      contrastText: "#fff",
    },
    error: {
      main: "#f44336",
      contrastText: "#fff",
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End


export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMainCont=()=>{
    const {isAddPassengerModalOpen,modalOpenType,focusField} = this.state;
    return <Box style={Styles.mainContainer} className = 'mainCont'>
      {this.renderAccordionParent()}
      <AddPassengerModal
        isOpen={isAddPassengerModalOpen} 
        actionType={modalOpenType}
        section = "client"
        cancel = {()=>this.handleSavePassengerModal(false,'add')}
        uploadIcon={<UploadIcon2/>}
        focusField = {this.handleSavePassengerFocus}
        focusedField={focusField}
        {...(modalOpenType !== "add" ? {initialValues:this.getEditPassengerDetails()}:{})}
        passportRef ={this.passportRef}
        submit={(values:PassengerFormValuesType)=>this.handleSubmitForm(values)}          
        />
        {this.renderDeleteConfirmationModal()}
    </Box>
  }

  renderAccordionParent=()=>{
    const {savedPassengerData} = this.state;
    return <Box style={Styles.accordionParent}>
      {savedPassengerData && savedPassengerData.map((savedPas,passInd:number)=>this.renderEachPassengerAccordion(passInd))}
        <AddLegtTypeButton
         fullWidth
         onClick={()=>this.handleSavePassengerModal(true)}
         className = 'add_passenger'
        >+ Add New Passenger</AddLegtTypeButton>
    </Box>
  }

  renderEachPassengerAccordion = (passInd:number)=>{
    const {savedPassengerData} = this.state;
    const passengerData = savedPassengerData[passInd];
    const {attributes:{full_name},id} = passengerData;
    return  <Box className = 'order_detail_view_accordion_parent'>
    <GenralUsedAccordion style={{overflow:'visible',width:"100%"}} >
          <MyAccordionSummary
            expandIcon={<KeyboardArrowDown style={{color:'white'}}
            />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            className="cf_avindode_accordion_header"
          >
            <Box className = 'order_detail_view_edit_expand' style={{color:'white'}}>
              <Box>{full_name}</Box>
              {this.renderEditDeleteIconButton(id,passInd)}
            </Box>
          </MyAccordionSummary>
          <AccordionDetails className="cf_avindode_accordion_content cf_avindode_accordion_content_filter_option">
            {this.renderEachPassengerAccordionDetails(passInd)}
          </AccordionDetails>
    </GenralUsedAccordion>
 </Box>
  }

  renderEachPassengerAccordionDetails=(passInd:number)=>{
    return  <Grid container style={{backgroundColor:'#1A1C1E'}}>
        <Grid lg={6}md={12} sm={12} xs={12} item>{this.leftGrid(passInd)}</Grid>
        <Grid lg={6}md={12} sm={12} xs={12} item>{this.rightGrid(passInd)}</Grid>
    </Grid>
  }

  leftGrid=(passInd:number)=>{
    const {savedPassengerData} = this.state;
    const passengerData = savedPassengerData[passInd];
    const {attributes:{full_name,passport_number,date_of_issuance,date_of_expiration,country_of_issue,passport_images}} = passengerData;

    return <Box className="order_detail_last_child" style={{borderRight:'1px solid #3E4247'}}>
      <EachRowInfo keyName={'Full Name'} valueName={full_name}/>
      <EachRowInfo textTransform="none" keyName={'Passport Number'} valueName={passport_number}/>
      <EachRowInfo keyName={'Date of Issuance'} valueName={date_of_issuance}/>
      <EachRowInfo keyName={'Date of Expiration'} valueName={date_of_expiration}/>
      <EachRowInfo keyName={'Country of Issue'} valueName={country_of_issue}/>
      {passport_images && passport_images.map(({name,url})=><DocDownlaodDelete name={name} link={url}/>)}
    </Box>
  }

  rightGrid=(passInd:number)=>{
    const {savedPassengerData} = this.state;
    const passengerData = savedPassengerData[passInd];
    const {attributes:{phone_number,email,full_address,date_of_birth,weight,gender,country_code}} = passengerData;
    
    return <Box className="order_detail_last_child" >
    <EachRowInfo keyName={'Phone Number'} valueName={ `+${country_code} ${formatByThree(phone_number)}`}/>
    <EachRowInfo textTransform="none" keyName={'Email'} valueName={email}/>
    <EachRowInfo textTransform="none" keyName={'Full Address'} valueName={full_address}/>
    <EachRowInfo keyName={'Date of Birth'} valueName={date_of_birth}/>
    <EachRowInfo keyName={'Weight'} valueName={weight}/>
    <EachRowInfo keyName={'Gender'} valueName={gender}/>
  </Box>
  }

  renderEditDeleteIconButton=(passId:string,passIndex:number)=>{
    return   <Box>
    <IconButton
      className = 'delete_passenger'
      onClick={(event:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
        this.propagationStop(event);
        this.handleDeleteModalOpen(true,passId);
      }}
    >
      <DeleteOutline style={{color:colors.GOLD_COLOR}} className="advance_search_remove_leg"/>
    </IconButton>
    <IconButton
      className = 'edit_passenger'
      onClick={(event:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
        this.propagationStop(event);
        this.handleSavePassengerModal(true,'edit',passIndex);
      }}
    >
      <Edit style={{color:colors.GOLD_COLOR}}/>
    </IconButton>
 </Box>
  }

  renderDeleteConfirmationModal=()=>{
    const {isDeleteModalOpen} = this.state;
    return  <SurityModal isOpen={isDeleteModalOpen}><SureModalButtonContainer cancel={()=>this.handleDeleteModalOpen(false)} submit={()=>this.deleteSavedPassenger()}/></SurityModal>
  }

  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <div id="booking_section">
        <GoldishSimpleButton style={{ color: 'white', textTransform: "capitalize", justifyContent:'start',fontSize:'1.6rem'}}>Saved Passengers</GoldishSimpleButton>
        {this.renderMainCont()}
      </div>
      // Customizable Area End

    );
  }
}
// Customizable Area Start

const Styles:CSSPropertiesMap = {
  mainContainer:{
    padding:"1rem",
    backgroundColor:'black',
    color:"white",
    borderRadius:'10px',
    marginTop:"0.5rem"
  },
  accordionParent:{
    display:'flex',
    flexDirection : 'column',
    rowGap : '1rem'
  }
}
// Customizable Area End
