Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfcustomskycards";
exports.labelBodyText = "cfcustomskycards Body";

exports.btnExampleTitle = "CLICK ME";
exports.getSkyCardsAPIEndpoint = "bx_block_custom_forms/custom_forms"
exports.getDebitSkyCardsAPIEndpoint = "bx_block_cfdebitskycard3/debit_sky_cards"
exports.getAircraftAPIEndpoint = "bx_block_cfavinodeintegration2/aircrafts/aircraft_categories"
exports.get25HoursSkyCardsAPIEndpoint = "/bx_block_cf25hourskycard/hour_sky_lists"
exports.getskyCardsPurchasesAPIEndpoint = "/bx_block_custom_forms/custom_forms/sky_card_purchases"
exports.getSearchAirportsEndpoint = 'bx_block_cfavinodeintegration2/airports?query';
exports.searchClientAPIEndpoint = "/bx_block_custom_forms/custom_forms/search?"
exports.createCardAPIEndpoint = "/bx_block_custom_forms/custom_forms"
exports.paymentAPIEndpoint = "bx_block_custom_forms/custom_forms/add_top_up"
exports.topupDetailsAPIEndpoint = "bx_block_cfdebitskycard3/debit_sky_cards"
exports.topupCardAPIEndpoint = "/bx_block_cfdebitskycard3/debit_sky_cards/add_top_up_debit_sky"
exports.topupHourCardAPIEndpoint = "/bx_block_cf25hourskycard/hours_sky_cards/add_top_up"
exports.hoursCardsFilterAPIEndpoint = "bx_block_cf25hourskycard/hour_sky_lists/filter_list"
exports.topupCustomCardsAPIEndpoint = "bx_block_custom_forms/custom_forms/add_top_up"
exports.customSkycardsFilterAPIEndpoint = "bx_block_custom_forms/custom_forms/filter_list"
exports.aircraftCategoriesApiEndpoint = "bx_block_cfavinodeintegration2/aircrafts/aircraft_categories"


// Customizable Area End