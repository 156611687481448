import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {getStorageData} from "../../../framework/src/Utilities";
import {handleNavigation, handleTriggerAlertMessage} from "../../../components/src/Navigator";
import { roles } from "../../../components/src/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  faqMenuAchor:any;

  phone_number:string;
  whatsapp:string;
  userRequests:any;
  faqData:any;
  faqMenuAnchors:any;
  isUpdateModalOpen:boolean;
  updateModalType:'phone_number' | 'whatsapp';
  faqIdToBeDeleted:any,
  isEditState:boolean,
  intitialValues:any,
  focused:string,
  isUserContactus:boolean,
  query : string,
  isAdminRole:boolean,
  isThankYouModalOpen:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postQueryApiCallId:string = "";
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  requestListApiCallId:any;
  getAllFAQApiCallId:any;
  updateContactUsAndTextUsNumberApiId:any;
  updateContactUsId:any;
  formikMethods:any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";
    this.requestListApiCallId = "";
    this.getAllFAQApiCallId = "";
    this.updateContactUsId = "";


    this.updateContactUsAndTextUsNumberApiId = "";

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      faqMenuAchor:null,

      phone_number:"",
      whatsapp:"",
      userRequests:[],
      faqData:[],
      isUpdateModalOpen:false,
      updateModalType:'phone_number',
      faqMenuAnchors:{},
      faqIdToBeDeleted:null,
      isEditState:false,
      intitialValues:{
        call_country_code: "",
        text_country_code: "",
        phone_number: "",
        whatsapp: "",
        call_label: "",
        text_label: "",
        text_us_number:'',
        call_us_number:''
    },
    focused:'',
    isUserContactus : false,
    query : "",
    isAdminRole:true,
    isThankYouModalOpen:false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // this.getContactUsList();
    this.getAllFAQs();
    this.handleRole();
    this.handleCurrentRole();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
     if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getContactUsList(token);
  } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);
      if (responseJson) {
        switch (apiRequestCallId) {
          case this.contactUsApiCallId:
            this.handleContactUsDataResponse(responseJson)
            break

          case this.getAllFAQApiCallId:
              this.handleFAQResponseData(responseJson)
              break  

          case this.deleteContactApiCallId:
            this.handleDeleteFaqResponse(responseJson);
            break;  

          case this.updateContactUsId:
              this.handleUpdateContactUsResponse(responseJson);
              break;    


          case this.postQueryApiCallId:
              this.handleQueryResponse(responseJson);
             break; 
      
          default:
            break
        }
      }
        
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  setName = (text: string) => {
    this.setState({ name: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  setComments = (text: string) => {
    this.setState({ comments: text });
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.comments)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    } else {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber.trim(),
          description: this.state.comments.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteContactUs = async(id: any) => {
    const header = {
      token: await getStorageData('token'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllFAQSApiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = (token: string = '') => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  getAllFAQs =  async() => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllFAQApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllFAQSApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  btnSubmitProps = {
    onPress: () => this.addQueryApi(),
  };

  btnBackProps = {
    onPress: () => this.props.navigation.goBack(),
  };


// Web Handler Function 

  handleFormData=(values:any)=>{
    this.setState(values);
    const dataToSend = {
      data : values
    }
    return dataToSend;
  }

  handleContactUsDataResponse=(responseJson:any)=>{
    if(responseJson.phone_number !== null){
      const {phone_number, whatsapp, call_country_code, text_country_code, call_label, text_label} = responseJson;
      this.setState({intitialValues:{phone_number,whatsapp,call_label, text_label,call_us_number:call_country_code+phone_number,text_us_number:text_country_code+whatsapp,text_country_code,call_country_code}});
    }
  }

  handleUserRequestDataResponse=(responseJson:any)=>{
    if(responseJson.data){
      const {data} = responseJson;
      this.setState({userRequests:data});
    }
  }

  handleClick = (event:any,index:any) => {
    this.setState({
      faqMenuAnchors: {
          [index]: event.currentTarget
      }
  });
  };

   handleClose = (index:any) => {
    this.setState(prevState => ({
      faqMenuAnchors: {
          ...prevState.faqMenuAnchors,
          [index]: null 
      }
  }));
  };

  handleFAQResponseData=(responseJson:any)=>{
    if(responseJson.data){
      const {data} = responseJson;
      this.setState({faqData:data});
    }
  }

  handleNavigationToCreateFaq=()=>{
    handleNavigation('/AddFAQ',this.props,this.send);
  }

  handleNavigationToEditFaq=(id:any)=>{
    handleNavigation(`/EditFAQ?q=${id}`,this.props,this.send);
  }

  handleDeleteFAQInitializer=(id:any)=>{
    this.setState({faqIdToBeDeleted:id});
    this.deleteContactUs(id);
  }

  handleDeleteFaqResponse=(responseJson:any)=>{
    if(responseJson.message){
      this.handleDeleteFaq();
      this.setState({faqMenuAnchors:{}});
    }
  }

  handleDeleteFaq=()=>{
    const {faqData,faqIdToBeDeleted} = this.state;
      const index = faqData.findIndex((faq:any) => faq.attributes.id === faqIdToBeDeleted);
      if (index !== -1) {
        faqData.splice(index, 1);
      }
  }

  handleEditor=(isEditState:boolean)=>{
    this.setState({isEditState});
  }

  handleFormSubmit=async(values:any)=>{
    const headers = {
      token : await getStorageData('token'),
      'Content-type':'application/json'
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateContactUsId = requestMessage.messageId;
    let formData = this.handleContactUsFormData(values);
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateCallUsTextUsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleContactUsFormData=(values:any)=>{
    const {phone_number,whatsapp,call_label,text_label,text_country_code,call_country_code} = values;
    let dataToSend = {
      data : {
        call_country_code,
        text_country_code,
        phone_number,
        whatsapp,
        call_label,
        text_label
    }
    }
    return JSON.stringify(dataToSend);
  }

  handleUpdateContactUsResponse=(responseJson:any)=>{
    const {meta:{message},data} = responseJson;
    if(message === 'Updated successfully'){
      this.handleContactUsDataResponse(data);
      this.handleTriggertMessage();
      this.handleEditor(false);
    }
  }

  handleTriggertMessage=()=>{
    const messageTrigerrer = new Message(getName(MessageEnum.AlertMessage));
    messageTrigerrer.addData(getName(MessageEnum.AlertBodyMessage), "Contacts saved successfully");
    this.send(messageTrigerrer);
  }

  handleFocusedLabel=(focusedLabel:string)=>{
    this.setState({focused : focusedLabel})
  }

  handleRole=()=>{
    const {location} = window;
    const {pathname} = location;
    if(pathname === '/ClientContactus'){
      this.setState({isUserContactus:true});
    }
  }

  handleQuery=(event : React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({query : event.target.value});
  }

  handleSubmitQuery=()=>{
    const {query} = this.state;
    if(query.trim().length === 0){
      handleTriggerAlertMessage(this.send,'Please enter valid query','error');
      return;
    }
    this.handlePostQuery();
  }

  handlePostQuery = async () =>{
    const {query} = this.state;
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
       "token" : await getStorageData('token')
    };
    const bodyToSend = {
      data: { 
      description: query
      }}
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.postQueryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getClientContactUs
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyToSend)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleQueryResponse=(responseJson : {meta:{message:string}})=>{
    if(responseJson.meta.message){
      this.handleThanksModal(true);
      this.setState({query:''});
    }else{
      handleTriggerAlertMessage(this.send,'Something Went Wrong!','error');
    }
  }

  handleCurrentRole=async()=>{
    const role = await getStorageData('role');
    this.setState({isAdminRole : role !== roles.user});
  }

  handleThanksModal=(isOpen=false)=>{
    this.setState({isThankYouModalOpen:isOpen});
  }
  // Customizable Area End
}
